import React from "react";
import { Link } from "react-router-dom";

function Button() {
  return (
    <>
      <div className="blue-white-btn">
        <div className="blue-btn">
          <Link to="/event-planning-consumer" className="consumer-button">
            Start planning
          </Link>
        </div>

        <div className="glow-blue-btn bor-whte">
          <Link to="/supplier" className="consumer-button">
            Become a Supplier
          </Link>
        </div>
      </div>
    </>
  );
}

export default Button;
