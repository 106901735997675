import React from "react";
import { Link } from "react-router-dom";
import logo from "../src/image/vendior-logo.png";
import bannerright from "../src/image/banner-right-img.webp";
import Gappscanner from "../src/image/scanner-srvice-img.webp";
import apple from "../src/image/apple-icon.png";
import playstore from "../src/image/google-play-icon.png";

function Gapp() {
  return (
    <>
      <div className="Gapp-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="Gapp-sec-inner">
                <div className="Gapp-upper-logo text-center">
                  <img src={logo} className="gapplogo" alt="logo" />
                </div>

                <div className="clode-gapp">
                  <Link to="/" className="close">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="47"
                      height="48"
                      viewBox="0 0 47 48"
                      fill="none">
                      <path
                        d="M11.7188 35.564L34.8912 12.3916M11.7188 12.3916L34.8912 35.564"
                        stroke="white"
                        strokeWidth="4.34483"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="Gapp-content">
           <div className="row align-items-center">
            <div className="col-lg-7">
                <div className="Gapp-left">
                   <img src={bannerright} className="gapp-banner" alt="banner" />
                </div>
            </div>
            <div className="col-lg-5">
               <div className="Gapp-right">
                 <h3 className="Gapp-heading">
                  Scan the QR code below to<br></br> download{" "}
                   <span className="Gapp-heading-inner">VENDOIR NOW</span>
                 </h3>

                  <img src={Gappscanner} className="qr-code-img" alt="qr code" />


                   <div className="Gapp-social-link Gapp-playStore">
                      <a
                        href="https://apps.apple.com/gb/app/vendoir/id1630965089"
                        target="_blank"
                        rel="noreferrer">
                        <img src={apple} className="Gapp-img" alt="apple store" />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vendoir.app.prod&pli=1"
                        target="_blank"
                        rel="noreferrer">
                        <img src={playstore} className="Gapp-img" alt="play store" />
                      </a>
                 </div>

                 <p className="gapp-bottom-title">
                     By downloading the Vendoir app you are agreeing to our{" "}<br></br>
                     <Link to="/Terms" className="t-c">
                       terms & conditions.
                     </Link>
                   </p>
               </div>
            </div>

          </div>
          </div>
         

        </div>
      </div>
    </>
  );
}

export default Gapp;
