import React from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Bnb from "./Bnb";
import BFooter from "./BFooter";
function Bprivacy() {
  return (
    <div>
      <Bnb />
      <section className="privacy">
        <div className="container">
          <div className="privacy-heading">
            <h5>Last update november 01, 2020</h5>
            <h1>Privacy notice</h1>
          </div>

          <div className="privacy-content">
            <h2 className="privacy-title">Our Privacy Commitment to You</h2>
            <p className="privacy-desc">
              Thank you for choosing to be part of our community at Vendoir
              Limited ("Company", "we", "us", "our"). We are committed to
              protecting your personal information and your right to privacy. If
              you have any questions or concerns about this privacy notice, or
              our practices with regards to your personal information, please
              contact us at support@vendoir.com. <br />
              <br />
              When you visit our Platform https://vendoir.com (the "Platform")
              and more generally, use any of our services (the "Services", which
              include the Platform), we appreciate that you are trusting us with
              your personal information. We take your privacy very seriously. In
              this privacy notice, we seek to explain to you in the clearest way
              possible what information we collect, how we use it and what
              rights you have in relation to it. We hope you take some time to
              read through it carefully, as it is important. If there are any
              terms in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
              <br />
              <br />
              Vendoir Limited is the data controller and is responsible for your
              personal data. Vendoir is registered in England and Wales, with
              company no. 12986100 and registered office: 128 City Road, London,
              United Kingdom, EC1V 2NJ. This privacy notice applies to all
              information collected through our Services (which, as described
              above, includes our Platform), as well as, any related services,
              sales, marketing or events.
              <br />
              <br />
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.
            </p>
          </div>

          <div className="privacy-faq">
            <MDBContainer>
              <MDBAccordion alwaysOpen initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="1. WHAT INFORMATION DO WE COLLECT?">
                  <p className="privacy-desc">
                    Personal information you disclose to us
                    <br />
                    <br />
                    In Short: We collect personal information that you provide
                    to us.
                    <br />
                    <br />
                    We collect personal information that you voluntarily provide
                    to us when you express an interest in obtaining information
                    about us or our products and Services, when you participate
                    in activities on the Platform or otherwise when you contact
                    us.
                    <br />
                    <br />
                    The personal information that we collect depends on the
                    context of your interactions with us and the Platform, the
                    choices you make and the products and features you use. The
                    personal information we collect may include the following:
                    <br />
                    <br />
                    Personal Information Provided by You. We collect names;
                    phone numbers; email addresses ; ID documents, and other
                    similar information.
                    <br />
                    <br />
                    We also collect details about your events such as the event
                    type, date and venue, and any transactions you enter into
                    with suppliers on the Platform.
                    <br />
                    <br />
                    If you are a supplier, we may collect other details such as
                    your occupation.
                    <br />
                    <br />
                    All personal information that you provide to us must be
                    true, complete and accurate, and you must notify us of any
                    changes to such personal information.
                    <br />
                    <br />
                    Where we need to collect personal data by law, or under the
                    terms of a contract we have with you, and you fail to
                    provide that data when requested, we may not be able to
                    perform the contract we have or are trying to enter into
                    with you (for example, to process a purchase). In this case,
                    we may have to cancel your purchase, but we will notify you
                    if this is the case at the time.
                    <br />
                    <br />
                    Information automatically collected
                    <br />
                    <br />
                    In Short: Some information - such as your Internet Protocol
                    (IP) address and/or browser and device characteristics - is
                    collected automatically when you visit our Platform.
                    <br />
                    <br />
                    We automatically collect certain information when you visit,
                    use or navigate the Platform. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Platform and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Platform, and for our internal
                    analytics and reporting purposes.
                    <br />
                    <br />
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                    <br />
                    <br />
                    The information we collect includes:
                  </p>
                  <ul className="privacy-desc">
                    <li className="mt-3">
                      Log and Usage Data: Log and usage data is service-related,
                      diagnostic, usage, and performance information our servers
                      automatically collect when you access or use our Platform
                      and which we record in log files. Depending on how you
                      interact with us, this log data may include your IP
                      address, device information, browser type and settings,
                      and information about your activity in the Platform (such
                      as the date/time stamps associated with your usage, pages
                      and files viewed, searches, and other actions you take
                      such as which features you use), device event information
                      (such as system activity, error reports - sometimes called
                      'crash dumps' - and hardware settings).
                    </li>
                    <li className="mt-3">
                      Device Data: We collect device data such as information
                      about your computer, phone, tablet, or other device you
                      use to access the Platform. Depending on the device used,
                      this device data may include information such as your IP
                      address (or proxy server), device and application
                      identification numbers, location, browser type, hardware
                      model, Internet service provider and/or mobile carrier,
                      operating system, and system configuration information.
                    </li>
                    <li className="mt-3">
                      Location Data:We collect location data such as information
                      about your device's location, which can be either precise
                      or imprecise. How much information we collect depends on
                      the type and settings of the device you use to access the
                      Platform. For example, we may use GPS and other
                      technologies to collect geolocation data that tells us
                      your current location (based on your IP address). You can
                      opt out of allowing us to collect this information either
                      by refusing access to the information or by disabling your
                      Location setting on your device. Note, however, if you
                      choose to opt out, you may not be able to use certain
                      aspects of the Services.
                    </li>
                  </ul>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="2. HOW DO WE USE YOUR INFORMATION?">
                  <p className="privacy-desc">
                    In Short: We process your information for purposes based on
                    legitimate business interests, the fulfilment of our
                    contract with you, compliance with our legal obligations,
                    and/or your consent.
                    <br />
                    <br />
                    We use personal information collected via our Platform for a
                    variety of business purposes described below.
                    <br />
                    <br />
                    We may process or share your data that we hold based on the
                    following legal bases:
                  </p>
                  <ul className="privacy-desc">
                    <li className="mt-3">
                      Consent: We may process your data if you have given us
                      specific consent to use your personal information for a
                      specific purpose.
                    </li>
                    <li className="mt-3">
                      Legitimate Interests: We may process your data when it is
                      reasonably necessary to achieve our legitimate business
                      interests.
                    </li>
                    <li className="mt-3">
                      Performance of a Contract: Where we have entered a
                      contract with you, we may process your personal
                      information to fulfil the terms of our contract.
                    </li>
                    <li className="mt-3">
                      Legal Obligations: We may disclose your information where
                      we are legally required to do so to comply with applicable
                      law, governmental requests, a judicial proceeding, court
                      order, or legal process, such as in response to a court
                      order or a subpoena (including in response to public
                      authorities to meet national security or law enforcement
                      requirements).
                    </li>
                    <li className="mt-3">
                      Vital Interests: We may disclose your information where we
                      believe it is necessary to investigate, prevent, or take
                      action regarding potential violations of our policies,
                      suspected fraud, situations involving potential threats to
                      the safety of any person and illegal activities, or as
                      evidence in litigation in which we are involved.
                    </li>
                  </ul>
                  <p className="privacy-desc">
                    We indicate the specific processing grounds we rely on next
                    to each purpose listed below.
                    <br />
                    <br />
                    We use the information we collect or receive:
                  </p>

                  <ul className="privacy-desc">
                    <li className="mt-3">
                      To send administrative information to you. We may use your
                      personal information to send you product, service, and new
                      feature information and/or information about changes to
                      our terms, conditions, and policies. Our legal basis for
                      this processing is the performance of our contract with
                      you.
                    </li>
                    <li className="mt-3">
                      To protect our Services. We may use your information as
                      part of our efforts to keep our Platform safe and secure
                      (for example, for fraud monitoring and prevention). Our
                      legal basis for this processing is our legitimate interest
                      in ensuring the security of the Platform.
                    </li>
                    <li className="mt-3">
                      To enforce our terms, conditions, and policies for
                      business purposes, to comply with legal and regulatory
                      requirements or in connection with our contract. Unless we
                      are required to by law, our legal basis for this
                      processing is our legitimate interest in ensuring our
                      terms and conditions are complied with by those using the
                      Platform.
                    </li>
                    <li className="mt-3">
                      To respond to legal requests and prevent harm. If we
                      receive a subpoena or other legal request, we may need to
                      inspect the data we hold to determine how to respond. Our
                      legal basis for this processing is to comply with our
                      legal obligations.
                    </li>
                    <li className="mt-3">
                      Fulfill and manage your orders. We may use your
                      information to fulfill and manage your orders, payments,
                      returns, and exchanges made through the Platform. Our
                      legal basis for this processing is the performance of our
                      contract with you.
                    </li>
                    <li className="mt-3">
                      Administer prize draws and competitions. We may use your
                      information to administer prize draws and competitions
                      when you elect to participate in our competitions. Our
                      legal basis for this processing is the performance of our
                      contract with you.
                    </li>
                    <li className="mt-3">
                      To deliver and facilitate the delivery of services to the
                      user. We may use your information to provide you with the
                      requested service. Our legal basis for this processing is
                      the performance of our contract with you.
                    </li>
                    <li className="mt-3">
                      To respond to user inquiries/offer support to users. We
                      may use your information to respond to your inquiries and
                      solve any potential issues you might have with the use of
                      our Services. Our legal basis for this processing is our
                      legitimate interest in responding to your queries.
                    </li>
                    <li className="mt-3">
                      To send you marketing and promotional communications. We
                      and/or our third-party marketing partners may use the
                      personal information you send to us for our marketing
                      purposes, if this is in accordance with your marketing
                      preferences. For example, when expressing an interest in
                      obtaining information about us or our Platform,
                      subscribing to marketing or otherwise contacting us, we
                      will collect personal information from you. You can
                      opt-out of our marketing emails at any time (see the "WHAT
                      ARE YOUR PRIVACY RIGHTS" below). Where you are an existing
                      Organiser, our legal basis for sending you marketing about
                      similar products / services to those already purchased is
                      our legitimate interest in marketing our business.
                      Otherwise, where you are not an existing Organiser or we
                      are promoting a different type of product or services, our
                      legal basis is your consent.
                    </li>
                    <li className="mt-3">
                      Deliver targeted advertising to you. We may use your
                      information to develop and display personalized content
                      and advertising (and work with third parties who do so)
                      tailored to your interests and/or location and to measure
                      its effectiveness. Our legal basis for this processing is
                      our legitimate interest in marketing and growing our
                      business.
                    </li>
                  </ul>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?">
                  <p className="privacy-desc">
                    In Short: We only share information with your consent, to
                    comply with laws, to provide you with services, to protect
                    your rights, or to fulfill business obligations.
                    <br />
                    <br />
                    We may share your personal data on one of the legal bases
                    listed in the previous section. More specifically, we may
                    need to process your data or share your personal information
                    in the following situations:
                  </p>

                  <ul className="privacy-desc">
                    <li className="mt-3">
                      Business Transfers. We may share or transfer your
                      information in connection with, or during negotiations of,
                      any merger, sale of company assets, financing, or
                      acquisition of all or a portion of our business to another
                      company.
                    </li>
                    <li className="mt-3">
                      Suppliers, Consultants, and Other Third-Party Service
                      Providers. We may share your data with third-party
                      suppliers, service providers, contractors, or agents who
                      perform services for us or on our behalf and require
                      access to such information to do that work. Examples
                      include payment processing, data analysis, email delivery,
                      hosting services, Organiser service, and marketing
                      efforts. We may allow selected third parties to use
                      tracking technology on the Platform, which will enable
                      them to collect data on our behalf about how you interact
                      with our Platform over time. This information may be used
                      to, among other things, analyze and track data, determine
                      the popularity of certain content, pages, or features, and
                      better understand online activity. Unless described in
                      this notice, we do not share, sell, rent, or trade any of
                      your information with third parties for their promotional
                      purposes. We have contracts in place with our data
                      processors, which are designed to help safeguard your
                      personal information. This means that they cannot do
                      anything with your personal information unless we have
                      instructed them to do it. They will also not share your
                      personal information with any organization apart from us.
                      They also commit to protect the data they hold on our
                      behalf and to retain it for the period we instruct.
                    </li>
                  </ul>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={4}
                  headerTitle="4. WHO WILL YOUR INFORMATION BE SHARED WITH?">
                  <p className="privacy-desc">
                    In Short: We only share information with the following third
                    parties.
                    <br />
                    <br />
                    We only share and disclose your information with the
                    following third parties. We have categorized each party so
                    that you may be easily understand the purpose of our data
                    collection and processing practices. If we have processed
                    your data based on your consent and you wish to revoke your
                    consent, please contact us using the contact details
                    provided in the section below titled "HOW CAN YOU CONTACT US
                    ABOUT THIS NOTICE?".
                  </p>

                  <ul className="privacy-desc">
                    <li className="mt-3">
                      Allow Users to Connect to Their Third-Party Accounts
                    </li>
                  </ul>
                  <p className="privacy-desc">
                    Facebook account, Instagram account, LinkedIn account and
                    Twitter account
                  </p>
                  <ul className="privacy-desc">
                    <li className="mt-3">Communicate and Chat with Users</li>
                  </ul>

                  <p className="privacy-desc">MailChimp</p>
                  <ul className="privacy-desc">
                    <li className="mt-3">
                      Social Media Sharing and Advertising
                    </li>
                  </ul>
                  <p className="privacy-desc">
                    Facebook advertising, LinkedIn advertising, Tiktok
                    advertising, Snapchat advertising
                  </p>
                  <ul className="privacy-desc">
                    <li className="mt-3">User Commenting and Forums</li>
                  </ul>
                  <p className="privacy-desc">Facebook Comments</p>
                  <ul className="privacy-desc">
                    <li className="mt-3">Web and Mobile Analytics</li>
                  </ul>
                  <p className="privacy-desc">
                    Google Analytics
                    <br />
                    <br />
                    Payment and Identity verification processing
                    <br />
                    Stripe
                    <br />
                    Messaging
                    <br />
                    TalkJs, Discourse
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={5}
                  headerTitle="5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?">
                  <p className="privacy-desc">
                    In Short: We may use cookies and other tracking technologies
                    to collect and store your information.
                    <br />
                    <br />
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={6}
                  headerTitle="6. DO WE USE GOOGLE MAPS PLATFORM APIS?">
                  <p className="privacy-desc">
                    In Short: Yes, we use Google Maps Platform APIs for the
                    purpose of providing better service.
                    <br />
                    <br />
                    This Platform uses Google Maps Platform APIs which are
                    subject to Google’s Terms of Service. You may find the
                    Google Maps Platform Terms of Service{" "}
                    <a href="https://cloud.google.com/maps-platform/terms/">
                      here
                    </a>{" "}
                    here. To find out more about Google’s Privacy Policy, please
                    refer to this{" "}
                    <a href="https://policies.google.com/privacy">link. </a> We
                    obtain and store on your device ('cache') your location for
                    zero (0) months. You may revoke your consent anytime by
                    contacting us at the contact details provided at the end of
                    this document.
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={7}
                  headerTitle="7. HOW LONG DO WE KEEP YOUR INFORMATION?">
                  <p className="privacy-desc">
                    In Short: We keep your information for as long as necessary
                    to fulfill the purposes outlined in this privacy notice
                    unless otherwise required by law.
                    <br />
                    <br />
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than 2 years
                    except where we are required to by law, for tax reasons, or
                    pursuant to a dispute or potential dispute.
                    <br />
                    <br />
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing until deletion is possible.
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={8}
                  headerTitle="8. HOW DO WE KEEP YOUR INFORMATION SAFE?">
                  <p className="privacy-desc">
                    In Short: We aim to protect your personal information
                    through a system of organizational and technical security
                    measures.
                    <br />
                    <br />
                    We have implemented appropriate technical and organizational
                    security measures designed to protect the security of any
                    personal information we process. However, despite our
                    safeguards and efforts to secure your information, no
                    electronic transmission over the Internet or information
                    storage technology can be guaranteed to be 100% secure, so
                    we cannot promise or guarantee that hackers, cyber
                    criminals, or other unauthorized third parties will not be
                    able to defeat our security, and improperly collect, access,
                    steal, or modify your information. Although we will do our
                    best to protect your personal information, transmission of
                    personal information to and from our Platform is at your own
                    risk. You should only access the Platform within a secure
                    environment.
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={9}
                  headerTitle="9. DO WE COLLECT INFORMATION FROM MINORS?">
                  <p className="privacy-desc">
                    In Short: We do not knowingly collect data from or market to
                    children under 18 years of age.
                    <br />
                    <br />
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Platform, you represent
                    that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor
                    dependent’s use of the Platform . If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we may have
                    collected from children under age 18, please contact us at{" "}
                    <a href="mailto:support@vendoir.com">support@vendoir.com</a>
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={10}
                  headerTitle="10. WHAT ARE YOUR PRIVACY RIGHTS?">
                  <p className="privacy-desc">
                    In Short: In some regions, such as the UK and European
                    Economic Area, you have rights that allow you greater access
                    to and control over your personal information. You may
                    review, change, or terminate your account at any time.
                    <br />
                    <br />
                    In some regions (like the European Economic Area), you have
                    certain rights under applicable data protection laws. These
                    may include the right (i) to request access and obtain a
                    copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to restrict the processing
                    of your personal information; and (iv) if applicable, to
                    data portability. In certain circumstances, you may also
                    have the right to object to the processing of your personal
                    information. To make such a request, please use the{" "}
                    <a href="https://www.vendoir.com/vendoir-privacy-policy#contact">
                      contact details
                    </a>{" "}
                    provided below. We will consider and act upon any request in
                    accordance with applicable data protection laws.
                    <br />
                    <br />
                    If we are relying on your consent to process your personal
                    information, you have the right to withdraw your consent at
                    any time. Please note however that this will not affect the
                    lawfulness of the processing before its withdrawal, nor will
                    it affect the processing of your personal information
                    conducted in reliance on lawful processing grounds other
                    than consent.
                    <br />
                    <br />
                    If you are a resident in the UK or the European Economic
                    Area and you believe we are unlawfully processing your
                    personal information, you also have the right to complain to
                    your local data protection supervisory authority. You can
                    find their contact details here:
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm,
                    or in the case of the UK, the relevant regulator is the
                    Information Commissioner’s Office (www.ico.org.uk).
                    <br />
                    <br />
                    If you are a resident in Switzerland, the contact details
                    for the data protection authorities are available here:{" "}
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                      https://www.edoeb.admin.ch/edoeb/en/home.html.
                    </a>
                    <br />
                    <br />
                    You will not have to pay a fee to access your personal data
                    (or to exercise any of the other rights). However, we may
                    charge a reasonable fee if your request is clearly
                    unfounded, repetitive or excessive. Alternatively, we could
                    refuse to comply with your request in these circumstances.
                    <br />
                    <br />
                    We may need to request specific information from you to help
                    us confirm your identity and ensure your right to access
                    your personal data (or to exercise any of your other
                    rights). This is a security measure to ensure that personal
                    data is not disclosed to any person who has no right to
                    receive it. We may also contact you to ask you for further
                    information in relation to your request to speed up our
                    response.
                    <br />
                    <br />
                    We try to respond to all legitimate requests within one
                    month. Occasionally it could take us longer than a month if
                    your request is particularly complex or you have made a
                    number of requests. In this case, we will notify you and
                    keep you updated.
                    <br />
                    <br />
                    Cookies and similar technologies: Most Web browsers are set
                    to accept cookies by default. If you prefer, you can usually
                    choose to set your browser to remove cookies and to reject
                    cookies. If you choose to remove cookies or reject cookies,
                    this could affect certain features or services of our
                    Platform. To opt-out of interest-based advertising by
                    advertisers on our Platform visit
                    <a href="http://www.aboutads.info/choices/">
                      http://www.aboutads.info/choices/
                    </a>
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={11}
                  headerTitle="11. CONTROLS FOR DO-NOT-TRACK FEATURES">
                  <p className="privacy-desc">
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform
                    technology standard for recognizing and implementing DNT
                    signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={12}
                  headerTitle="12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?">
                  <p className="privacy-desc">
                    In Short: Yes, if you are a resident of California, you are
                    granted specific rights regarding access to your personal
                    information.
                    <br />
                    <br />
                    California Civil Code Section 1798.83, also known as the
                    "Shine The Light" law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                    <br />
                    <br />
                    If you are under 18 years of age, reside in California, and
                    have a registered account with the Platform , you have the
                    right to request removal of unwanted data that you publicly
                    post on the Platform . To request removal of such data,
                    please contact us using the contact information provided
                    below, and include the email address associated with your
                    account and a statement that you reside in California. We
                    will make sure the data is not publicly displayed on the
                    Platform , but please be aware that the data may not be
                    completely or comprehensively removed from all our systems
                    (e.g. backups, etc.).
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={13}
                  headerTitle="13. DO WE MAKE UPDATES TO THIS NOTICE?">
                  <p className="privacy-desc">
                    In Short: Yes, we will update this notice as necessary to
                    stay compliant with relevant laws.
                    <br />
                    <br />
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated "Revised"
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={14}
                  headerTitle="14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?">
                  <p className="privacy-desc">
                    If you have questions or comments about this notice, you may
                    email us at info@vendoir.com or by post to:
                    <br />
                    <br />
                    Vendoir Limited <br />
                    128 City Road <br />
                    London <br />
                    United Kingdom <br />
                    EC1V 2NJ
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={15}
                  headerTitle="15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?">
                  <p className="privacy-desc">
                    Based on the applicable laws of your country, you may have
                    the right to request access to the personal information we
                    collect from you, change that information, or delete it in
                    some circumstances. To request to review, update, or delete
                    your personal information, please submit a request to{" "}
                    <a href="mailto:support@vendoir.com">support@vendoir.com</a>
                    <br />
                    <br />
                    Vendoir is a registered trademark of Vendoir Ltd. Registered
                    in England and Wales, company no. 12986100. Registered
                    office: 128 City Road, London, United Kingdom, EC1V 2NJ. ©
                    2021 Copyright:Vendoir.com
                  </p>
                </MDBAccordionItem>
              </MDBAccordion>
            </MDBContainer>
          </div>
        </div>
      </section>

      <BFooter />
    </div>
  );
}

export default Bprivacy;
