import React, { useState } from "react";
import Nb from "./Nb";
import Footer from "./Footer";
import Referfriendimg from "./image/referfriend-img.webp";
import Referfriendimg2 from "./image/refer-a-friend.webp";

function Referfriend() {
  const [sections, setSections] = useState([
    {
      title: "How do I get my referral bonus?",
      content:
        "Your referral bonus will be paid to your Vendoir account within 30 days of your friend booking an event.",
      show: false,
    },
    {
      title: "What if my friend doesnt book an event within 30 days?",
      content:
        "You will not be eligible for a referral bonus if your friend does not book an event within 30 days.Content for Section 2",
      show: false,
    },
    {
      title: "Can I refer more than one friend?",
      content: "Yes, you can refer to as many friends as you like.",
      show: false,
    },
    {
      title: "Where can I find my unique referral link?",
      content:
        "Please contact our customer support team at support@vendoir.com",
      show: false,
    },
  ]);

  const toggleSection = (index) => {
    const updatedSections = [...sections];
    updatedSections[index].show = !updatedSections[index].show;
    setSections(updatedSections);
  };

  return (
    <>
      <Nb />

      <div className="Referfriend-image-sec">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>

      <div className="Sustainability-new-sec margin-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-detail-sec-inner text-center">
                <h2 className="Blog-detai-heading">Refer a friend</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* earn-extra-money */}

      <div className="earn-extra-money">
        <div className="container">
          <h2 className="text-center earn-extra-money-heading">
            Earn extra
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none">
              <g clipPath="url(#clip0_3068_14218)">
                <path
                  d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0Z"
                  fill="#5165AC"
                />
                <path
                  d="M16.1719 20.1094C16.1719 19.9882 16.1703 19.8711 16.1673 19.7578H20.4609V17.6484H15.867C15.7288 17.1804 15.5613 16.8143 15.4029 16.4686C15.0987 15.8041 14.8359 15.23 14.8359 13.7812C14.8359 12.0366 16.2553 10.6172 18 10.6172C19.7447 10.6172 21.1641 12.0366 21.1641 13.7812H23.2734C23.2734 10.8735 20.9078 8.50781 18 8.50781C15.0922 8.50781 12.7266 10.8735 12.7266 13.7812C12.7266 15.69 13.1295 16.5702 13.4851 17.3467C13.5313 17.4469 13.5766 17.5474 13.6209 17.6484H11.6719V19.7578H14.057C14.0605 19.8693 14.0625 19.986 14.0625 20.1094C14.0625 23.1535 12.2413 25.7244 12.2242 25.7481L12.0234 26.0251V27.4922H23.2734V25.3828H14.8632C15.4788 24.1248 16.1719 22.2362 16.1719 20.1094Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3068_14218">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            by referring friends to vendoir
          </h2>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="earn-extra-money-left">
                <h3 className="earn-extra-money-title">
                  Earn £10 for every friend you refer to Vendoir who signs up
                  and books an event within 30 days. It's easy to refer your
                  friends:
                </h3>
                <ul className="extra-money-list">
                  <li className="extra-money-list-name">
                    Download the Vendoir app on either IOS to Android
                  </li>
                  <li className="extra-money-list-name">
                    Click on the "Refer a Friend" tab on the profile page
                  </li>
                  <li className="extra-money-list-name">
                    Share your unique referral link with your friends.
                  </li>
                  <li className="extra-money-list-name">
                    For every friend who signs up and books an event within 30
                    days, you'll earn at least £10.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="earn-extra-money-right">
                <img
                  src={Referfriendimg}
                  className="img-fluid"
                  alt="earn-extra-money"
                />
              </div>
            </div>
          </div>

          <p className="extra-money-desc text-center">
            There's no limit to the number of friends you can refer, so start
            spreading the word today!
          </p>
        </div>
      </div>

      {/* join-vendior */}

      <div className="earn-extra-money join-vendior">
        <div className="container">
          <h2 className="text-center earn-extra-money-heading">
            Why should your friends join Vendoir?
          </h2>
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="earn-extra-money-left">
                <img
                  src={Referfriendimg2}
                  className="img-fluid"
                  alt="why join"
                />
              </div>
            </div>

            <div className="col-lg-6 order-1 order-lg-2">
              <div className="earn-extra-money-right">
                <ul className="extra-money-list">
                  <li className="extra-money-list-name">
                    Vendoir is the one-stop shop for stress-free and sustainable
                    events.
                  </li>
                  <li className="extra-money-list-name">
                    We have a comprehensive marketplace of vetted event vendors.
                  </li>
                  <li className="extra-money-list-name">
                    Our user-friendly platform makes it easy to source, book,
                    and manage vendors.
                  </li>
                  <li className="extra-money-list-name">
                    We're committed to sustainability and net-zero carbon
                    emissions by 2040.
                  </li>
                  <li className="extra-money-list-name">
                    Plus, your friends will get a free event management
                    consultation when they sign up! So what are you waiting for?
                    Start referring your friends today!
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p className="extra-money-desc text-center">
            So what are you waiting for? Start referring your friends today!
          </p>
        </div>
      </div>

      {/* accordion-sec */}

      <div className="accordion-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="accordion-sec-inner text-center">
                <h2 className="faq-heading">FAQ</h2>
              </div>

              <div className="accordion">
                {sections.map((section, index) => (
                  <div key={index} className="accordion-section">
                    <div
                      className="accordion-header"
                      onClick={() => toggleSection(index)}>
                      <div className="accordion-header-name">
                        {" "}
                        {section.title}
                      </div>
                      <div className="accordion-header-sign">
                        {" "}
                        {section.show ? "-" : "+"}
                      </div>
                    </div>
                    {section.show && (
                      <div className="accordion-body">{section.content}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Referfriend;
