import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogTwentyfive() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-twentyFive">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
              What is “Dry” Hiring?
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">8 January 2024</p>
              </div>

              <p className="detail-inner-desc">
              Have you ever heard of “dry” hiring a venue? Vendoir is here to let you in on the latest trend in event planning!

              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                What is “dry” hiring?
                </h3>
                <p className="blog-inner-lists-title">
                Dry hiring a venue refers to renting a space without any additional services or amenities provided by the venue itself. Essentially, it means hiring the venue alone, without any catering, equipment, staffing, or other services included.


                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                Why is it important?
                </h3>
                <p className="blog-inner-lists-title">
                Dry hiring is important for event planners who have specific preferences for their event and prefer to bring in their own vendors. It allows for greater flexibility and customization, as planners have full control over every aspect of the event, from catering to decor to entertainment. It also allows for a wider range of events to take place at a particular venue.<br/>How can we help?

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                1. Easy to find vendors:
                </h3>
                <p className="blog-inner-lists-title">
                Vendoir provides a comprehensive directory of vendors offering various services, such as photographers, videographers, florists, DJs, hosts, and more. Planners can browse through the directory to find vendors that align with their event requirements and preferences.


                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                2. Booking and Payment:

                </h3>
                <p className="blog-inner-lists-title">
                Vendoir can facilitate the booking process by allowing planners to send inquiries, receive quotes, and confirm bookings directly through the platform. Payment processing functionality can also be integrated into the app, allowing for secure and convenient transactions between planners and vendors.

                </p>
              </div>
              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                3. Communication:

                </h3>
                <p className="blog-inner-lists-title">
                Vendoir serves as a communication hub between event planners and vendors, enabling seamless communication regarding event details, requirements, timelines, and any other pertinent information. This ensures that both parties are on the same page throughout the planning process.

                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogTwentyfive;
