import React from "react";
import Bnb from "../Bnb";
import BFooter from "../BFooter";

function ResourceThird() {
  return (
    <>
      <Bnb />
      <div className="blog-image-sec resource-image-third">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="resource-heading">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="blog-sec-heading resource-sec-title">
                How to make the right selection when planning a sustainable
                event
              </h2>
            </div>
          </div>
        </div>

        <div className="resource-content">
          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">Sustainable Venue Selection</h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                When selecting a venue,Look for venues that have adopted
                eco-friendly practices, such as using LED lighting, motion
                sensors, and water-saving plumbing systems.
              </li>
              <li className="resource-sub-desc">
                Give preference to venues that prioritize sustainable waste
                management, composting, and recycling initiatives.
              </li>
              <li className="resource-sub-desc">
                Show support for venues that offer local, organic catering
                options and obtain their food from ethical sources.
              </li>
              <li className="resource-sub-desc">
                Consider venues that have received green building
                certifications, like BREEAM or LEED, to ensure their
                construction and operations are environmentally friendly.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">Sustainable Catering Choices</h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Place a focus on locally sourced and organic food choices in
                order to decrease the negative environmental effects of
                transportation and agricultural methods.
              </li>
              <li className="resource-sub-desc">
                Provide plant-based and vegetarian meal options to decrease the
                consumption of meat and its environmental impact.
              </li>
              <li className="resource-sub-desc">
                Reduce food waste by serving smaller portions on plates,
                encouraging guests to take only what they need, and composting
                any leftover food.
              </li>
              <li className="resource-sub-desc">
                Utilize reusable plates, cutlery, and tableware instead of
                disposable plastics that are used once and thrown away.
              </li>
              <li className="resource-sub-desc">
                Take into consideration the use of compostable or biodegradable
                materials for food packaging.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Sustainable Transportation Options
            </h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Collaborate with nearby transportation providers to establish
                reduced fares or exclusive promotions for public transportation,
                carpooling, and cycling services.
              </li>
              <li className="resource-sub-desc">
                Enhance event materials and signage by incorporating
                comprehensive transit details to encourage the utilization of
                public transportation.
              </li>
              <li className="resource-sub-desc">
                Designate a specific area within the venue to facilitate
                ridesharing, bolstering carpooling efforts and diminishing
                reliance on individual vehicles.
              </li>
              <li className="resource-sub-desc">
                Reward participants who opt for sustainable transportation
                alternatives with enticing incentives, ranging from raffle
                prizes to discounted event merchandise.
              </li>
              <li className="resource-sub-desc">
                Foster eco-minded transportation options by installing electric
                vehicle charging stations on-site, promoting and accommodating
                green transport choices.
              </li>
            </ul>
          </div>
          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Sustainable Event Materials and Amenities
            </h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Opt for signs, handouts, and promotional items made from
                materials that can be reused or recycled.
              </li>
              <li className="resource-sub-desc">
                Select environmentally friendly printing options, like using
                recycled paper and inks made from soy.
              </li>
              <li className="resource-sub-desc">
                Whenever feasible, opt for digital versions of event materials
                to minimize the consumption of paper.
              </li>
              <li className="resource-sub-desc">
                Set up a water refilling station to minimize the use of
                disposable plastic water bottles.
              </li>
              <li className="resource-sub-desc">
                Encourage a zero-waste beverage program by offering reusable
                cups and water bottles for sale.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Sustainable Event Energy Management
            </h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                To enhance lighting efficiency, employ dimmers and timers to
                regulate light levels outside of peak periods.
              </li>
              <li className="resource-sub-desc">
                Decrease energy usage by replacing traditional bulbs with
                energy-efficient alternatives like LEDs.
              </li>
              <li className="resource-sub-desc">
                Minimize reliance on artificial lighting by maximizing the use
                of natural daylight where feasible.
              </li>
              <li className="resource-sub-desc">
                Conserve energy by unplugging dormant electronic devices.
              </li>
              <li className="resource-sub-desc">
                Upgrade to energy-efficient appliances and equipment for
                catering, sound, and other event requirements.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Sustainable Event Promotion and Awareness
            </h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Integrate sustainability messaging into event marketing
                materials and communications, emphasizing the importance of
                environmental responsibility.
              </li>
              <li className="resource-sub-desc">
                Showcase the event's sustainable initiatives prominently on the
                event website and social media channels to increase awareness
                and engagement.
              </li>
              <li className="resource-sub-desc">
                Emphasize sustainable transportation options and offer
                incentives through clear messaging on event signage and
                promotional materials.
              </li>
              <li className="resource-sub-desc">
                Enhance attendee knowledge about the event's sustainability
                efforts through engaging workshops, presentations, or
                interactive displays.
              </li>
              <li className="resource-sub-desc">
                Collaborate with environmental organizations or sustainable
                event platforms to amplify the sustainability message and extend
                its reach.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Sustainable Event Measurement and Reporting
            </h5>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Define and set measurable goals to measure the effectiveness of
                your sustainability efforts.
              </li>
              <li className="resource-sub-desc">
                Regularly collect and track information on waste reduction,
                energy usage, transportation emissions, and other pertinent
                measures.
              </li>
              <li className="resource-sub-desc">
                Seek assistance from a sustainability consultant or use
                technology tools to evaluate and report on the environmental
                impact of your events.
              </li>
              <li className="resource-sub-desc">
                Communicate the outcomes of your sustainability endeavors with
                participants, collaborators, and interested parties to foster
                transparency and inspire ongoing.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default ResourceThird;
