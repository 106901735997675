import React from "react";
import serviceimg from "./image/service-img.webp";
import serviceimg2 from "./image/rectangle-6318.webp";
import serviceimg3 from "./image/service-img3.webp";
import serviceimg4 from "./image/Group image 4.webp";
import homepartner from "../src/image/homepatner.webp";
import homepartner2 from "../src/image/homepatner2.webp";
import homepartner3 from "../src/image/homepatner3.webp";
import homepartner4 from "../src/image/homepatner4.webp";
import homepartner5 from "../src/image/homepatner5.webp";
import homepartner6 from "../src/image/homepatner6.webp";
import homepartner7 from "../src/image/homepatner7.webp";
import homepartner8 from "../src/image/homepatner8.webp";
import homepartner9 from "../src/image/homepatner9.webp";
import homepartner10 from "../src/image/homepatner10.webp";
import homepartner11 from "../src/image/homepatner11.webp";
import homepartner12 from "../src/image/homepatner12.webp";
import homepartner14 from "../src/image/homepartner14.webp";
import homepartner15 from "../src/image/homepartner15.webp";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Contactform from "./Contactform";
import Bnb from "./Bnb";
import BFooter from "./BFooter";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

function Service() {
  const location = useLocation();

  useEffect(() => {
    // Save the current route to local storage when the component mounts
    localStorage.setItem("previousRoute", location.pathname);
  }, [location.pathname]);


  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 3,
      slideTransition: "ease",
    },
    desktop: {
      breakpoint: { max: 1600, min: 1199 },
      items: 3,
      slideTransition: "ease",
    },
    tablet: {
      breakpoint: { max: 1199, min: 767 },
      items: 2,
      slideTransition: "ease",
    },
    mobile: {
      breakpoint: { max: 767, min: 575 },
      items: 1.5,
      slideTransition: "ease",
    },
    minmobile: {
      breakpoint: { max: 575, min: 340 },
      items: 1,
      slideTransition: "ease",
    },
  };

  return (
    <>
      <Bnb />

      <div className="service-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <p className="service-sec-heading text-center ">
                Vendoir services
              </p>

              <div className="service-sec-inner">
                <div className="container">
                  <div className="row align-items-center service-card">
                    <div className="col-lg-6">
                      <div className="service-inner-card">
                        <h2 className="service-main-title">
                          Bespoke event suppliers: sourcing and management
                        </h2>
                        <p className="service-main-desc">
                          We work with a network of sustainable event suppliers
                          to help you find the perfect vendors for your event.
                          We also handle all the logistics of booking and
                          managing your suppliers, so you can focus on your
                          guests.
                        </p>

                        <p className="service-main-desc">Benefits:</p>
                        <ul className="service-list">
                          <li className="service-list-name service-main-desc">
                            Save time and money by letting us do the hard work
                            for you
                          </li>
                          <li className="service-list-name service-main-desc">
                            Get access to a wide range of sustainable event
                            suppliers
                          </li>
                          <li className="service-list-name service-main-desc">
                            Be confident that your suppliers are vetted and
                            reliable
                          </li>
                          <li className="service-list-name service-main-desc">
                            Enjoy peace of mind knowing that your suppliers are
                            managed by a team of experts
                          </li>
                        </ul>

                        <div className="See-more">
                          <Link
                            to="/event-planning-business"
                            className="sign-btn">
                            Get started today
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 text-center">
                      <img
                        src={serviceimg}
                        className="service-img"
                        alt="sourcing and management"
                      />
                    </div>
                  </div>
                </div>

                <div className="blue-bg">
                  <div className="container">
                    <div className="row align-items-center service-card ">
                      <div className="col-lg-6 order-2 order-lg-1">
                        <img
                          src={serviceimg2}
                          className="service-img"
                          alt="sustainable market"
                        />
                      </div>

                      <div className="col-lg-6 order-1 order-lg-2">
                        <h2 className="service-main-title">
                          Access to event secondary sustainable market
                        </h2>
                        <p className="service-main-desc">
                          Our event-secondary sustainable marketplace allows you
                          to buy, sell, and hire sustainable equipment and
                          products for your event. This is a great way to save
                          money and reduce your environmental impact.
                        </p>

                        <p className="service-main-desc">Benefits:</p>
                        <ul className="service-list">
                          <li className="service-list-name service-main-desc">
                            Save money on event equipment and products
                          </li>
                          <li className="service-list-name service-main-desc">
                            Reduce your environmental impact by buying and
                            selling used items
                          </li>
                          <li className="service-list-name service-main-desc">
                            Support sustainable businesses and organizations
                          </li>
                          <li className="service-list-name service-main-desc">
                            Find a wide range of event equipment and products,
                            from furniture to décor to catering
                          </li>
                        </ul>

                        <div className="See-more">
                          <Link
                            to="/event-planning-business"
                            className="sign-btn">
                            Get started today
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

                <div className="container">
                  <div className="row align-items-center service-card">
                    <div className="col-lg-6">
                      <h2 className="service-main-title">
                        Carbon measurement & offset
                      </h2>
                      <p className="service-main-desc">
                        We measure the carbon footprint of your event and help
                        you offset it with high-quality projects. - This means
                        that your event can be carbon-neutral or even
                        carbon-negative..
                      </p>

                      <p className="service-main-desc">Benefits:</p>
                      <ul className="service-list">
                        <li className="service-list-name service-main-desc">
                          Save time and money by letting us do the hard work for
                          you
                        </li>
                        <li className="service-list-name service-main-desc">
                          Get access to a wide range of sustainable event
                          suppliers
                        </li>
                        <li className="service-list-name service-main-desc">
                          Be confident that your suppliers are vetted and
                          reliable
                        </li>
                        <li className="service-list-name service-main-desc">
                          Enjoy peace of mind knowing that your suppliers are
                          managed by a team of experts
                        </li>
                      </ul>

                      <div className="See-more ">
                        <Link to="/Sustainability" className="sign-btn">
                          {" "}
                          Sustainability
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-6 text-center">
                      <img
                        src={serviceimg3}
                        className="service-img"
                        alt="sustainability"
                      />
                    </div>
                  </div>
                </div>

                <div className="blue-bg">
                  <div className="container">
                    <div className="row align-items-center service-card blue-bg">
                      <div className="col-lg-6 order-2 order-lg-1">
                        <img
                          src={serviceimg4}
                          className="service-img"
                          alt="24/7 support"
                        />
                      </div>

                      <div className="col-lg-6 order-1 order-lg-2">
                        <h2 className="service-main-title">24/7 support</h2>
                        <p className="service-main-desc">
                          We're here to help you with every step of your event
                          planning process, from sourcing suppliers to
                          offsetting your carbon footprint. We're available 24/7
                          to answer your questions and provide support.
                        </p>

                        <p className="service-main-desc">Benefits:</p>

                        <ul className="service-list">
                          <li className="service-list-name service-main-desc">
                            Get help from a team of experts whenever you need it
                          </li>
                          <li className="service-list-name service-main-desc">
                            Get peace of mind knowing that we're here to support
                            you
                          </li>
                          <li className="service-list-name service-main-desc">
                            Avoid costly mistakes by getting advice from the
                            pros
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* our-partner-sec */}

      <div className="our-partner-sec service-partner-sec">
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                  
                <div className="partner-names">
                 
                 <Carousel 
                 ssr={true} 
                 infinite={true}
                  responsive={responsive2}
                  autoPlay={true}
                  autoplayTimeout={0}
                  autoPlaySpeed={2000}
                  >
                  <div className="partner-img">
                    <img src={homepartner} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner2} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner3} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner4} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner5} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner6} alt=""/>
                  </div>

                  <div className="partner-img logoWidth">
                    <img src={homepartner14} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner7} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner8} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner9} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner10} alt=""/>
                  </div>

                  <div className="partner-img">
                    <img src={homepartner11} alt=""/>
                  </div>
                  
                  <div className="partner-img">
                    <img src={homepartner12} alt=""/>
                  </div>

                  <div className="partner-img logoWidth">
                    <img src={homepartner15} alt=""/>
                  </div>
                  
                </Carousel>
              
                </div>
              </div>
            </div>
        </div>
      </div>

      {/* Contactform-sec */}

      <Contactform />

      <BFooter />
    </>
  );
}

export default Service;
