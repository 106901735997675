import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogNineteen() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-nineteen">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                CEO’s welcome message
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">23 February,2021</p>
              </div>

              <div className="blog-inner-lists">
                <p className="blog-inner-lists-title">
                  As you navigate our website, I hope you learn more about our
                  company's vision and mission in the event industry and our
                  plans to transform the industry using technology. Our goal is
                  to provide event organisers with a simple, pleasant,
                  efficient, and cost-effective way to source event services.
                  <br />
                  <br />
                  Whether you are an event organiser with challenges in booking
                  suppliers, a supplier looking to increase revenue, or a
                  partner, supplier, or future employee looking for
                  opportunities and we are confident that you will find what you
                  are looking for once we launch our self-serve on-demand app in
                  2022.
                  <br />
                  <br />
                  We have a clear vision of what we want to be – the number one
                  marketplace for suppliers in the event industry, and our
                  progressive thinking and creative approach is what will make
                  us stand out from others. Our priority is our consumers, as we
                  believe they will be the heart of our existence, and that is
                  why we want to build a strong relationship with you and other
                  stakeholders so we can all build the kind of platform you
                  want. “We believe our app will suit the needs of every event
                  organiser taking into consideration budget, experience, speed,
                  and time.
                  <br />
                  <br />
                  I am continually impressed by the creative and entrepreneurial
                  qualities we have within the company and the exceptional value
                  it brings. This will allow us to deliver a best-in-class
                  product with outstanding customer service, which is in line
                  with our key principles, which include trust, harmony, and
                  customer satisfaction.
                  <br />
                  <br />
                  Enjoy our website, and feel free to contact us for any
                  additional information
                  <br />
                  <br />
                  Let’s transform the event industry together with technology.
                  <br />
                  <br />
                  by Oshoma Zekeri, 01/12/2020
                </p>
              </div>

              <p className="vendior-date">Chief Executive Officer</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogNineteen;
