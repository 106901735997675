import React, { useState, useEffect } from "react";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import stepperlogo from "../src/image/Vendoir logo/Vendor B2B Logo 1.svg";
import cstepper1 from "../src/image/staycation.webp";
import cstepper2 from "../src/image/cstepperimg2.webp";
import cstepper3 from "../src/image/cstepperimg3.webp";
import cstepper4 from "../src/image/Networking.webp";
import cstepper5 from "../src/image/cstepperimg5.webp";
import cstepper6 from "../src/image/cstepperimg6.webp";
import cstepper7 from "../src/image/cstepperimg7.webp";
import cstepper8 from "../src/image/cstepperimg8.webp";
import cstepper9 from "../src/image/cstepperimg9.webp";
import cstepper10 from "../src/image/cstepperimg10.webp";
import cstepper11 from "../src/image/cstepperimg11.webp";
import cstepper12 from "../src/image/cstepperimg12.webp";
import cstepper13 from "../src/image/cstepperimg13.webp";
import cstepper14 from "../src/image/cstepperimg14.webp";
import cstepper15 from "../src/image/cstepperimg15.webp";
import cstepper16 from "../src/image/cstepperimg16.webp";
import cstepper17 from "../src/image/cstepperimg17.webp";
import cstepper18 from "../src/image/cstepperimg19.webp";
import cstepper19 from "../src/image/cstepperimg20.webp";
import cstepper20 from "../src/image/cstepperimg21.webp";
import cstepper21 from "../src/image/cstepperimg22.webp";
import cstepper22 from "../src/image/cstepperimg23.webp";
import cstepper23 from "../src/image/cstepperimg24.webp";
import cstepper24 from "../src/image/cstepperimg25.webp";
import cstepper25 from "../src/image/cstepperimg1.webp";
import cstepper26 from "../src/image/cstepperimg26.webp";
import cstepper28 from "../src/image/cstepperimg28.webp";
import cstepper29 from "../src/image/cstepperimg29.webp";
import cstepper30 from "../src/image/cstepperimg30.webp";
import cstepper31 from "../src/image/cstepperimg31.webp";
import cstepper32 from "../src/image/cstepperimg32.webp";
import cstepper33 from "../src/image/cstepperimg33.webp";
import cstepper34 from "../src/image/cstepperimg34.webp";
import cstepper35 from "../src/image/speed_dating.webp";
// import cstepper36 from "../src/image/cstepperimg36";
import cstepper37 from "../src/image/cstepperimg37.webp";
import cstepper38 from "../src/image/cstepperimg38.webp";
import cstepper39 from "../src/image/cstepperimg39.webp";
import cstepper40 from "../src/image/cstepperimg40.webp";
import cstepper41 from "../src/image/cstepperimg41.webp";
import supplyimg1 from "../src/image/csupply1.webp";
import supplyimg2 from "../src/image/csupply2.webp";
import supplyimg3 from "../src/image/csupply3.webp";
import supplyimg4 from "../src/image/csupply4.webp";
import supplyimg5 from "../src/image/csupply5.webp";
import supplyimg6 from "../src/image/csupply6.webp";
import supplyimg7 from "../src/image/csupply7.webp";
import supplyimg8 from "../src/image/csupply8.webp";
import supplyimg9 from "../src/image/csupply9.webp";
import supplyimg10 from "../src/image/csupply10.webp";
import stepperimg15 from "../src/image/stepper-img15.webp";
import ReactGoogleAutocomplete from "react-google-autocomplete";

function Cstepper() {
  const [currentStep, setCurrentStep] = useState(1);
  const [guestCount, setGuestCount] = useState("");
  const [guestCountError, setGuestCountError] = useState("");
  let [supplierHour, setSupplierHour] = useState("");
  let [supplierHourError, setSupplierHourError] = useState("");
  const [budgetCount, setBudgetCount] = useState("");
  const [budgetError, setBudgetError] = useState("");
  const [stepError, setStepError] = useState("");
  let [location, setLocation] = useState(null);
  const [locationError, setLocationError] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [dateTimeError, setTimeDateError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isEcoFriendly, setIsEcoFriendly] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");
  const [flexibleBudget, setFlexibleBudget] = useState(false);
  const [otherEventPlan, setOtherEventPlanning] = useState("");
  const handleNext = () => {
    if (currentStep === 1 && eventOption.trim() === "") {
      setStepError("Please select an event option");
      return;
    } else if (
      currentStep === 2 &&
      Object.values(selectedSuppliers).every((value) => !value)
    ) {
      setStepError("Please select at least one supplier");
      return;
    } else if (
      currentStep === 2 &&
      selectedSuppliers.Other &&
      otherEventPlan.trim() === ""
    ) {
      setStepError("Please enter details for the 'Other' supplier");
      return;
    } else if (currentStep === 3 && location === null) {
      setLocationError(true);
      return;
    } else if (currentStep === 4 && date.trim() === "") {
      setTimeDateError("Please fill all fields");
      return;
    } else if (currentStep === 4 && time.trim() === "") {
      setTimeDateError("Please fill all fields");
      return;
    } else if (currentStep === 5 && supplierHour.trim() === "") {
      setSupplierHourError("Please enter a valid hour (0-24).");
      return;
    } else if (currentStep === 6 && guestCount.trim() < 1) {
      setGuestCountError("Please add number of guests");
      return;
    } else if (currentStep === 7 && budgetCount.trim() < 1) {
      setBudgetError("Please enter the budget");
      return;
    }

    setCurrentStep(currentStep + 1);
    setStepError("");
    setLocationError(false);
    setSupplierHourError("");
    setGuestCountError("");
    setBudgetError("");
    setTimeDateError("");
  };

  const handlePrev = () => {
    // Define a function to reset errors based on the current step
    const resetErrors = () => {
      switch (currentStep) {
        case 1:
          setStepError("");
          break;
        case 2:
          setStepError("");
          break;
        case 3:
          setSupplierHour("");
          break;
        case 4:
          setTimeDateError("");
          break;
        case 5:
          setSupplierHourError("");
          break;
        case 6:
          setGuestCountError("");
          break;
        case 7:
          setBudgetError("");
          break;
        default:
          break;
      }
    };

    // Reset errors
    resetErrors();

    // Navigate to the previous step
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1)); // Ensure currentStep doesn't go below 1
  };

  const validateForm = () => {
    let isValid = true;

    // Validate Name
    if (name.trim() === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate Email
    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    //role
    if (role.trim() === "") {
      setRoleError("Role is required");
    } else {
      setRoleError("");
    }

    if (phone.trim() === "") {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      // Remove any non-digit characters from the phone number
      const cleanedPhone = phone.replace(/\D/g, "");

      // Validate the cleaned phone number
      if (!/^\+?\d+$/.test(cleanedPhone)) {
        setPhoneError("Please enter a valid phone number with numbers only");
        isValid = false;
      } else {
        setPhoneError("");
      }
    }

    if (!agreedToTerms) {
      setTermsError("Please agree to the terms");
      isValid = false;
    } else {
      setTermsError("");
    }

    return isValid;
  };
  const navigate = useNavigate();
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      navigate("/thankupage");
      setName("");
      setEmail("");
      setPhone("");
      setRole("");
      setAgreedToTerms(false);

      const payload = {
        event_type: eventOption,
        supplier_type: Object.entries(selectedSuppliers)
          .filter(([key, value]) => value)
          .map(([key]) => key)
          .join(", "),
        otherSupplier: otherEventPlan ? otherEventPlan : null,
        location: location,
        date: date,
        time: time,
        hours: supplierHour,
        guests: guestCount,
        budget: budgetCount,
        is_budget_flexible: flexibleBudget,
        name: name,
        role: role,
        email: email,
        phone: phone,
        is_eco_friendly: ecoFriendly,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/supplierCampaign`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));
    } else {
      console.log("Form validation failed");
    }
  };

  useEffect(() => {
    // Log component mount for debugging
    console.log("Bstepper component has mounted");
  }, []);

  const renderButtons = () => {
    if (currentStep === 8) {
      return (
        <div className="fixed-bottom w-100 bg-white shadow border-top py-3">
          <Link to="/thankupage">
            {" "}
            <Button className="submit-btn" onClick={handleSubmit}>
              Submit
            </Button>
          </Link>
          <Button
            className="prev-btn"
            onClick={handlePrev}
            disabled={currentStep === 1}>
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div className="fixed-bottom stepper-btn w-100 bg-white shadow border-top py-3">
          <Button
            className="prev-btn"
            onClick={handlePrev}
            disabled={currentStep === 1}>
            Back
          </Button>
          <Button
            className="next-btn"
            onClick={handleNext}
            disabled={currentStep === 8}>
            Next
          </Button>
        </div>
      );
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  // const history = useLocation();

  const handleRoute = () => {
    const previousRoute = localStorage.getItem("previousRoute");

    // Navigate to the previous route
    navigate(previousRoute);
  };

  const [showOtherTextField, setShowOtherTextField] = useState(false);

  // Handler for the "Other" checkbox change
  // const handleOtherCheckboxChange = (event) => {
  //   setShowOtherTextField(event.target.checked);
  // };

  // start
  const [eventOption, setEventOption] = useState("");
  const [ecoFriendly, setEcoFriendly] = useState(false);
  const handleFirstStep = (event) => {
    setEventOption(event.target.value);
  };
  const handleOtherEventPlanning = (event) => {
    setOtherEventPlanning(event.target.value);
  };
  const handleEcoFriendlyCheckboxChange = (event) => {
    setEcoFriendly(event.target.checked);
  };

  const handleFlexibleBudget = (event) => {
    const value = event.target.checked;
    setFlexibleBudget(value);
  };

  //second
  const [selectedSuppliers, setSelectedSuppliers] = useState({
    Caterer: false,
    DJ: false,
    Florist: false,
    "Live Band": false,
    "Make Up Artist": false,
    "Mc/Host": false,
    Photobooth: false,
    Photography: false,
    Venue: false,
    Videography: false,
    Other: false,
  });

  const handleSecondStep = (event) => {
    const { value, checked } = event.target;

    if (value === "Other") {
      setShowOtherTextField(checked);
    }

    if (showOtherTextField) {
      setStepError("");
    }

    setSelectedSuppliers((prevSelected) => ({
      ...prevSelected,
      [value]: checked,
    }));
  };

  // third

  const handleAddressChange = (option) => {
    setLocation(option);
    location = option;
    if (!option) {
      setLocationError(true);
    } else {
      setLocationError(false);
    }
  };

  //fourth

  const handleDateChange = (event) => {
    // var now = new Date();
    const value = event.target.value;
    const target = new Date(value);
    setDate(value);
    if (target.getDate()) {
      setTimeDateError("");
    } else {
      setTimeDateError(
        "Date & time should be equal or greater than today's date"
      );
    }
  };

  const handleTimeChange = (event) => {
    const value = event.target.value;
    const today = new Date();
    const selectedDateTime = new Date(today.toDateString() + " " + value);

    // Check if the selected time is in the future
    if (selectedDateTime) {
      setTime(value);
      setTimeDateError("");
    } else {
      setTimeDateError("Time should be equal or greater than current time");
    }
  };

  // fifth

  const handleHourChange = (event) => {
    const value = event.target.value;

    // Validate the time
    if (
      value === "" ||
      (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 24)
    ) {
      setSupplierHour(value);
    } else {
      setSupplierHourError("");
    }
  };

  return (
    <>
      <div className="Bstepper-sec">
        <div className="container">
          <div className="Bstepper-sec-uper">
            <img src={stepperlogo} className="stepper-logo" alt="logo" />
            <div className="close" onClick={handleShow}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.54688 22.4483L22.4434 7.55176M7.54688 7.55176L22.4434 22.4483"
                  stroke="#5165AC"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="Bstepper-sec-bottom">
                <ProgressBar
                  now={(currentStep / 8) * 100}
                  label={`${currentStep}/8`}
                />

                <Form onSubmit={handleFormSubmit}>
                  <div className="form-inner">
                    {currentStep === 1 && (
                      <Form.Group controlId="step2">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What type of event are you planning?
                          </h3>
                        </div>

                        <div className="step2-inner text-center">
                          <div className="step-2-list">
                            <ul>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle2"
                                  name="eventOption"
                                  value={"Baby Shower"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Baby Shower"}
                                  required
                                />
                                <label htmlFor="vehicle2">
                                  <img src={cstepper2} alt="baby-shower" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Baby Shower
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle3"
                                  name="eventOption"
                                  value={"Birthday Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Birthday Party"}
                                  required
                                />
                                <label htmlFor="vehicle3">
                                  <img src={cstepper3} alt="birthday-party" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Birthday Party
                                </h4>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle5"
                                  name="eventOption"
                                  value={"Bridal Shower"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Bridal Shower"}
                                  required
                                />
                                <label htmlFor="vehicle5">
                                  <img src={cstepper5} alt="bridal-shower" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Bridal Shower
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle6"
                                  name="eventOption"
                                  value={"Christmas Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Christmas Party"}
                                  required
                                />
                                <label htmlFor="vehicle6">
                                  <img src={cstepper6} alt="christmas-party" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Christmas Party
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle7"
                                  name="eventOption"
                                  value={"Club Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Club Party"}
                                  required
                                />
                                <label htmlFor="vehicle7">
                                  <img src={cstepper7} alt="club-party" />
                                </label>
                                <h4 className="step2-list-desc-">Club Party</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle8"
                                  name="eventOption"
                                  value={"Community Event"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Community Event"}
                                  required
                                />
                                <label htmlFor="vehicle8">
                                  <img src={cstepper8} alt="community-event" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Community Event
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle9"
                                  name="eventOption"
                                  value={"Concert"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Concert"}
                                  required
                                />
                                <label htmlFor="vehicle9">
                                  <img src={cstepper9} alt="concert" />
                                </label>
                                <h4 className="step2-list-desc-">Concerts</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle10"
                                  name="eventOption"
                                  value={"Conference"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Conference"}
                                  required
                                />
                                <label htmlFor="vehicle10">
                                  <img src={cstepper10} alt="conference" />
                                </label>
                                <h4 className="step2-list-desc-">Conference</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle11"
                                  name="eventOption"
                                  value={"Congress"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Congress"}
                                  required
                                />
                                <label htmlFor="vehicle11">
                                  <img src={cstepper11} alt="congress" />
                                </label>
                                <h4 className="step2-list-desc-">Congress</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle12"
                                  name="eventOption"
                                  value={"Corporate"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Corporate"}
                                  required
                                />
                                <label htmlFor="vehicle12">
                                  <img src={cstepper12} alt="corporate" />
                                </label>
                                <h4 className="step2-list-desc-">Corporate</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle13"
                                  name="eventOption"
                                  value={"Charitable"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Charitable"}
                                  required
                                />
                                <label htmlFor="vehicle13">
                                  <img src={cstepper13} alt="charitable" />
                                </label>
                                <h4 className="step2-list-desc-">Charitable</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle14"
                                  name="eventOption"
                                  value={"Exhibition"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Exhibition"}
                                  required
                                />
                                <label htmlFor="vehicle14">
                                  <img src={cstepper14} alt="exhibition" />
                                </label>
                                <h4 className="step2-list-desc-">Exhibition</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle15"
                                  name="eventOption"
                                  value={"Fair"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Fair"}
                                  required
                                />
                                <label htmlFor="vehicle15">
                                  <img src={cstepper15} alt="fair" />
                                </label>
                                <h4 className="step2-list-desc-">Fair</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle16"
                                  name="eventOption"
                                  value={"Family gathering"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Family gathering"}
                                  required
                                />
                                <label htmlFor="vehicle16">
                                  <img
                                    src={cstepper16}
                                    alt="family-gathering"
                                  />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Family gathering
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle17"
                                  name="eventOption"
                                  value={"Father's Day"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Father's Day"}
                                  required
                                />
                                <label htmlFor="vehicle17">
                                  <img src={cstepper17} alt="fathers-day" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Father's Day
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle18"
                                  name="eventOption"
                                  value={"Festival"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Festival"}
                                  required
                                />
                                <label htmlFor="vehicle18">
                                  <img src={cstepper18} alt="festival" />
                                </label>
                                <h4 className="step2-list-desc-">Festival</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle19"
                                  name="eventOption"
                                  value={"Fundraising"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Fundraising"}
                                  required
                                />
                                <label htmlFor="vehicle19">
                                  <img src={cstepper19} alt="fundraising" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Fundraising
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle20"
                                  name="eventOption"
                                  value={"Gala Dinners"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Gala Dinners"}
                                  required
                                />
                                <label htmlFor="vehicle20">
                                  <img src={cstepper20} alt="gala-dinners" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Gala Dinners
                                </h4>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle22"
                                  name="eventOption"
                                  value={"Gender Reveal Party"}
                                  onChange={handleFirstStep}
                                  checked={
                                    eventOption === "Gender Reveal Party"
                                  }
                                  required
                                />
                                <label htmlFor="vehicle22">
                                  <img
                                    src={cstepper22}
                                    alt="gender-reveal-party"
                                  />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Gender Reveal Party
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle23"
                                  name="eventOption"
                                  value={"Halloween"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Halloween"}
                                  required
                                />
                                <label htmlFor="vehicle23">
                                  <img src={cstepper23} alt="halloween" />
                                </label>
                                <h4 className="step2-list-desc-">Halloween</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle24"
                                  name="eventOption"
                                  value={"HangOut"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "HangOut"}
                                  required
                                />
                                <label htmlFor="vehicle24">
                                  <img src={cstepper24} alt="hangout" />
                                </label>
                                <h4 className="step2-list-desc-">HangOut</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle26"
                                  name="eventOption"
                                  value={"Hen Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Hen Party"}
                                  required
                                />
                                <label htmlFor="vehicle26">
                                  <img src={cstepper26} alt="hen-party" />
                                </label>
                                <h4 className="step2-list-desc-">Hen Party</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle25"
                                  name="eventOption"
                                  value={"Meetup"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Meetup"}
                                  required
                                />
                                <label htmlFor="vehicle25">
                                  <img src={cstepper25} alt="meetup" />
                                </label>
                                <h4 className="step2-list-desc-">Meetup</h4>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle28"
                                  name="eventOption"
                                  value={"Mother’s Day"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Mother’s Day"}
                                  required
                                />
                                <label htmlFor="vehicle28">
                                  <img src={cstepper28} alt="mothers-day" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Mother’s Day
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle29"
                                  name="eventOption"
                                  value={"Musicals"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Musicals"}
                                  required
                                />
                                <label htmlFor="vehicle29">
                                  <img src={cstepper29} alt="musicals" />
                                </label>
                                <h4 className="step2-list-desc-">Musicals</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="eventOption"
                                  value={"Networking"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Networking"}
                                  required
                                />
                                <label htmlFor="vehicle1">
                                  <img src={cstepper4} alt="networking" />
                                </label>
                                <h4 className="step2-list-desc-">Networking</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle30"
                                  name="eventOption"
                                  value={"Office Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Office Party"}
                                />
                                <label htmlFor="vehicle30">
                                  <img src={cstepper30} alt="office-party" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Office Party
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle31"
                                  name="eventOption"
                                  value={"Prom"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Prom"}
                                  required
                                />
                                <label htmlFor="vehicle31">
                                  <img src={cstepper31} alt="prom" />
                                </label>
                                <h4 className="step2-list-desc-">Prom</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle32"
                                  name="eventOption"
                                  value={"Reunion"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Reunion"}
                                  required
                                />
                                <label htmlFor="vehicle32">
                                  <img src={cstepper32} alt="reunion" />
                                </label>
                                <h4 className="step2-list-desc-">Reunion</h4>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle33"
                                  name="eventOption"
                                  value={"Seminar"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Seminar"}
                                  required
                                />
                                <label htmlFor="vehicle33">
                                  <img src={cstepper33} alt="seminar" />
                                </label>
                                <h4 className="step2-list-desc-">Seminar</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle34"
                                  name="eventOption"
                                  value={"Speed Dating"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Speed Dating"}
                                  required
                                />
                                <label htmlFor="vehicle34">
                                  <img src={cstepper35} alt="speed-dating" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Speed Dating
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle35"
                                  name="eventOption"
                                  value={"Stag Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Stag Party"}
                                  required
                                />
                                <label htmlFor="vehicle35">
                                  <img src={cstepper34} alt="stag-party" />
                                </label>
                                <h4 className="step2-list-desc-">Stag Party</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle36"
                                  name="eventOption"
                                  value={"Staycation"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Staycation"}
                                  required
                                />
                                <label htmlFor="vehicle36">
                                  <img src={cstepper1} alt="staycation" />
                                </label>
                                <h4 className="step2-list-desc-">Staycation</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle37"
                                  name="eventOption"
                                  value={"Street Parties"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Street Parties"}
                                  required
                                />
                                <label htmlFor="vehicle37">
                                  <img src={cstepper37} alt="street-parties" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Street Parties
                                </h4>
                              </li>

                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle38"
                                  name="eventOption"
                                  value={"Themed Party"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Themed Party"}
                                  required
                                />
                                <label htmlFor="vehicle38">
                                  <img src={cstepper38} alt="themed-party" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Themed Party
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle39"
                                  name="eventOption"
                                  value={"Trade Show"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Trade Show"}
                                  required
                                />
                                <label htmlFor="vehicle39">
                                  <img src={cstepper39} alt="trade-show" />
                                </label>
                                <h4 className="step2-list-desc-">Trade Show</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle40"
                                  name="eventOption"
                                  value={"Valentines Day"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Valentines Day"}
                                  required
                                />
                                <label htmlFor="vehicle40">
                                  <img src={cstepper40} alt="valentines-day" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Valentine's Day
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle21"
                                  name="eventOption"
                                  value={"Wake"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Wake"}
                                  required
                                />
                                <label htmlFor="vehicle21">
                                  <img src={cstepper21} alt="wake" />
                                </label>
                                <h4 className="step2-list-desc-">Wake</h4>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  id="vehicle41"
                                  name="eventOption"
                                  value={"Wedding"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Wedding"}
                                  required
                                />
                                <label htmlFor="vehicle41">
                                  <img src={cstepper41} alt="wedding" />
                                </label>
                                <h4 className="step2-list-desc-">Wedding</h4>
                              </li>
                            </ul>
                          </div>
                          {stepError && (
                            <p className="error-message ">{stepError}</p>
                          )}
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 2 && (
                      <Form.Group controlId="step3">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What type of suppliers do you need?
                          </h3>
                          <p className="step1-desc text-center mb-5">
                            We work with the top suppliers in the country. Tell
                            us what you're looking for,<br></br>and we'll find
                            it for you.
                          </p>
                        </div>

                        <div className="step3-inner text-center">
                          <div className=" Btepper-sec-card step-2-list">
                            <ul>
                              {Object.keys(selectedSuppliers).map(
                                (supplier, index) => (
                                  <li key={index}>
                                    <input
                                      type="checkbox"
                                      id={`supply${index + 1}`}
                                      name="secondEventOption"
                                      value={supplier}
                                      onChange={handleSecondStep}
                                      checked={selectedSuppliers[supplier]}
                                    />
                                    <label htmlFor={`supply${index + 1}`}>
                                      {supplier === "DJ" && (
                                        <img src={supplyimg1} alt={`DJ`} />
                                      )}
                                      {supplier === "Caterer" && (
                                        <img src={supplyimg2} alt={`Caterer`} />
                                      )}
                                      {supplier === "Florist" && (
                                        <img src={supplyimg3} alt={`Florist`} />
                                      )}
                                      {supplier === "Live Band" && (
                                        <img
                                          src={supplyimg4}
                                          alt={`Live Band`}
                                        />
                                      )}
                                      {supplier === "Make Up Artist" && (
                                        <img
                                          src={supplyimg5}
                                          alt={`Make Up Artist`}
                                        />
                                      )}
                                      {supplier === "Mc/Host" && (
                                        <img src={supplyimg6} alt={`Mc/Host`} />
                                      )}
                                      {supplier === "Photobooth" && (
                                        <img
                                          src={supplyimg7}
                                          alt={`photobooth`}
                                        />
                                      )}
                                      {supplier === "Photography" && (
                                        <img
                                          src={supplyimg8}
                                          alt={`photography`}
                                        />
                                      )}
                                      {supplier === "Venue" && (
                                        <img src={supplyimg9} alt={`venue`} />
                                      )}
                                      {supplier === "Videography" && (
                                        <img
                                          src={supplyimg10}
                                          alt={`videography`}
                                        />
                                      )}
                                      {supplier === "Other" && (
                                        <img src={stepperimg15} alt={`other`} />
                                      )}
                                    </label>
                                    <h4 className="step2-list-desc-">
                                      {supplier}
                                    </h4>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>

                          {showOtherTextField && (
                            <div className="other-text-field">
                              <input
                                type="text"
                                id="otherTextField"
                                className="other-field"
                                placeholder="what other supplier(s) do you need?"
                                onChange={handleOtherEventPlanning}
                                value={otherEventPlan}
                                required
                              />
                            </div>
                          )}
                          {stepError && (
                            <p className="error-message ">{stepError}</p>
                          )}
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 3 && (
                      <Form.Group controlId="step4 step3">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What location is your event taking place?
                          </h3>
                          <p className="step-title text-center ">
                            Let us know the area where your event will take
                            place to enable us to source local suppliers.
                          </p>
                        </div>

                        <div
                          className="step-bottom text-center"
                          id="google-location">
                          <ReactGoogleAutocomplete
                            className="form-control"
                            apiKey="AIzaSyCCGghgOEG3d9HAF1UXIj5P9PBeQ_gvxls"
                            onPlaceSelected={(place) => {
                              handleAddressChange(place?.formatted_address);
                            }}
                            options={{
                              types: "(cities)",
                            }}
                            defaultValue={location}
                            placeholder="Enter postcode or address"
                          />
                        </div>
                        {locationError && (
                          <p className="error-location">
                            Please enter a location
                          </p>
                        )}
                        <div className=" ecofriendly-check">
                          <input
                            type="checkbox"
                            checked={ecoFriendly}
                            onChange={handleEcoFriendlyCheckboxChange}
                          />
                          <label className="checkbox-desc-ecofriendly">
                            Select if you would like to have eco-friendly
                            suppliers for your event.
                          </label>

                          <br />
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 4 && (
                      <Form.Group controlId="step4 step3">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What date is your event and the time it starts?
                          </h3>
                          <p className="step-title text-center">
                            The most accurate date will enable us to get the
                            best suppliers for you at the most affordable
                            prices.
                          </p>
                        </div>

                        <div className="date-time">
                          <div className="date">
                            <label htmlFor="date">Date</label>
                            <input
                              type="date"
                              id="date"
                              name="date"
                              placeholder="mm/dd/yyyy"
                              value={date}
                              min={new Date().toISOString().split("T")[0]}
                              onChange={handleDateChange}
                            />
                          </div>

                          <div className="time">
                            <label htmlFor="time">Time</label>
                            <input
                              type="time"
                              id="time"
                              name="time"
                              placeholder="--:--:--"
                              value={time}
                              onChange={handleTimeChange}
                            />
                          </div>
                        </div>

                        {dateTimeError && (
                          <p className="error-location ">{dateTimeError}</p>
                        )}
                      </Form.Group>
                    )}
                    {currentStep === 5 && (
                      <Form.Group controlId="step3 step6">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            How many hours do you need your supplier(s) for?
                          </h3>
                          <p className="step-title text-center ">
                            The number of hours you give us will determine the
                            price for your event.
                          </p>
                        </div>

                        <div className="step-bottom5 text-center">
                          <label>Number of hours:</label>
                          <input
                            type="number"
                            id="fname"
                            name="location"
                            placeholder="00"
                            value={supplierHour}
                            onChange={handleHourChange}
                            autoComplete="off"
                          />
                          {supplierHourError && (
                            <p className="error-message ">
                              {supplierHourError}
                            </p>
                          )}
                          <div className="step-svg"></div>
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 6 && (
                      <Form.Group controlId="step3 step6">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            How many guests are you expecting?
                          </h3>
                          <p className="step-title text-center ">
                            If you don’t know exactly, just give us your best
                            estimate.
                          </p>
                        </div>

                        <div className="step-bottom5 text-center">
                          <label>Number of Guests:</label>
                          <input
                            type="number"
                            id="fname"
                            name="location"
                            placeholder="0"
                            value={guestCount}
                            onChange={(e) => setGuestCount(e.target.value)}
                            autoComplete="off"
                          />
                          {guestCountError && (
                            <p className="error-message ">{guestCountError}</p>
                          )}
                          <div className="step-svg"></div>
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 7 && (
                      <Form.Group controlId="step3 step6">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What’s your estimated budget for this event?
                          </h3>
                          <p className="step-title text-center ">
                            We'll do our best to find the suppliers and services
                            that fit your budget
                          </p>
                        </div>

                        <div className="step-bottom5 text-center">
                          <label>
                            <svg
                              width="13"
                              height="19"
                              viewBox="0 0 13 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.8764 15.816V18.048H1.76438L1.28438 16.56C1.95638 15.84 2.45238 15.128 2.77238 14.424C3.09238 13.704 3.25238 12.816 3.25238 11.76C3.25238 11.44 3.21238 11.104 3.13238 10.752H0.492375V8.856H2.53238C2.19638 7.848 2.02838 6.872 2.02838 5.928C2.02838 4.856 2.26038 3.928 2.72438 3.144C3.18838 2.36 3.82838 1.76 4.64438 1.344C5.46038 0.912 6.40438 0.696 7.47638 0.696C9.18838 0.696 10.4844 1.176 11.3644 2.136C12.2444 3.096 12.7004 4.32 12.7324 5.808H10.2364C10.2364 4.944 10.0124 4.264 9.56438 3.768C9.11638 3.256 8.42038 3 7.47638 3C6.61238 3 5.91638 3.248 5.38838 3.744C4.87638 4.24 4.62038 5.016 4.62038 6.072C4.62038 6.536 4.66838 6.984 4.76438 7.416C4.86038 7.848 4.99638 8.328 5.17238 8.856H9.82838V10.752H5.72438C5.80438 11.12 5.84438 11.464 5.84438 11.784C5.84438 13.336 5.30838 14.68 4.23638 15.816H12.8764Z"
                                fill="#242424"
                              />
                            </svg>
                          </label>
                          <input
                            type="number"
                            id="fname"
                            name="location"
                            placeholder="000"
                            value={budgetCount}
                            onChange={(e) => setBudgetCount(e.target.value)}
                            autoComplete="off"
                          />
                        </div>

                        <div className=" select-step text-center step-inner-checkbox">
                          {budgetError && (
                            <p className="error-message ">{budgetError}</p>
                          )}
                          <div className="checkbox-msg">
                            <input
                              type="checkbox"
                              checked={flexibleBudget}
                              onChange={handleFlexibleBudget}
                            />
                            <label className="checkbox-desc">
                              My budget is flexible
                            </label>
                          </div>

                          <p className="step-title mt-2">
                            {" "}
                            Selecting this means you’ll get a broader range of
                            quotes to choose from
                          </p>
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 8 && (
                      <Form.Group controlId="step3 step6">
                        <div className="last-step">
                          <div className="step-upper ">
                            <h3 className="step1-heading text-center">
                              You're almost there!
                            </h3>
                            <p className="step-title text-center ">
                              Once you fill out your details below, someone from
                              our team will be in touch shortly.
                            </p>
                          </div>

                          <div className="Cstepper-form bsupply-sec-inner ">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label htmlFor="name" className="form-label">
                                    Name:<span className="color-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                  />
                                </div>
                                {nameError && (
                                  <p className="error-message">{nameError}</p>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label">
                                    What best describes your role?:
                                    <span className="color-red">*</span>
                                  </label>

                                  <select
                                    className="form-select"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}>
                                    <option value="Select an option">
                                      Select an option
                                    </option>
                                    <option value="Organising my own weddings">
                                      Organising my own wedding
                                    </option>
                                    <option>
                                      Organising an event on behalf of friends
                                      or family
                                    </option>
                                    <option value="Organising my own event">
                                      Organising my own event
                                    </option>
                                    <option value="Organising an event for my company">
                                      Organising an event for my company
                                    </option>
                                    <option value="Organising an event on behalf of a clientt">
                                      Organising an event on behalf of a clientt
                                    </option>
                                    <option value="Professional wedding Planner">
                                      Professional Wedding Planner
                                    </option>
                                  </select>
                                  {roleError && (
                                    <p className="error-message">{roleError}</p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="emailAddress"
                                    className="form-label">
                                    Email address:
                                    <span className="color-red">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    placeholder=""
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                                {emailError && (
                                  <p className="error-message">{emailError}</p>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="phoneNumber"
                                    className="form-label">
                                    Phone number:
                                    <span className="color-red">*</span>
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholer=""
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                  />
                                </div>
                                {phoneError && (
                                  <p className="error-message">{phoneError}</p>
                                )}
                              </div>
                            </div>

                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label">
                              Would you like eco-friendly suppliers?
                              <span className="color-red">*</span>
                            </label>
                            <div className="yes-no">
                              <div className="yes">
                                <input
                                  type="radio"
                                  id="age1"
                                  name="age"
                                  value="30"
                                  className="eco-friendly-inner-input"
                                  checked={isEcoFriendly}
                                  onChange={() => setIsEcoFriendly(true)}
                                />
                                <label
                                  htmlFor="age1"
                                  className="eco-friendly-inner-lable">
                                  Yes
                                </label>
                              </div>

                              <div className="no">
                                <input
                                  type="radio"
                                  id="age2"
                                  name="age"
                                  value="60"
                                  className="eco-friendly-inner-input"
                                  checked={!isEcoFriendly}
                                  onChange={() => setIsEcoFriendly(false)}
                                />
                                <label
                                  htmlFor="age2"
                                  className="eco-friendly-inner-lable">
                                  No
                                </label>
                              </div>
                            </div>

                            <div className=" mt-3  ">
                              <div className="checkbox-msg">
                                <input
                                  type="checkbox"
                                  checked={agreedToTerms}
                                  onChange={() =>
                                    setAgreedToTerms(!agreedToTerms)
                                  }
                                />
                                <label className="checkbox-desc">
                                  I agree with the{" "}
                                  <Link to="/terms">T&Cs </Link>
                                  and <Link to="/privacy">Privacy Policy</Link>
                                </label>

                                <br />
                              </div>
                              {termsError && (
                                <p className="error-message">{termsError}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    )}
                  </div>

                  <div className=" fixed-bottom stepper-btn  w-100 bg-white shadow border-top py-3">
                    <Button
                      className="prev-btn"
                      onClick={handlePrev}
                      disabled={currentStep === 1}>
                      Back
                    </Button>
                    <Button
                      className="next-btn"
                      onClick={handleNext}
                      disabled={currentStep === 8}>
                      Next
                    </Button>
                  </div>
                </Form>

                {renderButtons()}
              </div>
            </div>
          </div>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Want to leave the form?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="nav flex-column flex-nowrap">
                <li>
                  <div className="form-check d-flex align-items-baseline gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason-0"
                    />
                    <label className="form-check-label mb-0" htmlFor="reason-0">
                      I don’t have time to fill it out right now
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check d-flex align-items-baseline gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason-1"
                      defaultChecked
                    />
                    <label className="form-check-label mb-0" htmlFor="reason-1">
                      I’m not ready to enquire yet
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check d-flex align-items-baseline gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason-2"
                    />
                    <label className="form-check-label mb-0" htmlFor="reason-2">
                      I’m in the wrong place
                    </label>
                  </div>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No, continue with the form
              </Button>
              <Button variant="primary" onClick={handleRoute}>
                Yes, leave the form
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Cstepper;
