import React from "react";
import sidemaplogo from "../src/image/sidemap-logo.png";
import { Link } from "react-router-dom";


function Sitemap(){
    return(
        <>

        <div className="sitemap-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sitemap-top-inner">
                          <img src={sidemaplogo} className="sidemao-logo"/>
                        </div>
                      
                    </div>
                </div>
             </div>
        </div>


        <div className="vendior-middle">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                         <div className="vendior-middle-inner">
                            <p className="vendior-btn">Vendoir</p>
                              <div className="coustom-Business">
                                  <div className="coustom">
                                      <Link to="/" className="coustom-Business-link">For Consumers</Link>  

                                      <div className="coustom-inner">
                                        <div className="sidemap-blog-sec">
                                        <Link to="/" className="coustom-business-link">Home Page</Link>
                                        </div>

                                        <div className="sidemap-blog-sec">
                                        <Link to="/about"className="coustom-business-link">About</Link>
                                        </div>
                                     
                                        <div className="sidemap-blog-sec">
                                      <a href="https://www.facebook.com/groups/890675242565244/" target="_blank"  className="coustom-business-link">Community</a>
                                      <div className="sidemap-blog-sec-inner">
                                             <Link to="/referfriend" className="sidemap-blog-sec-inner-link">refer a friend</Link>
                                         </div>
                                       </div>

                                      <div className="sidemap-blog-sec">
                                      <Link to="/blog"className="coustom-business-link">Blog</Link>
                                        <div className="sidemap-blog-sec-inner">
                                             <Link to="/blog/corporate-event-trends"  className="sidemap-blog-sec-inner-link">Corporate event trends</Link>
                                             <Link to="/blog/ugly-christmas-jumper" className="sidemap-blog-sec-inner-link">ugly Christmas jumper</Link>
                                             <Link to="/blog/major-challenges-in-events-industry" className="sidemap-blog-sec-inner-link">Major challenge in event industry</Link>
                                             <Link to="/blog/spook-tacular-halloween" className="sidemap-blog-sec-inner-link">Spook tacular halloweenSpook tacular halloween</Link>
                                             <Link to="/blog/why-invest-in-sustainable" className="sidemap-blog-sec-inner-link">Why invest in sustainable</Link>
                                             <Link to="/blog/vendoir-for-business" className="sidemap-blog-sec-inner-link">Vendoir for business</Link>
                                             <Link to="/blog/summer-event-planning-guide" className="sidemap-blog-sec-inner-link">Summer event planning guide</Link>
                                             <Link to="/blog/winter-wedding" className="sidemap-blog-sec-inner-link">Winter wedding</Link>
                                             <Link to="/blog/bonfire-night-london-2022" className="sidemap-blog-sec-inner-link">Bonfire night london 2022</Link>
                                             <Link to="/blog/christmas-theme-ideas-guide" className="sidemap-blog-sec-inner-link">Chirstmas theme ideas guide</Link>
                                             <Link to="/blog/christmas-party-cost" className="sidemap-blog-sec-inner-link">Christmas party cost </Link>
                                             <Link to="/blog/more-bookings-on-vendoir" className="sidemap-blog-sec-inner-link">More booking on Vendoir</Link>
                                             <Link to="/blog/wedding-photographer" className="sidemap-blog-sec-inner-link">Wedding photographer</Link>
                                             <Link to="/blog/wedding-trends-2022" className="sidemap-blog-sec-inner-link">Wedding trends 2022</Link>
                                             <Link to="/blog/find-perfect-supplier" className="sidemap-blog-sec-inner-link">Find perfect supplier</Link>
                                             <Link to="/blog/sell-online" className="sidemap-blog-sec-inner-link">Sell online</Link>
                                             <Link to="/blog/beta" className="sidemap-blog-sec-inner-link">Beta</Link>
                                             <Link to="/blog/london-partners" className="sidemap-blog-sec-inner-link">London partners</Link>
                                             <Link to="/blog/news" className="sidemap-blog-sec-inner-link">News</Link>
                                             <Link to="/blog/ceo" className="sidemap-blog-sec-inner-link">Ceo</Link>
                                         </div>
                                      </div>
                                         
                                         <div className="sidemap-blog-sec">
                                          <Link to="/getapp"className="coustom-business-link">Get App</Link>
                                         </div>

                                         <div className="sidemap-blog-sec">
                                            <Link to="/" className="coustom-business-link">Footer</Link>
                                           <div className="sidemap-blog-sec-inner">
                                             <Link to="/terms" className="sidemap-blog-sec-inner-link">Website Terms</Link>
                                             <Link to="/cookies" className="sidemap-blog-sec-inner-link">Cookie Policy</Link>
                                             <Link to="/disclamer" className="sidemap-blog-sec-inner-link">Disclaimer</Link>
                                             <Link to="/privacy" className="sidemap-blog-sec-inner-link">Privacy Policy</Link>
                                             <Link to="/founders" className="sidemap-blog-sec-inner-link">Founding Members</Link>
                                         </div>
                                       </div>
                                     
                                      </div>

                                  </div>
                                  <div className="Business">
                                    <Link to="/Business" className="coustom-Business-link">For Business</Link>  


                                    <div className="business-inner">
                                        <div className="sidemap-blog-sec">
                                        <Link to="/business" className="coustom-business-link">Home Page</Link>
                                        </div>

                                        <div className="sidemap-blog-sec"> 
                                        <Link to="/business-about" className="coustom-business-link">About</Link>
                                        </div>
                                     
                                      
                                      <div className="sidemap-blog-sec">
                                      <Link to="/service" className="coustom-business-link">Service</Link>
                                      <div className="sidemap-blog-sec-inner">
                                             <Link to="/busniess-refer" className="sidemap-blog-sec-inner-link">refer a Business</Link>
                                      </div>
                                      </div>

                                      <div className="sidemap-blog-sec">
                                        <Link to="/pricing" className="coustom-business-link">Pricing</Link>
                                      </div>
                                     
                                       <div className="sidemap-blog-sec">
                                      <Link to="/sustainability" className="coustom-business-link">Sustainability</Link>
                                      <div className="sidemap-blog-sec-inner">
                                             <Link to="/resource/embracing-sustainability" className="sidemap-blog-sec-inner-link">Embracing sustainability</Link>
                                             <Link to="/resource/keysteps"className="sidemap-blog-sec-inner-link">Keysteps</Link>
                                             <Link to="/resource/make-right-selection"className="sidemap-blog-sec-inner-link">Make right selection</Link>
                                             <Link to="/resource/support-organizer"className="sidemap-blog-sec-inner-link">Support organiser</Link>
                                             <Link to="/resource/business-to-sustainable"className="sidemap-blog-sec-inner-link">Business to sustainablee</Link>
                                             <Link to="/resource/help-business-events"className="sidemap-blog-sec-inner-link">Help business events</Link>
                                      </div>
                                      </div>

                                      <div className="sidemap-blog-sec">
                                            <Link to="/Business" className="coustom-business-link">Busniess Footer</Link>
                                           <div className="sidemap-blog-sec-inner">
                                             <Link to="/Bterms" className="sidemap-blog-sec-inner-link">Website Terms</Link>
                                             <Link to="/business-cookies" className="sidemap-blog-sec-inner-link">Cookie Policy</Link>
                                             <Link to="/business-disclamier" className="sidemap-blog-sec-inner-link">Disclaimer</Link>
                                             <Link to="/business-privacy" className="sidemap-blog-sec-inner-link">Privacy Policy</Link>
                                             <Link to="/BusinessFounder" className="sidemap-blog-sec-inner-link">Founding Members</Link>
                                         </div>
                                       </div>

                                    
                                    </div>
                                  </div>
                              </div>

                           




                         </div>
                    </div>
                </div>
            </div>
         </div>
        </>
    )
}



export default Sitemap;