import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../src/image/vendior-logo.svg";

function Aheader() {

  return (
    <>
      <section className="header app-header">
        <div className=" container nav_bg">
          <div className="row">
            <div className="col-12">
              <Navbar expand="lg">
                <Container>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Navbar.Brand>
                    <div className="header-logo">
                     <img src={logo} className="header-logo" alt="logo" />
                    </div>
                  </Navbar.Brand>

                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto ">
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Aheader;
