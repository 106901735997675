import React from 'react';
import Errorimage from '../src/image/404-image.webp';
import { Link } from 'react-router-dom';

function Error(){
  return (
    <div>
      <div className="error-page">
         <div className="error-page-inner">
         <div className="error-image">
          <img src={Errorimage}/>
         </div>
         <div className="error-text">
         <h4>Ooups, page not found</h4>
         <p>This page was not found. You may have mistyped the address or the page may have moved.</p>
         <div className='error-button'><Link to="/"><button className="error-button" >Back to Home Page</button></Link></div>  
         </div>
         </div>
      </div>
    </div>
  );
};

export default Error;