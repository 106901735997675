import React, { useState } from "react";
import Bnb from "./Bnb";
import BFooter from "./BFooter";

function Pricing() {
  const [currentForm, setCurrentForm] = useState(null);
  const [firstClick, setFirstClick] = useState(true);
  // const [isfieldempty, Setisfieldempty] = useState(false);
  const [message, setMessage] = useState("");
  const [fNameError, setFNameError] = useState("");
  const [fName, setFName] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [lName, setLName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [company, setCompany] = useState("");
  const [descError, setDescError] = useState("");
  const [desc, setDesc] = useState("");
  const [eventError, setEventError] = useState("");
  const [event, setEvent] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubscribeClick = () => {
    if (!validateForm()) {
      setSuccessMessage("");
      setMessage(" Please Fill the form Field");
    } else if (!isCheckboxChecked) {
      setMessage("Please agree to term and condition");
    } else {
      const payload = {
        first_name: fName,
        last_name: lName,
        email: email,
        company: company,
        event_type: event,
        event_desc: desc,
        package_type: currentForm,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/package`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));
      setMessage("");
      setSuccessMessage(
        "Thank you for submitting, we will be in touch shortly"
      );
      setFName("");
      setLName("");
      setEmail("");
      setDesc("");
      setCompany("");
      setIsCheckboxChecked(false);
      setIsCheckbox2Checked(false);
    }
  };

  const validateForm = () => {
    let isValid = true;

    // Validate First Name
    if (fName.trim() === "") {
      setFNameError("First Name is required");
      isValid = false;
    } else {
      setFNameError("");
    }

    // Validate First Name
    if (lName.trim() === "") {
      setLNameError("Last Name is required");
      isValid = false;
    } else {
      setLNameError("");
    }

    // Validate Describe event
    if (desc.trim() === "") {
      setDescError("Event description is required");
      isValid = false;
    } else {
      setDescError("");
    }

    // Validate Describe event
    if (event.trim() === "") {
      setEventError("Event type is required");
      isValid = false;
    } else {
      setEventError("");
    }

    // Validate Company
    if (company.trim() === "") {
      setCompanyError("Company name is required");
      isValid = false;
    } else {
      setCompanyError("");
    }

    // Validate Email
    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate Checkbox

    if (!isCheckboxChecked) {
      setCheckBoxError("Please agree to term and condition");
      isValid = false;
    } else {
      setCheckBoxError("");
    }

    return isValid;
  };

  const resetForm = () => {
    setIsCheckboxChecked(false);
    setIsCheckbox2Checked(false);
    setFName("");
    setFNameError("");
    setLName("");
    setLNameError("");
    setEmail("");
    setEmailError("");
    setCompany("");
    setCompanyError("");
    setEvent("in_person");
    setEventError("");
    setDesc("");
    setDescError("");
    setMessage("");
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckbox2Checked, setIsCheckbox2Checked] = useState(false);
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleCheckbox2Change = () => {
    setIsCheckbox2Checked(!isCheckbox2Checked);
  };

  const handleButtonClick = (formNumber) => {
    if (firstClick) {
      // Logic to scroll down to the bottom of the page
      const element = document.querySelector(".Sustainability-new-sec");
      const scrollPosition = element.offsetTop + element.offsetHeight - 200;

      // Scroll to the calculated position
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });

      // Update state to indicate that the button has been clicked
      setFirstClick(false);
    }
    resetForm();
    // Set the current form based on the button click
    switch (formNumber) {
      case 1:
        setCurrentForm("Starter Package");
        break;
      case 2:
        setCurrentForm("Growth Package");
        break;
      case 3:
        setCurrentForm("Enterprise Package");
        break;
      default:
        setCurrentForm(null);
    }
  };

  return (
    <>
      <Bnb />

      <div className="Ourpackage-image">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>

      <div className="Sustainability-new-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-detail-sec-inner text-center">
                <h2 className="Blog-detai-heading b-heading">
                  Our service packages
                </h2>
                <p className="Sustainability-new-title">
                  Vendoir offers a range of pricing packages to suit the needs
                  of businesses of all sizes. Our packages are designed{" "}
                  <br></br>to provide comprehensive event planning and
                  management support, while also promoting sustainability.
                </p>
              </div>

              <div className="sub-packages">
                <div className="row">
                  <div className="col-lg-4 col-12">
                    <div className="starter-package">
                      <div className="package-content">
                        <h4>Starter Package</h4>
                        <div className="package-list">
                          <ul>
                            <li>Free Event Consultation</li>
                            <li>
                              Access to a curated network of eco-friendly
                              suppliers
                            </li>
                            <li>
                              Calculate your carbon footprint and learn how to
                              offset it.
                            </li>
                            <li>
                            Sustainable ticketing platform
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="package-btn"
                        onClick={() => handleButtonClick(1)}>
                        Get Started
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-12">
                    <div className="starter-package growth-package">
                      <div className="package-content">
                        <h4>Growth Package</h4>
                        <div className="package-list">
                          <ul>
                            <li>All Starter Package benefits</li>
                            <li>Bespoke event management</li>
                            <li>Dedication event planner</li>
                            <li>
                              Discounts on the event-secondary sustainable
                              marketplace
                            </li>
                            <li>24/7 support</li>
                            <li>
                            Sustainable ticketing platform
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="package-btn"
                        onClick={() => handleButtonClick(2)}>
                        Get Started
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  col-12">
                    <div className="starter-package">
                      <div className="package-content">
                        <h4>Enterprise Package</h4>
                        <div className="package-list">
                          <ul>
                            <li>All Growth Package benefits</li>
                            <li>Customized branding and reporting</li>
                            <li>
                              Customised event planning, sourcing, and
                              management
                            </li>
                            <li>
                              Comprehensive guidance from a team of experts to
                              achieve your event sustainability goals.
                            </li>
                            <li>
                            Sustainable ticketing platform
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="package-btn"
                        onClick={() => handleButtonClick(3)}>
                        Get Started
                      </div>
                    </div>
                  </div>

                  {currentForm && (
                    <div>
                      <div className="Sustainability-new-sec Gpackage-sec form-head">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="blog-detail-sec-inner text-center">
                                <h2 className="Blog-detai-heading">
                                  {currentForm}
                                </h2>
                              </div>
                              <div className="bsupply-sec-inner Gpackage-inner">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label">
                                        First Name
                                        <span className="color-red">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={fName}
                                        onChange={(e) =>
                                          setFName(e.target.value)
                                        }
                                        id="exampleInputPassword1"
                                        aria-describedby="emailHelp"
                                      />
                                      {fNameError && (
                                        <p className="error-message">
                                          {fNameError}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label">
                                        Last Name
                                        <span className="color-red">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        value={lName}
                                        onChange={(e) =>
                                          setLName(e.target.value)
                                        }
                                      />
                                      {lNameError && (
                                        <p className="error-message">
                                          {lNameError}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label">
                                        Email address:
                                        <span className="color-red">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                      />
                                      {emailError && (
                                        <p className="error-message">
                                          {emailError}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 ">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label">
                                        Company Name
                                        <span className="color-red">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        value={company}
                                        onChange={(e) =>
                                          setCompany(e.target.value)
                                        }
                                        className="form-control"
                                        id="exampleInputPassword1"
                                      />
                                      {companyError && (
                                        <p className="error-message">
                                          {companyError}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-lg-6 ">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label">
                                        What type of event:
                                        <span className="color-red">*</span>
                                      </label>
                                      <select
                                        onChange={(e) =>
                                          setEvent(e.target.value)
                                        }
                                        className="form-select">
                                        <option
                                          selected={event === "in_person"}
                                          value="in_person">
                                          In-person
                                        </option>
                                        <option
                                          selected={event === "hybrid"}
                                          value="hybrid">
                                          Hybrid Event
                                        </option>
                                        <option
                                          selected={event === "virtual"}
                                          value="virtual">
                                          Virtual Event
                                        </option>
                                        <option
                                          selected={event === "sustainable"}
                                          value="sustainable">
                                          Sustainable Event
                                        </option>
                                      </select>
                                      {eventError && (
                                        <p className="error-message">
                                          {eventError}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label">
                                        Describe your event:
                                        <span className="color-red">*</span>
                                      </label>
                                      <input
                                        value={desc}
                                        onChange={(e) =>
                                          setDesc(e.target.value)
                                        }
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                      />
                                      {descError && (
                                        <p className="error-message">
                                          {descError}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="checkbox-msg mb-3">
                                  <input
                                    type="checkbox"
                                    onChange={handleCheckboxChange}
                                    checked={isCheckboxChecked}
                                  />
                                  <label className="checkbox-desc agree-msg">
                                    I agree to receive other communications from
                                    Vendoir.<span className="color-red">*</span>
                                  </label>
                                </div>
                                <div className="checkbox-msg">
                                  <input
                                    type="checkbox"
                                    onChange={handleCheckbox2Change}
                                    checked={isCheckbox2Checked}
                                  />
                                  <label className="checkbox-desc">
                                    To provide you with the content requested,
                                    we need to store and process your data.I
                                    agree to allow Vendoir to store and process
                                    my data<span className="color-red">*</span>
                                  </label>
                                  <br />
                                </div>
                                {checkBoxError && (
                                  <p className="error-message">
                                    {checkBoxError}
                                  </p>
                                )}

                                <div className="contact-btn">
                                  <button
                                    onClick={handleSubscribeClick}
                                    type="submit"
                                    className="contact-button">
                                    Submit
                                  </button>
                                </div>
                                <p className="Join Waitlist-message">
                                  {successMessage}
                                </p>
                                {message && (
                                  <p className="Join Waitlist-message"></p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default Pricing;
