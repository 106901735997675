import React from "react";
import Nb from "./Nb";
import Footer from "./Footer";
import Bcard from "./Bcard";
import Bdata from "./Bdata";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <>
      <Nb />

      {/* image-sec */}

      <div className="blog-image-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>

      <div className="our-blog">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="blog-sec-heading">Our Blog</h2>
            </div>
          </div>

          <div className="our-blog-bottom">
            <div className="row ">
              <div className="col-lg-12 col-12 ">
                <div className="our-blog-inner-left">
                  {Bdata.map((val, ind) => {
                    return (
                      <Bcard
                        img={val.img}
                        title={val.title}
                        heading={val.heading}
                        desc={val.desc}
                        link={val.link}
                        linktag={val.linktag}
                        date={val.date}
                        key={ind}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="glow-blue-white-btn mb-5">
        <div className="glow-blue-btn">
          <Link to="/event-planning-consumer">Start planning</Link>
        </div>

        <div className="glow-whit-btn">
          <Link to="/supplier">Become a Supplier</Link>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blog;
