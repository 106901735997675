import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogFifteen() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-fifteen">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                How to find the perfect supplier for your event?
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">09 June,2022</p>
              </div>

              <p className="detail-inner-desc">
                The right supplier is one of the many factors that contribute to
                the success of an event. The success of your events depends on
                your ability to select the best supplier for the job. Even
                though event planning may be a challenging task, the right
                suppliers can make it a delight for everyone.
                <br />
                Shortly, it is essential to approach the appropriate people to
                form a balanced team that can execute the event efficiently. To
                avoid any inconvenience on the day of your event, you should
                discuss everything ahead of time.
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">1. Be proactive</h3>
                <p className="blog-inner-lists-title">
                  To find the right supplier, you need to be proactive. That is
                  how you get enough time to discuss things with the supplier.
                  Plus, you get enough time to finalize the supplier that makes
                  the perfect arrangements. So, contacting a supplier some
                  months before the actual event is preferable.If you are short
                  on time, I have some excellent news. You may use the app
                  Vendoir to approach the best suppliers from the comfort of
                  your home.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  2. Decide upon the supplier type
                </h3>
                <p className="blog-inner-lists-title">
                  So, before you book a supplier, you must first decide what
                  sort of supplier you require. Several suppliers hold different
                  types of events. i.e., if you use the Vendoir app to source
                  for a supplier, you will see that there are DJs,
                  Photographers, Videographers, MCs, Live Bands, and 360
                  Photobooth available. You can then find suppliers who suit the
                  planned event type
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  3. Negotiate with the supplier
                </h3>
                <p className="blog-inner-lists-title">
                  Negotiating the price, the sort of services you want, and
                  everything else is one of the essential steps you must take
                  before booking the supplier. You not only get the idea of
                  their services and availability but also build a connection
                  with them. Most importantly, ask about previous events they
                  organized to get a sense of the job as they are the ones who
                  will arrange everything for you. By using the Vendoir App
                  budget feature, you can source for suppliers within your price
                  range in just a few taps.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  4. Look up references
                </h3>
                <p className="blog-inner-lists-title">
                  Another way to find a supplier before booking them is by
                  asking for referrals. In addition to doing some research
                  yourself, you can also rely on references. That may not always
                  be effective, but you are less likely to get stuck choosing a
                  service that does not work for you.
                  <br />
                  The Vendoir App reviews and ratings feature allows you to make
                  informed decisions faster without asking for referrals.
                </p>
              </div>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogFifteen;
