import React from "react";
import Rcard from "./Rcard";
import Resourcedata from "./Resourcedata";
import Bnb from "./Bnb";
import BFooter from "./BFooter";

function Resource() {
  return (
    <>
      <Bnb />

      <div className="blog-image-sec resource-image-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="resource-heading">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="blog-sec-heading">Resources </h2>
              <p className="reesource-main-desc">
                More and more businesses are adopting sustainability as a core
                value, and we have provided resources to assist you at every
                step. Vendoir provides a specially curated platform of
                eco-friendly suppliers, as well as a range of tools and
                resources to support sustainable practices. Additionally,
                Vendoir allows you to track and report your sustainability
              </p>
            </div>
          </div>
        </div>

        <div className="our-blog-bottom">
          <div className="row ">
            <div className="col-lg-12 col-12 ">
              <div className="our-blog-inner-left">
                {Resourcedata.map((val, ind) => {
                  return (
                    <Rcard
                      img={val.img}
                      heading={val.heading}
                      desc={val.desc}
                      link={val.link}
                      linktag={val.linktag}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default Resource;
