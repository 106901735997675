
import bcard1 from "../src/image/bcard-img1.webp";
import bcard2 from "../src/image/bcard-img2.webp";
import bcard3 from "../src/image/bcard-img3.webp";
import bcard4 from "../src/image/bcard-img4.webp";
import bcard5 from "../src/image/bcard-img5.webp";
import bcard6 from "../src/image/bcard-img6.webp";
import bcard7 from "../src/image/bcard-img7.webp";
import bcard8 from "../src/image/bcard-img8.webp";
import bcard9 from "../src/image/bcard-img9.webp";
import bcard10 from "../src/image/bcard-img10.webp";
import bcard11 from "../src/image/bcard-img11.webp";
import bcard12 from "../src/image/bcard-img12.webp";
import bcard13 from "../src/image/bcard-img13.webp";
import bcard14 from "../src/image/bcard-img14.webp";
import bcard15 from "../src/image/bcard-img15.webp";
import bcard16 from "../src/image/bcard-img16.webp";
import bcard17 from "../src/image/bcard-img17.webp";
import bcard18 from "../src/image/bcard-img18.webp";
import bcard19 from "../src/image/bcard-img19.webp";
import bcard20 from "../src/image/bcard-img20.webp";
import bcard21 from "../src/image/bcard-img21.webp";
import bcard22 from "../src/image/bcard-img22.webp";
import bcard23 from "../src/image/bcard-img23.webp";
import bcard24 from "../src/image/bcard-img24.webp";
import bcard25 from "../src/image/bcard-img25.webp";

const Bdata = [
  {
    img: bcard21,
    title: "Building Vendoir",
    date: "12 April 2024",
    heading: "Notting Hill Carnival Helping Guide",
    desc: "The Notting Hill Carnival is just around the corner! This Caribbean inspired celebration will commence in late August. This 3 day long festival will need tons of preparation in order to make this the best Notting Hill Carnival to date. ",
    linktag: "/blog/nothing-hill-carnival",
  },  
  {
    img: bcard22,
    title: "Building Vendoir",
    date: "1 April 2024",
    heading: "Relax During This May Bank Holiday !",
    desc: "As May approaches, anticipation builds for the upcoming Bank Holiday weekend. For many, this long weekend represents an opportunity to relax, unwind, and make lasting memories with loved ones. No matter what your ",
    link: "Read More",
    linktag: "/blog/relax-during-this-may",
  },
  {
    img: bcard23,
    title: "Building Vendoir",
    date: "15 March 2024",
    heading: "Summer Wedding Season Guide",
    desc: "There is no better time of year to get married than the summer! We at Vendoir want to make this the most stress-free wedding season yet! As the warm season draws near, here are some ways to elevate your upcoming summer ",
    link: "Read More",
    linktag: "/blog/summer-wedding",
  },
  {
    img: bcard24,
    title: "Building Vendoir",
    date: "4 February 2024",
    heading: "Host a Unique Mother’s Day!",
    desc: "Mother’s Day is quickly approaching. Let’s shake things up this year and make your mother feel the utmost love this Mothers Day.",
    link: "Read More",
    linktag: "/Blog/mothers-day",
  },
  {
    img: bcard25,
    title: "Building Vendoir",
    date: "8 January 2024",
    heading: "What is “Dry” Hiring?",
    desc: "Have you ever heard of “dry” hiring a venue? Vendoir is here to let you in on the latest trend in event planning!",
    link: "Read More",
    linktag: "/Blog/dry-hiring",
  },

  {
    img: bcard1,
    title: "Building Vendoir",
    date: "21 December,2023",
    heading: "Corporate event trend predictions",
    desc: "As we step into a new year, the event planning landscape is evolving, offering exciting possibilities for unforgettable experiences. Let’s explore the upcoming trends that will shape the events...",
    link: "Read More",
    linktag: "/Blog/corporate-event-trends",
  },

  {
    img: bcard2,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "The ugly christmas Jumper competition",
    desc: "In the midst of Christmas glee, there has always been an underrated item of clothing that gets ridiculed without fail every year. That’s right its the UGLY CHRISTMAS Jumper. Ah, the time-honored...",
    link: "Read More",
    linktag: "/blog/ugly-christmas-jumper",
  },

  {
    img: bcard3,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "Navigating The Tumultuous Waters",
    desc: "The events industry is a dynamic and ever-evolving field, known for its capacity to adapt to various trends, technologies, and consumer preferences. However, like any other sector, it faces its share of...",
    link: "Read More",
    linktag: "/blog/major-challenges-in-events-industry",
  },

  {
    img: bcard4,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "Top 5 Spook-Tacular Halloween Activities",
    desc: "Here Ye, Here Ye, Halloween is Nigh and if you’re still freaking out about where to go and what to do then hath no fear the Team at Vendoir have rounded up top 5 events/ideas you could do to get...",
    link: "Read More",
    linktag: "/blog/spook-tacular-halloween",
  },

  {
    img: bcard5,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "Why It's Good To Invest In A Sustainable Business",
    desc: "In today’s rapidly changing world, sustainability has evolved from a buzzword into a fundamental consideration for businesses, investors, and consumers. Sustainable practices are not only...",
    link: "Read More",
    linktag: "/blog/why-invest-in-sustainable",
  },

  {
    img: bcard6,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "Making Business Events Revolutionary In The UK",
    desc: "The changing corporate landscape of the UK has made planning successful events an essential part of any company’s or organization’s growth strategy. The way we organize and carry out events for...",
    link: "Read More",
    linktag: "/blog/vendoir-for-business",
  },

  {
    img: bcard7,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "Your Summer Event Planning Guide With Vendoir",
    desc: "As the days grow longer and temperatures begin to climb, summer’s promise of fun and freedom hovers in the air. Social calendars start to fill with an array of events: weddings, graduation parties,",
    link: "Read More",
    linktag: "/blog/summer-event-planning-guide",
  },
  {
    img: bcard8,
    title: "Building Vendoir",
    date: "05 June,2023",
    heading: "Plan A Winter Wedding That Will Deserve All The Compliments",
    desc: "Let your wedding be the most memorable one for your guests. Yes, winter weddings can be incredibly magical since winter wedding comes with a lot of practical benefits. Nothing more magical could be...",
    link: "Read More",
    linktag: "/blog/winter-wedding",
  },
  {
    img: bcard9,
    title: "Building Vendoir",
    date: "17 November,2022",
    heading: "What Is On At The Best Bonfire Night Events In London In 2022?",
    desc: "Let your wedding be the most memorable one for your guests. Yes, winter weddings can be incredibly magical since winter wedding comes with a lot of practical benefits. Nothing more magical could be...",
    link: "Read More",
    linktag: "/blog/bonfire-night-london-2022",
  },
  {
    img: bcard10,
    title: "Building Vendoir",
    date: "11 October,2022",
    heading: "The Ultimate Christmas Party Theme Ideas Guide",
    desc: "You don’t need to stick to your clan’s holiday traditions when throwing the ultimate Christmas party. There are specific ways you can make this event better this year with fantastic party themes.",
    link: "Read More",
    linktag: "/blog/christmas-theme-ideas-guide",
  },
  {
    img: bcard11,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "What Should The Cost Of Your Christmas Party Be?",
    desc: "Here Ye, Here Ye, Halloween is Nigh and if you’re still freaking out about where to go and what to do then hath no fear the Team...",
    link: "Read More",
    linktag: "/blog/christmas-party-cost",
  },
  {
    img: bcard12,
    title: "Building Vendoir",
    date: "13 September,2022",
    heading: "How To Get More Bookings On Vendoir",
    desc: "endoir is a great way to make a bit of extra cash, meet new people and utilise your talents by offering them as a service. A lot of people sign up and wonder how they can give themselves the best",
    link: "Read More",
    linktag: "/blog/more-bookings-on-vendoir",
  },
  {
    img: bcard13,
    title: "Building Vendoir",
    date: "09 June,2022",
    heading: "Hiring The Best Wedding Photographer For Your Big Day!",
    desc: "One of the most significant occasions in your life is your wedding. Your wedding is a non-repeatable event, that is why the pictures must be memorable also. But when you look at the list of wedding",
    link: "Read More",
    linktag: "/blog/wedding-photographer",
  },
  {
    img: bcard14,
    title: "Building Vendoir",
    date: "01 June,2022",
    heading: "Biggest Wedding Trends 2022",
    desc: "As we approach the end of the year, wedding planners everywhere are starting to finalize their plans for the coming years. And if you are also like most couples, you are probably wondering what",
    link: "Read More",
    linktag: "/blog/wedding-trends-2022",
  },
  {
    img: bcard15,
    title: "Building Vendoir",
    date: "02 May,2023",
    heading: "What Should The Cost Of Your Christmas Party Be?",
    desc: "Here Ye, Here Ye, Halloween is Nigh and if you’re still freaking out about where to go and what to do then hath no fear the Team...",
    link: "Read More",
    linktag: "/blog/find-perfect-supplier",
  },
  {
    img: bcard16,
    title: "Building Vendoir",
    date: "16 May,2022",
    heading: "New Vendoir App. The Easiest Way To Sell Online!",
    desc: "Event marketing is an essential part of any company’s marketing mix. However, now that pandemic limitations have been lifted, event planners need to justify their costs before",
    link: "Read More",
    linktag: "/blog/sell-online",
  },
  {
    img: bcard17,
    title: "Building Vendoir",
    date: "25 April,2022",
    heading: "Vendoir Live In Beta On IOS And Android",
    desc: "It’s official - Vendoir is now live in Beta on IOS and Android, this is a huge milestone for us after many months of building and testing in Alpha. The app is available to test for anyone in the UK...",
    link: "Read More",
    linktag: "/blog/beta",
  },
  {
    img: bcard18,
    title: "Building Vendoir",
    date: "23 February,2021",
    heading: "London & Partners - Business Growth Programme",
    desc: "This week we officially started our journey on the Business Growth Programme sponsored by the Mayor of London. This free 3-month program will offer tailored, impartial advice and strategic",
    link: "Read More",
    linktag: "/blog/london-partners",
  },
  {
    img: bcard19,
    title: "Building Vendoir",
    date: "23 February,2021",
    heading: "We’ve Got Some Exciting News",
    desc: "We are planning to go live on Crowdcube soon. After 6 months of amazing growth, app and community building, we have made significant progress and are now launching our seed round.",
    link: "Read More",
    linktag: "/blog/news",
  },
  {
    img: bcard20,
    title: "Building Vendoir",
    date: "23 February,2021",
    heading: "CEO’s Welcome Message",
    desc: "As you navigate our website, I hope you learn more about our company's vision and mission in the event industry and our plans to transform the industry using technology. Our goal is to provide",
    link: "Read More",
    linktag: "/blog/ceo",
  }
];

export default Bdata;
