import React from "react";

import Bnb from "./Bnb";
import BFooter from "./BFooter";

function Bdisclaimer() {
  return (
    <>
      <Bnb />
      <section className="privacy">
        <div className="container">
          <div className="privacy-heading">
            <h5>Last update november 01, 2020</h5>
            <h1>Disclaimer</h1>
          </div>

          <div className="privacy-content">
            <h2 className="privacy-sub-title">WEBSITE DISCLAIMER</h2>
            <p className="privacy-desc">
              The information provided by Vendoir Limited ("we," "us" or "our")
              on https://www.vendoir.com (the "Site") and our mobile application
              is for general informational purposes only. All information on the
              Site and our mobile application is provided in good faith, however
              we make no representation or warranty of any kind, express or
              implied, regarding the accuracy, adequacy, validity, reliability,
              availability or completeness of any information on the Site or our
              mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
              LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR
              RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE
              APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND
              YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE
              APPLICATION IS SOLELY AT YOUR OWN RISK
            </p>
          </div>

          <div className="privacy-content">
            <h2 className="privacy-sub-title">EXTERNAL LINKS DISCLAIMER</h2>
            <p className="privacy-desc">
              The Site and our mobile application may contain (or you may be
              sent through the Site or our mobile application) links to other
              websites or content belonging to or originating from third parties
              or links to websites and features in banners or other advertising.
              Such external links are not investigated, monitored, or checked
              for accuracy, adequacy, validity, reliability, availability or
              completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
              ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
              INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE
              SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER
              ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
            </p>
          </div>

          <div className="privacy-content">
            <h2 className="privacy-sub-title">PROFESSIONAL DISCLAIMER</h2>
            <p className="privacy-desc">
              The Site cannot and does not contain information technology &
              services advice. The information technology & services information
              is provided for general informational and educational purposes
              only and is not a substitute for professional advice. Accordingly,
              before taking any actions based upon such information, we
              encourage you to consult with the appropriate professionals. We do
              not provide any kind of information technology & services advice.
              THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE OR
              OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.{" "}
            </p>
          </div>

          <div className="privacy-content">
            <h2 className="privacy-sub-title">AFFILIATES DISCLAIMER</h2>
            <p className="privacy-desc">
              The Site and our mobile application may contain links to affiliate
              websites, and we receive an affiliate commission for any purchases
              made by you on the affiliate website using such links. Our
              affiliates include the following:
            </p>
            <div className="dsiclamer-list">
              <ul className="privacy-desc">
                <li>Facebook</li>
                <li> Twitter</li>
                <li>Instagram</li>
                <li>LinkedIn</li>
                <li>MailChimp</li>
              </ul>
            </div>

            <p className="privacy-desc">
              This disclaimer was created using
              <a href="https://termly.io/products/disclaimer-generator/?ftseo">
                Termly’s Disclaimer Generator.
              </a>
            </p>
          </div>
        </div>
      </section>

      <BFooter />
    </>
  );
}

export default Bdisclaimer;
