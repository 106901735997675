import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import stepperlogo from "../src/image/Vendoir logo/Vendoir B2B Logo b.svg";
import stepperimg2 from "../src/image/stepper-img2.webp";
import stepperimg3 from "../src/image/stepper-img3.webp";
import stepperimg4 from "../src/image/Awards_ceremonies_and_gala.webp";
import stepperimg5 from "../src/image/sporting-event.webp";
import stepperimg6 from "../src/image/cstepperimg19.webp";
import stepperimg7 from "../src/image/stepper-img7.webp";
// import stepperimg8 from "../src/image/stepper-img8.png";
// import stepperimg9 from "../src/image/stepper-img9.png";
import stepperimg10 from "../src/image/stepper-img10.webp";
import stepperimg11 from "../src/image/stepper-img11.webp";
import stepperimg12 from "../src/image/stepper-img12.webp";
import stepperimg13 from "../src/image/stepper-img13.webp";
import stepperimg14 from "../src/image/stepper-img14.webp";
import stepperimg15 from "../src/image/stepper-img15.webp";
import supplyimg1 from "../src/image/csupply1.webp";
import supplyimg2 from "../src/image/csupply2.webp";
import supplyimg3 from "../src/image/csupply3.webp";
import supplyimg4 from "../src/image/csupply4.webp";
import supplyimg5 from "../src/image/csupply5.webp";
import supplyimg6 from "../src/image/csupply6.webp";
import supplyimg7 from "../src/image/csupply7.webp";
import supplyimg8 from "../src/image/csupply8.webp";
import supplyimg9 from "../src/image/csupply9.webp";
import supplyimg10 from "../src/image/csupply10.webp";
import ReactGoogleAutocomplete from "react-google-autocomplete";

function Bstepper() {
  const [currentStep, setCurrentStep] = useState(1);
  const [guestCount, setGuestCount] = useState("");
  const [guestCountError, setGuestCountError] = useState("");
  const [budgetCount, setBudgetCount] = useState("");
  const [budgetError, setBudgetError] = useState("");
  const [stepError, setStepError] = useState("");
  let [location, setLocation] = useState(null);
  const [locationError, setLocationError] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  // const [eventType, setEventType] = useState("");

  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectEventOption, setSelectEventOption] = useState("");
  const [eventError, setEventError] = useState("");
  const [otherEventPlan, setOtherEventPlanning] = useState("");
  const [supplierEventPlan, setSupplierEventPlan] = useState("");
  const [agreedToTermsError, setAgreedToTermsError] = useState("");
  // const [services, setServices] = useState(
  //   "sustainable event planning/management"
  // );
  const [sustainableError, setSustainableError] = useState("");
  const handleNext = () => {
    if (currentStep === 1 && eventOption.trim() === "") {
      setStepError("Please select an event option");
      return;
    } else if (currentStep === 1 && eventOption === "Other") {
      if (otherEventPlan.trim() === "") {
        setStepError("Please enter the event");
        return;
      }
    } else if (
      currentStep === 2 &&
      Object.values(selectedSuppliers).every((value) => !value)
    ) {
      setStepError("Please select at least one supplier");
      return;
    } else if (
      currentStep === 2 &&
      selectedSuppliers.Other &&
      supplierEventPlan.trim() === ""
    ) {
      setStepError("Please enter details for the 'Other' supplier");
      return;
    } else if (currentStep === 3 && location === null) {
      setLocationError(true);
      return;
    } else if (currentStep === 4 && guestCount.trim() < 1) {
      setGuestCountError("Please enter the number of guests");
      return;
    } else if (currentStep === 5 && budgetCount.trim() < 1) {
      setBudgetError("Please enter the budget");
      return;
    } else if (currentStep === 5 && isSustainable) {
      if (services === null) {
        setSustainableError("Select a Service");
        return;
      }
    }

    // If no errors, proceed to the next step
    setCurrentStep(currentStep + 1);
    setStepError("");
    setLocationError(false);
    setBudgetError("");
    setGuestCountError("");
    setSustainableError("");
  };

  const handlePrev = () => {
    switch (currentStep) {
      case 1:
        setStepError("");
        break;
      case 2:
        setStepError("");
        break;
      case 3:
        setGuestCountError("");
        break;
      case 4:
        setBudgetError("");
        break;
      case 5:
        setSustainableError("");
        break;
      default:
        break;
    }

    // Navigate to the previous step
    setCurrentStep(currentStep - 1);
  };

  const validateForm = () => {
    let isValid = true;

    // Validate Name
    if (name.trim() === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate Company Name
    if (companyName.trim() === "") {
      setCompanyNameError("Company name is required");
      isValid = false;
    } else {
      setCompanyNameError("");
    }

    // Validate Email
    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (phone.trim() === "") {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      // Remove any non-digit characters from the phone number
      const cleanedPhone = phone.replace(/\D/g, "");

      // Validate the cleaned phone number
      if (!/^\+?\d+$/.test(cleanedPhone)) {
        setPhoneError("Please enter a valid phone number with numbers only");
        isValid = false;
      } else {
        setPhoneError("");
      }
    }

    // valid agreed to terms

    if (!agreedToTerms) {
      setAgreedToTermsError("Please agree to the terms");
      isValid = false;
    } else {
      setAgreedToTermsError("");
    }

    // select event option
    if (selectEventOption.trim() === "") {
      setEventError("Please select an event option");
      isValid = false;
    } else {
      setEventError("");
    }

    return isValid;
  };
  const navigate = useNavigate();
  const handleRoute = () => {
    const previousRoute = localStorage.getItem("previousRoute");

    // Navigate to the previous route
    navigate(previousRoute);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      navigate("/businessthankupage");

      const payload = {
        event_type: eventOption,
        other_event: otherEventPlan ? otherEventPlan : null,
        selected_suppliers: Object.entries(selectedSuppliers)
          .filter(([key, value]) => value)
          .map(([key]) => key)
          .join(", "),
        other_supplier: supplierEventPlan ? supplierEventPlan : null,
        location: location,
        guests: guestCount,
        budget: budgetCount,
        is_sustainable: isSustainable,
        services: services,
        name: name,
        email: email,
        phone: phone,
        company_name: companyName,
        event: selectEventOption,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/businessCampaign`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));

      console.log("Form submitted successfully");
    } else {
      console.log("Form validation failed");
    }
  };

  useEffect(() => {
    // Log component mount htmlFor debugging
    console.log("Bstepper component has mounted");
  }, []);
  const renderButtons = () => {
    if (currentStep === 6) {
      return (
        <div className="fixed-bottom w-100 bg-white shadow border-top py-3">
          <Link to="/businessthankupage">
            {" "}
            <Button className="submit-btn" onClick={handleSubmit}>
              Submit
            </Button>
          </Link>
          <Button
            className="prev-btn"
            onClick={handlePrev}
            disabled={currentStep === 1}>
            Back
          </Button>
        </div>
      );
    } else {
      return (
        <div className="fixed-bottom stepper-btn w-100 bg-white shadow border-top py-3">
          <Button
            className="prev-btn"
            onClick={handlePrev}
            disabled={currentStep === 1}>
            Back
          </Button>
          <Button
            className="next-btn"
            onClick={handleNext}
            disabled={currentStep === 6}>
            Next
          </Button>
        </div>
      );
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showOtherTextField, setShowOtherTextField] = useState(false);
  const [showOtherSupplierField, setShowOtherSupplierField] = useState(false);

  const [isSustainable, setIsSustainable] = useState(false);

  // const handleOptionChange = (e) => {
  //   setIsSustainable(e.target.value);
  // };

  const [eventOption, setEventOption] = useState("");
  // const [ecoFriendly, setEcoFriendly] = useState(false);

  const handleFirstStep = (event) => {
    setEventOption(event.target.value);
    setShowOtherTextField((event.target.checked = false));
    setStepError("");
  };
  // const handleEcoFriendlyCheckboxChange = (event) => {
  //   setEcoFriendly(event.target.checked);
  // };

  const handleOtherCheckboxChange = (event) => {
    setEventOption("Other");
    setShowOtherTextField(event.target.checked);
  };

  const handleOtherEventPlanning = (event) => {
    setOtherEventPlanning(event.target.value);
  };

  const handleOtherSupplier = (event) => {
    setSupplierEventPlan(event.target.value);
  };

  // Step 1: Create state to store selected checkboxes for each supplier type
  const [selectedSuppliers, setSelectedSuppliers] = useState({
    Caterer: false,
    DJ: false,
    Florist: false,
    "Live Band": false,
    "Make Up Artist": false,
    "Mc/Host": false,
    Photobooth: false,
    Photography: false,
    Venue: false,
    Videography: false,
    Other: false,
  });

  // Step 2: Update state when a checkbox is checked or unchecked
  const handleSecondStep = (event) => {
    const { value, checked } = event.target;

    // If "Other" checkbox is checked, show the other text field
    if (value === "Other") {
      setShowOtherSupplierField(checked);
      setShowOtherSupplierField(event.target.checked);
    }
    if (showOtherTextField || showOtherSupplierField) {
      setStepError("");
    }

    // Update selectedSuppliers based on checkbox status
    setSelectedSuppliers((prevSelected) => ({
      ...prevSelected,
      [value]: checked,
    }));
  };

  // const handleLocationChange = (value) => {
  //   setLocation(value);
  //   location = value;
  //   console.log(location);
  //   if (!value) {
  //     setLocationError(true);
  //   } else {
  //     setLocationError(false);
  //   }
  // };
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleAddressChange = (option) => {
    setLocation(option);
    location = option;
    if (!option) {
      setLocationError(true);
    } else {
      setLocationError(false);
    }
  };
  const [services, setServices] = useState(null);
  const options = [
    {
      value: "Sustainable event planning/management",
      label: "Sustainable event planning/management",
    },
    {
      value: "Carbon measurement and offset only",
      label: "Carbon measurement and offset only",
    },
    { value: "Training & Support", label: "Training & Support" },
    { value: "Sustainable Consultancy", label: "Sustainable Consultancy" },
    { value: "Impact Report", label: "Impact Report" },
  ];

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}>
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  return (
    <>
      <div className="Bstepper-sec">
        <div className="container">
          <div className="Bstepper-sec-uper">
            <img src={stepperlogo} className="stepper-logo" alt="stepperlogo" />
            <div className="close" onClick={handleShow}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.54688 22.4483L22.4434 7.55176M7.54688 7.55176L22.4434 22.4483"
                  stroke="#5165AC"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="Bstepper-sec-bottom">
                <ProgressBar
                  now={(currentStep / 6) * 100}
                  label={`${currentStep}/6`}
                />

                <Form onSubmit={handleFormSubmit}>
                  <div className="form-inner">
                    {currentStep === 1 && (
                      <Form.Group controlId="step2">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What type of event are you planning?
                          </h3>
                        </div>

                        <div className="step2-inner text-center">
                          <div className="Btepper-sec-card step-2-list">
                            <ul>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle5"
                                  name="eventOption"
                                  value={"Awards"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Awards"}
                                  required
                                />
                                <label htmlFor="vehicle5">
                                  <img src={stepperimg4} alt="awards" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Awards ceremonies <br></br>& gala
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle10"
                                  name="eventOption"
                                  value={"Charity"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Charity"}
                                  required
                                />
                                <label htmlFor="vehicle10">
                                  <img src={stepperimg11} alt="charity" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Charity event
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle1"
                                  name="eventOption"
                                  value={"Conference"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Conference"}
                                  required
                                />
                                <label htmlFor="vehicle1">
                                  <img src={stepperimg2} alt="conference" />
                                </label>
                                <h4 className="step2-list-desc-">Conference</h4>
                              </li>

                              <li>
                                <input
                                  type="radio"
                                  id="vehicle444"
                                  name="eventOption"
                                  value={"Festival & Fair"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Festival & Fair"}
                                  required
                                />
                                <label htmlFor="vehicle444">
                                  <img src={stepperimg6} alt="festival" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Festival & Fair
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle11"
                                  name="eventOption"
                                  value={"Fundraising"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Fundraising"}
                                  required
                                />
                                <label htmlFor="vehicle11">
                                  <img src={stepperimg12} alt="fundraising" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Fundraising event
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle12"
                                  name="eventOption"
                                  value={"Meeting"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Meeting"}
                                />
                                <label htmlFor="vehicle12">
                                  <img src={stepperimg13} alt="meeting" />
                                </label>
                                <h4 className="step2-list-desc-">Meeting</h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle6"
                                  name="eventOption"
                                  value={"Private"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Private"}
                                  required
                                />
                                <label htmlFor="vehicle6">
                                  <img src={stepperimg7} alt="private" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Private event
                                </h4>
                              </li>

                              <li>
                                <input
                                  type="radio"
                                  id="vehicle9"
                                  name="eventOption"
                                  value={"ProductLaunch"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "ProductLaunch"}
                                  required
                                />
                                <label htmlFor="vehicle9">
                                  <img src={stepperimg10} alt="product" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Product launch
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle4"
                                  name="eventOption"
                                  value={"Sporting"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Sporting"}
                                  required
                                />
                                <label htmlFor="vehicle4">
                                  <img src={stepperimg5} alt="sporting" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Sporting event
                                </h4>
                              </li>

                              <li>
                                <input
                                  type="radio"
                                  id="vehicle2"
                                  name="eventOption"
                                  value={"Trade shows & expo"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Trade shows & expo"}
                                  required
                                />
                                <label htmlFor="vehicle2">
                                  <img src={stepperimg3} alt="trade" />
                                </label>
                                <h4 className="step2-list-desc-">
                                  Trade shows &<br></br> expo
                                </h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="vehicle13"
                                  name="eventOption"
                                  value={"Workshop"}
                                  onChange={handleFirstStep}
                                  checked={eventOption === "Workshop"}
                                  required
                                />
                                <label htmlFor="vehicle13">
                                  <img src={stepperimg14} alt="workshop" />
                                </label>
                                <h4 className="step2-list-desc-">Workshop</h4>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  onChange={handleOtherCheckboxChange}
                                  id="vehicle14"
                                  name="eventOption"
                                  value={"Other"}
                                  checked={eventOption === "Other"}
                                  required
                                />
                                <label htmlFor="vehicle14">
                                  <img src={stepperimg15} alt="other" />
                                </label>
                                <h4 className="step2-list-desc-">Other</h4>
                              </li>
                            </ul>
                            {showOtherTextField && (
                              <div className="other-text-field">
                                <input
                                  type="text"
                                  id="otherTextField"
                                  className="other-field"
                                  placeholder="what other event(s) do you need?"
                                  onChange={handleOtherEventPlanning}
                                  value={otherEventPlan}
                                />
                              </div>
                            )}
                          </div>
                          {stepError && (
                            <p className="error-message ">{stepError}</p>
                          )}
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 2 && (
                      <Form.Group controlId="step3">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What type of suppliers do you need?
                          </h3>
                          <p className="step-title text-center mb-5">
                            We work with the top suppliers in the country. Tell
                            us what you're looking htmlFor,<br></br> and we'll
                            find it htmlFor you.
                          </p>
                        </div>

                        <div className="step3-inner text-center">
                          <div className=" Btepper-sec-card step-2-list">
                            <ul>
                              {Object.keys(selectedSuppliers).map(
                                (supplier, index) => (
                                  <li key={index}>
                                    <input
                                      type="checkbox"
                                      id={`supply${index + 1}`}
                                      name="secondEventOption"
                                      value={supplier}
                                      onChange={handleSecondStep}
                                      checked={selectedSuppliers[supplier]}
                                    />
                                    <label htmlFor={`supply${index + 1}`}>
                                      {supplier === "DJ" && (
                                        <img src={supplyimg1} alt={`DJ`} />
                                      )}
                                      {supplier === "Caterer" && (
                                        <img src={supplyimg2} alt={`caterer`} />
                                      )}
                                      {supplier === "Florist" && (
                                        <img src={supplyimg3} alt={`florist`} />
                                      )}
                                      {supplier === "Live Band" && (
                                        <img
                                          src={supplyimg4}
                                          alt={`live band`}
                                        />
                                      )}
                                      {supplier === "Make Up Artist" && (
                                        <img
                                          src={supplyimg5}
                                          alt={`make up artist`}
                                        />
                                      )}
                                      {supplier === "Mc/Host" && (
                                        <img src={supplyimg6} alt={`mc/host`} />
                                      )}
                                      {supplier === "Photobooth" && (
                                        <img
                                          src={supplyimg7}
                                          alt={`photobooth`}
                                        />
                                      )}
                                      {supplier === "Photography" && (
                                        <img
                                          src={supplyimg8}
                                          alt={`photography`}
                                        />
                                      )}
                                      {supplier === "Venue" && (
                                        <img src={supplyimg9} alt={`venue`} />
                                      )}
                                      {supplier === "Videography" && (
                                        <img
                                          src={supplyimg10}
                                          alt={`videography`}
                                        />
                                      )}
                                      {supplier === "Other" && (
                                        <img src={stepperimg15} alt={`other`} />
                                      )}
                                    </label>
                                    <h4 className="step2-list-desc-">
                                      {supplier}
                                    </h4>
                                  </li>
                                )
                              )}
                            </ul>
                            {showOtherSupplierField && (
                              <div className="other-text-field">
                                <input
                                  type="text"
                                  id="otherTextField"
                                  className="other-field"
                                  placeholder="what other supplier(s) do you need?"
                                  onChange={handleOtherSupplier}
                                  value={supplierEventPlan}
                                />
                              </div>
                            )}
                          </div>

                          {stepError && (
                            <p className="error-message ">{stepError}</p>
                          )}
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 3 && (
                      <Form.Group controlId="step4 step3">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            What location is your event taking place?
                          </h3>
                          <p className="step-title text-center ">
                            Let us know the area where your event will take
                            place to enable us to source local suppliers.
                          </p>
                        </div>

                        <div
                          className="step-bottom text-center"
                          id="google-location">
                          <ReactGoogleAutocomplete
                            className="form-control"
                            apiKey="AIzaSyCCGghgOEG3d9HAF1UXIj5P9PBeQ_gvxls"
                            onPlaceSelected={(place) => {
                              handleAddressChange(place?.formatted_address);
                            }}
                            options={{
                              types: "(cities)",
                            }}
                            defaultValue={location}
                            placeholder="Enter postcode or address"
                          />
                        </div>

                        {locationError && (
                          <p className="error-message error-location">
                            Please enter a location
                          </p>
                        )}
                      </Form.Group>
                    )}
                    {currentStep === 4 && (
                      <Form.Group controlId="step4 step3">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            How many guests/attendees are you <br></br>expecting
                            at your event?
                          </h3>
                          <p className="step-title text-center ">
                            If you don’t know exactly, just give us your best
                            estimate.
                          </p>
                        </div>

                        <div className="step-bottom5 text-center">
                          <label>Number of Guests:</label>
                          <input
                            type="number"
                            id="fname"
                            name="location"
                            placeholder="0"
                            value={guestCount}
                            onChange={(e) => setGuestCount(e.target.value)}
                          />
                          {guestCountError && (
                            <p className="error-message ">{guestCountError}</p>
                          )}
                          <div className="step-svg"></div>
                        </div>
                      </Form.Group>
                    )}
                    {currentStep === 5 && (
                      <Form.Group controlId="step3 step6">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            We’ll do our best to plan your event <br></br>within
                            your budget.
                          </h3>
                          <p className="step-title text-center ">
                            The number of hours you give us will determine the
                            price for your event.
                          </p>
                        </div>

                        <div className="step-bottom5 text-center">
                          <label>
                            <svg
                              width="13"
                              height="19"
                              viewBox="0 0 13 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.8764 15.816V18.048H1.76438L1.28438 16.56C1.95638 15.84 2.45238 15.128 2.77238 14.424C3.09238 13.704 3.25238 12.816 3.25238 11.76C3.25238 11.44 3.21238 11.104 3.13238 10.752H0.492375V8.856H2.53238C2.19638 7.848 2.02838 6.872 2.02838 5.928C2.02838 4.856 2.26038 3.928 2.72438 3.144C3.18838 2.36 3.82838 1.76 4.64438 1.344C5.46038 0.912 6.40438 0.696 7.47638 0.696C9.18838 0.696 10.4844 1.176 11.3644 2.136C12.2444 3.096 12.7004 4.32 12.7324 5.808H10.2364C10.2364 4.944 10.0124 4.264 9.56438 3.768C9.11638 3.256 8.42038 3 7.47638 3C6.61238 3 5.91638 3.248 5.38838 3.744C4.87638 4.24 4.62038 5.016 4.62038 6.072C4.62038 6.536 4.66838 6.984 4.76438 7.416C4.86038 7.848 4.99638 8.328 5.17238 8.856H9.82838V10.752H5.72438C5.80438 11.12 5.84438 11.464 5.84438 11.784C5.84438 13.336 5.30838 14.68 4.23638 15.816H12.8764Z"
                                fill="#242424"
                              />
                            </svg>
                          </label>
                          <input
                            type="number"
                            id="fname"
                            name="location"
                            placeholder="0"
                            value={budgetCount}
                            onChange={(e) => setBudgetCount(e.target.value)}
                          />
                        </div>

                        <div className=" select-step text-center">
                          {budgetError && (
                            <p className="error-message ">{budgetError}</p>
                          )}
                          <label
                            htmlFor="exampleInputEmail1"
                            className="yes-no-content text-center">
                            Would you like your event to be sustainable?
                          </label>
                          <div className="yes-no ">
                            <div className="yes">
                              <input
                                type="radio"
                                onChange={() => setIsSustainable(true)}
                                checked={isSustainable}
                                id="age1"
                                name="age"
                                value="30"
                                className="eco-friendly-inner-input"
                              />
                              <label
                                htmlFor="age1"
                                className="eco-friendly-inner-lable">
                                Yes
                              </label>
                            </div>

                            <div className="no">
                              <input
                                type="radio"
                                onChange={() => setIsSustainable(false)}
                                checked={!isSustainable}
                                id="age2"
                                name="age"
                                value="60"
                                className="eco-friendly-inner-input"
                              />
                              <label
                                htmlFor="age2"
                                className="eco-friendly-inner-lable">
                                No
                              </label>
                            </div>
                          </div>

                          <p className="step-title mt-2">
                            {" "}
                            If you select yes, you need to complete the form
                            below and if you don’t,<br></br> you move to the
                            next question
                          </p>
                        </div>

                        {isSustainable && (
                          <div className=" step-form bsupply-sec-inner mt-5">
                            <p className="sustainable-step-heading">
                              What sustainable services would you like:
                            </p>

                            <div className="row mb-2 multi-select">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="Select Services"
                                    className="form-label">
                                    Service needed:{" "}
                                  </label>
                                  <br></br>
                                  <Select
                                    isMulti
                                    defaultValue={services}
                                    onChange={setServices}
                                    options={options}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                      Option: InputOption,
                                    }}
                                  />
                                  {/* <select
                                    className="form-select"
                                    value={services}
                                    onChange={(e) =>
                                      setServices(e.target.value)
                                    }>
                                    <option value="sustainable event planning/management">
                                      Sustainable Event Planning/Management
                                    </option>
                                    <option value="carbon measurement and offset only">
                                      Carbon measurement and offset only.
                                    </option>
                                    <option value="Training & Support">
                                      Training & Support
                                    </option>
                                    <option value="Sustainable Consultancy">
                                      Sustainable Consultancy
                                    </option>
                                    <option value="Impact Report">
                                      Impact Report
                                    </option>
                                    <option value="All of the above">
                                      All of the above
                                    </option>
                                  </select> */}
                                </div>
                                {sustainableError && (
                                  <p className="d-none error-message">
                                    {sustainableError}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    )}
                    {currentStep === 6 && (
                      <Form.Group controlId="step3 step6">
                        <div className="step-upper">
                          <h3 className="step1-heading text-center">
                            You're almost there!
                          </h3>
                          <p className="step-title text-center ">
                            Once you fill out your details below, someone from
                            our team will be in touch shortly.
                          </p>
                        </div>

                        <div className="bsupply-sec-inner">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                  Name:<span className="color-red">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                              {nameError && (
                                <p className="error-message">{nameError}</p>
                              )}
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="companyName"
                                  className="form-label">
                                  Company name:{" "}
                                  <span className="color-red">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="companyName"
                                  value={companyName}
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                />
                              </div>
                              {companyNameError && (
                                <p className="error-message">
                                  {companyNameError}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                  Email address:
                                  <span className="color-red">*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  aria-describedby="emailHelp"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                              {emailError && (
                                <p className="error-message">{emailError}</p>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="phoneNumber"
                                  className="form-label">
                                  Phone number:
                                  <span className="color-red">*</span>
                                </label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="phoneNumber"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  required
                                />
                              </div>
                              {phoneError && (
                                <p className="error-message">{phoneError}</p>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="cars" className="form-label">
                                  What type of event?
                                  <span className="color-red">*</span>
                                </label>
                                <br></br>

                                <select
                                  className="form-select"
                                  value={selectEventOption}
                                  onChange={(e) =>
                                    setSelectEventOption(e.target.value)
                                  }>
                                  <option value="Select an option">
                                    Select an option
                                  </option>
                                  <option value="In-person">In-person</option>
                                  <option value="Hybrid">Hybrid</option>
                                  <option value="Virtual">Virtual</option>
                                  <option value="Sustainable events">
                                    Sustainable events
                                  </option>
                                </select>
                                {eventError && (
                                  <p className="error-message">{eventError}</p>
                                )}
                              </div>
                            </div>
                            <div className="checkbox-msg">
                              <input
                                type="checkbox"
                                checked={agreedToTerms}
                                onChange={() =>
                                  setAgreedToTerms(!agreedToTerms)
                                }
                              />
                              <label className="checkbox-desc">
                                I agree with the <Link to="/terms">T&Cs </Link>
                                and <Link to="/privacy">Privacy Policy</Link>
                              </label>

                              <br />
                            </div>
                            {agreedToTermsError && (
                              <p className="error-message">
                                {agreedToTermsError}
                              </p>
                            )}
                          </div>
                        </div>
                      </Form.Group>
                    )}
                  </div>
                  <div className=" fixed-bottom stepper-btn  w-100 bg-white shadow border-top py-3">
                    <Button
                      className="prev-btn"
                      onClick={handlePrev}
                      disabled={currentStep === 1}>
                      Back
                    </Button>
                    <Button
                      className="next-btn"
                      onClick={handleNext}
                      disabled={currentStep === 7}>
                      Next
                    </Button>
                  </div>
                </Form>

                {renderButtons()}
              </div>
            </div>
          </div>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Want to leave the form?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="nav flex-column flex-nowrap">
                <li>
                  <div className="form-check d-flex align-items-baseline gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason-0"
                    />
                    <label className="form-check-label mb-0" htmlFor="reason-0">
                      I don’t have time to fill it out right now
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check d-flex align-items-baseline gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason-1"
                      defaultChecked
                    />
                    <label className="form-check-label mb-0" htmlFor="reason-1">
                      I’m not ready to enquire yet
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check d-flex align-items-baseline gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason-2"
                    />
                    <label className="form-check-label mb-0" htmlFor="reason-2">
                      I’m in the wrong place
                    </label>
                  </div>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No, continue with the form
              </Button>
              <Button variant="primary" onClick={handleRoute}>
                Yes, leave the form
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Bstepper;
