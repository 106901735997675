import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function Top5() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-four">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                Top 5 spook-tacular halloween<br></br> activities
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">27 October,2023</p>
              </div>

              <p className="detail-inner-desc">
                Here Ye, Here Ye, Halloween is Nigh and if you’re still freaking
                out about where to go and what to do then hath no fear the Team
                at Vendoir have rounded up top 5 events/ideas you could do to
                get you through the all hallows eve.
              </p>

              <ul className="blog-inner-lists">
                <li className="blog-inner-lists-title mb-3">
                  The tried and tested cozy horror night - for those who are
                  tired of the hustle and bustle of the outside world why not
                  gather friends around the TV to watch the most iconic horror
                  movies with spooky themed snacks and of course a bloodyyy
                  Maryyyy or not it can just be a cup of tea.
                </li>
                <li className="blog-inner-lists-title mb-3">
                  If you’re in the mood for a boogie and to be transported back
                  to the 70’s then Rouges 70’s & 80’S Disco Funk night is the
                  one for you! Dance the night of the 28th of October away with
                  the ghouls and the ghosts to the tune of Take On Me because
                  why not. Link to tickets is below! Rouges 70’s & 80’s Disco
                  and Funk
                </li>
                <li className="blog-inner-lists-title mb-3">
                  {" "}
                  Ahoy there matey, if land is not to your liking why not take
                  on the sea, well river. Presenting to you a Zombie Pirate Ship
                  setting sail on the 27th of October! Dance and dodge the
                  zombies as you peruse the haunted galley with a free after
                  party at Egg. For more details follow the link Zombie Pirate
                  Ship 2023 Halloween London Boat party / selling out
                </li>
                <li className="blog-inner-lists-title mb-3">
                  Looking for something more wholesome to do with the kids? We
                  got you covered here as well, spend an adorably terrifying
                  afternoon on the 28th of October, filled with costume
                  contests, yummy treats and of course monstrously mashed up
                  beats. Follow the link for your tickets: Halloween with the
                  Kids
                </li>
                <li className="blog-inner-lists-title mb-3">
                  {" "}
                  Nothing tickling your fancy yet? Not to worry, why not make it
                  your own! Vendoir have partnered with Tutti to streamline your
                  events even faster. At Tutti you can get a tried and tested
                  venue and with Vendoir we can help you get a DJ, Makeup
                  artist, Photographer and many more faster than you can say
                  Boo! Download our app now and have the spookiest of Halloween.
                </li>
              </ul>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Top5;
