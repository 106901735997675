import React from "react";
import { ProgressBar } from "react-bootstrap";
import stepperimg from "../src/image/start-img.webp";
import { Link, useNavigate } from "react-router-dom";
import stepperlogo from "../src/image/Vendoir logo/Vendoir B2B Logo b.svg";

function EventPlanningBusiness() {
  const navigate = useNavigate();
  const handleRoute = () => {
    // const previousRoute = localStorage.getItem("previousRoute");
    // console.log(previousRoute,"previousRoute")

    // Navigate to the previous route
    navigate("/business");
  };
  return (
    <>
      <div className="Eorganise-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="Bstepper-sec-uper">
                <img src={stepperlogo} className="stepper-logo" alt="logo" />
                <button
                  onClick={handleRoute}
                  className="close"
                  id="close-route">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.54688 22.4483L22.4434 7.55176M7.54688 7.55176L22.4434 22.4483"
                      stroke="#5165AC"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <ProgressBar />
              <div className="step1 text-center">
                <img src={stepperimg} className="step-1-img" alt="person" />
                <h3 className="step1-heading">
                  Let's take the stress out of planning your event.
                </h3>
                <p className="step1-desc">
                  Hey, I’m Lisa and I’m here to help you plan your bespoke event
                  that is stress-free, sustainable, and inclusive.
                </p>
                <p className="step1-desc">
                  You’re just a few steps away from having a memorable event
                  that meets all your needs and within your budget.
                </p>
              </div>

              <div className=" fixed-bottom organise-btn w-100 bg-white text-end py-3">
                <Link to="/business-event-form" className="sign-btn">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventPlanningBusiness;
