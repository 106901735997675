import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogTwenty() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-twenty">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                CEO’s welcome message
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">23 February,2021</p>
              </div>

              <div className="blog-inner-lists">
                <p className="blog-inner-lists-title">
                  This week we officially started our journey on the Business
                  Growth Programme sponsored by the Mayor of London.
                  <br />
                  <br />
                  This free 3-month program will offer tailored, impartial
                  advice and strategic solutions to Vendoir and help unlock our
                  true potential. We will be provided with mentoring, workshops,
                  and business growth advice, as well as access to a range of
                  opportunities across the London network.
                  <br />
                  <br />
                  To date, the program has helped more than 1000 high-potential
                  London companies reach their growth and scale ambitions, with
                  over 710 founders completing the program and more than 320
                  female founders and 240 under-represented founders supported.
                  <br />
                  <br />
                  A sample of their most innovative cohort members has created
                  more than 250 jobs and raised more than £141 million
                  investment.
                  <br />
                  <br />
                  Yoma James Kukor,
                  <br />
                  <br />
                  Chief Operations Officer
                  <br />
                  <br />
                  23/02/2021
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogTwenty;
