import React, { useState } from "react";
import Bnb from "./Bnb";
import Referfriendimg from "./image/referfriend-img.webp";
// import Referbusinessimg from "./image/referbusiness-img.png";
import Referbusinessimg2 from "./image/referbusnessimg.webp";
import BFooter from "./BFooter";
import { Link } from "react-router-dom";

function Referbusiness() {
  // const [isfieldempty, Setisfieldempty] = useState(false);
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [rNameError, setRNameError] = useState("");
  const [rName, setRName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [rEmailError, setREmailError] = useState("");
  const [rEmail, setREmail] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleSubscribeClick = () => {
    if (!validateForm()) {
      setMessage("");
    } else if (!isCheckboxChecked) {
      setMessage("Please agree to term and condition");
    } else {
      const payload = {
        business_name: name,
        business_email: email,
        referral_name: rName,
        referral_email: rEmail,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/referral`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));

      setMessage("Thank you for submitting, we will be in touch shortly");
      setEmail("");
      setREmail("");
      setRName("");
      setName("");
      setIsCheckboxChecked(false);
    }
  };

  const validateForm = () => {
    let isValid = true;

    // Validate Business Name
    if (name.trim() === "") {
      setNameError("Business's Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate Referral Name
    if (rName.trim() === "") {
      setRNameError("Referral's Name is required");
      isValid = false;
    } else {
      setRNameError("");
    }

    // Validate Email
    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate Referral Email
    if (rEmail.trim() === "") {
      setREmailError("Referral's Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setREmailError("Invalid referral's email address");
      isValid = false;
    } else {
      setREmailError("");
    }

    // Validate Checkbox

    if (!isCheckboxChecked) {
      setCheckBoxError("Please agree to term and condition");
      isValid = false;
    } else {
      setCheckBoxError("");
    }

    return isValid;
  };

  // const fieldempty = () => {
  //   Setisfieldempty(!isfieldempty);
  // };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  return (
    <>
      <Bnb />

      <div className="Referbusiness-image-sec">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>

      <div className="Sustainability-new-sec margin-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-detail-sec-inner text-center">
                <h2 className="Blog-detai-heading">Refer a business</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* earn-extra-money */}

      <div className="earn-extra-money">
        <div className="container">
          <h2 className="text-center earn-extra-money-heading">
            Help your network plan Incredible events
          </h2>
          <p className="text-center rbusiness-title">
            At Vendoir, we believe that sharing is caring. That's why we've
            created our Refer a business program to help you <br></br>connect
            your network with the resources they need to plan unforgettable
            events.
          </p>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="earn-extra-money-left">
                <h3 className="earn-extra-money-title">How it works:</h3>
                <ul className="extra-money-list">
                  <li className="extra-money-list-name">
                    Fill out the form below with your friend's name and email
                    address.
                  </li>
                  <li className="extra-money-list-name">
                    We'll send your friend a personalized consultation
                    invitation
                  </li>
                  <li className="extra-money-list-name">
                    {" "}
                    If your friend plans an event with Vendoir within 30 days,
                    you'll receive a commission of up to 5% of the total budget
                    spent by your friend.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="earn-extra-money-right">
                <img
                  src={Referbusinessimg2}
                  className="img-fluid"
                  alt="how it works"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* join-vendior */}

      <div className="earn-extra-money ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-2 ">
              <div className="earn-extra-money-left">
                <h3 className="earn-extra-money-title">
                  Benefits for your friend:
                </h3>
                <ul className="extra-money-list">
                  <li className="extra-money-list-name">
                    Access to Vendoir's comprehensive marketplace of vetted
                    event vendors.
                  </li>
                  <li className="extra-money-list-name">
                    Simplified event planning and management
                  </li>
                  <li className="extra-money-list-name">
                    Commitment to sustainability and net-zero carbon emissions
                    by 2040
                  </li>
                  <li className="extra-money-list-name">
                    We're committed to sustainability and net-zero carbon
                    emissions by 2040.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 order-2 order-lg-1">
              <div className="earn-extra-money-right">
                <img
                  src={Referfriendimg}
                  className="img-fluid"
                  alt="benefits"
                />
              </div>
            </div>
          </div>

          <p className="earn-extra-money-title text-center">
            Together, we can make event planning stress-free, more sustainable,
            and more inclusive.
          </p>
        </div>
      </div>

      {/* friend-info */}

      <div className="friend-info">
        <div className="container">
          <div className="row align-items-start">
            <h3 className="friend-info-inner-heading text-center">
              Please provide your business's information below:
            </h3>
            <div className="col-lg-7">
              <div className="friend-info-inner">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor="businessName" className="form-label">
                        Business's Name:<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="businessName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        required
                      />
                      {nameError && (
                        <p className="error-message">{nameError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor="referralName" className="form-label">
                        Referral name:<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="referralName"
                        value={rName}
                        onChange={(e) => setRName(e.target.value)}
                        className="form-control"
                        required
                      />
                      {rNameError && (
                        <p className="error-message">{rNameError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor="companyEmail" className="form-label">
                        Business's Email Address:
                        <span className="color-red">*</span>
                      </label>
                      <input
                        type="email"
                        id="companyEmail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        required
                      />
                      {emailError && (
                        <p className="error-message">{emailError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor="referralEmail" className="form-label">
                        Referral's email address:
                        <span className="color-red">*</span>
                      </label>
                      <input
                        type="email"
                        id="referralEmail"
                        value={rEmail}
                        onChange={(e) => setREmail(e.target.value)}
                        className="form-control"
                        required
                      />
                      {rEmailError && (
                        <p className="error-message">{rEmailError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="checkbox-msg">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={isCheckboxChecked}
                  />
                  <label className="tc-desc">
                    {" "}
                    I agree to Vendoir’s{" "}
                    <Link to="/Terms">
                      <span className="termsandcondition">T&Cs</span>
                    </Link>{" "}
                    and{" "}
                    <Link to="/Privacy">
                      <span className="termsandcondition"> Privacy Policy</span>
                    </Link>{" "}
                    <span className="color-red">*</span>
                  </label>
                </div>
                {checkBoxError && (
                  <p className="error-message">{checkBoxError}</p>
                )}

                <div className="contact-btn">
                  <button
                    onClick={handleSubscribeClick}
                    type="submit"
                    className="contact-button">
                    Submit
                  </button>
                </div>

                <p className="Join Waitlist-message">{message}</p>
              </div>
            </div>

            <div className="col-lg-5">
              <h3 className="earn-extra-money-title">
                By submitting this form, you agree to the following terms and
                conditions:
              </h3>
              <ul className="extra-money-list">
                <li className="extra-money-list-name">
                  You are a current Vendoir user in good standing..
                </li>
                <li className="extra-money-list-name">
                  You have the consent of your friend to provide their personal
                  information.
                </li>
                <li className="extra-money-list-name">
                  You will not receive a referral bonus if your friend does not
                  sign up for Vendoir or book an event within 30 days.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default Referbusiness;
