import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogEighteen() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-eighteen">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                Corporate event trend predictions
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">23 February, 2021</p>
              </div>

              <p className="detail-inner-desc">
                We are planning to go live on Crowdcube soon. After 6 months of
                amazing growth, app and community building, we have made
                significant progress and are now launching our seed round. The
                funds will enable Vendoir to accelerate the development of the
                app and launch successfully.
                <br />
                <br />
                The interest in Vendoir to date has far exceeded our
                expectations and with your help with as little as £10, you can
                own a piece of the Uber of the event industry.
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">Key Event To Date:</h3>
                <ul>
                  <li className="blog-inner-lists-title">
                    300+ people on the app waitlist.
                  </li>
                  <li className="blog-inner-lists-title">
                    1,900+ social community.
                  </li>
                  <li className="blog-inner-lists-title">
                    Advanced SEIS assurance from the government for tax relief.
                  </li>
                  <li className="blog-inner-lists-title">
                    Advanced EIS assurance from the government for tax relief.
                  </li>
                  <li className="blog-inner-lists-title">
                    Accepted on to London & Partners Business Growth Program
                    supported by the Mayor of London
                  </li>
                  <li className="blog-inner-lists-title">Featured on Forbes</li>
                  <li className="blog-inner-lists-title">
                    Back-end App development in progress, thanks to our CTO
                  </li>
                </ul>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">About the Raise</h3>
                <p className="blog-inner-lists-title">
                  We are raising £75,000 at a £1,000,000 pre-money valuation.
                  More key details on the round
                </p>
                <ul>
                  <li className="blog-inner-lists-title">
                    The share price for the round is £1.00
                  </li>
                  <li className="blog-inner-lists-title">
                    The minimum investment amount is £10
                  </li>
                  <li className="blog-inner-lists-title">
                    Round is expected to start in a few days
                  </li>
                </ul>
              </div>

              <div className="blog-inner-lists d-flex gap-3 mb-3">
                <a className="sign-btn" href="/">
                  View Pitch Deck
                </a>
                <a className="sign-btn" href="/">
                  {" "}
                  Priority Access
                </a>
              </div>

              <div className="blog-inner-lists">
                <p className="blog-inner-lists-title">
                  Kindly share the opportunity with friends and family it will
                  mean a great deal, as always contact us directly at
                  info@vendoir.com, if you have any questions.
                  <br />
                  <br />
                  Investments of this nature carry risks to your capital. Please
                  Invest Aware.
                </p>
              </div>

              <p className="vendior-date">by Vendoir Team, 23/02/2021</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogEighteen;
