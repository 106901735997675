import React from "react";
import Bnb from "../Bnb";
import BFooter from "../BFooter";

function ResourceSixth() {
  return (
    <>
      <Bnb />
      <div className="blog-image-sec resource-image-sixth">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="resource-heading">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="blog-sec-heading resource-sec-title">
                How Vendoir can help businesses make their events sustainable
              </h2>
            </div>
          </div>
        </div>

        <div className="resource-content">
          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              Vendoir offers a range of assistance to businesses looking to make
              their events sustainable:
            </p>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                We provide a carefully selected directory of sustainable
                vendors, making it simple for businesses to find eco-friendly
                options.
              </li>
              <li className="resource-sub-desc">
                We also offer helpful resources like checklists, guides, and
                case studies to aid businesses in planning and implementing
                sustainable events.
              </li>
              <li className="resource-sub-desc">
                In addition, Vendoir enables businesses to track their
                sustainability efforts and provides reporting tools to measure
                the environmental impact of their events. In addition to these
                specific examples, Vendoir also provides businesses with a
                number of other support services, such as:
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <ol className="resorce-sub-list">
              <li className="resource-sub-desc">
                Offers a comprehensive range of services for event planning and
                management. Our team is equipped to assist businesses in every
                aspect of their event, from choosing the perfect venue and
                organizing catering, to creating a budget and implementing
                effective marketing strategies.
              </li>
              <li className="resource-sub-desc">
                Connect event organizers with sustainable caterers that use
                local and organic ingredients, minimize food waste and compost
                food scraps.
              </li>
              <li className="resource-sub-desc">
                We also specialize in vendor management, taking care of contract
                negotiation, making payments, and ensuring that vendors comply
                with sustainability guidelines.
              </li>
              <li className="resource-sub-desc">
                Additionally, we provide on-site support during events to
                guarantee smooth operations and sustainable practices
              </li>
            </ol>
          </div>

          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              In conclusion, implementing these tips and utilizing Vendoir's
              resources can enable businesses to enhance the sustainability of
              their events while minimizing their environmental footprint. This
              not only benefits the planet but also aids businesses in
              cost-saving and attracting new customers. Let us work together to
              forge a more sustainable future for events.{" "}
            </p>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default ResourceSixth;
