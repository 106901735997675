import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function Chrismas() {
  return (
    <>
      <Nb />
      <div className="blog-inner-image-sec blog-imag-second">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                The ugly christmas jumper<br></br> competition
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">21 December,2023</p>
              </div>

              <p className="detail-inner-desc">
                In the midst of Christmas glee, there has always been an
                underrated item of clothing that gets ridiculed without fail
                every year. That’s right its the UGLY CHRISTMAS Jumper. Ah, the
                time-honored tradition of donning the most garish, gaudy, and
                downright delightful Christmas jumpers! However we at Vendoir
                believe that it is the ultimate christmas item that can add much
                more to a christmas party than it takes away from fashion, and
                here is the reason why! Whether you’re celebrating with family,
                friends, or your work crew, there’s just one rule: Go big or go
                home!
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  1. The Classics Never Fade:
                </h3>
                <p className="blog-inner-lists-title">
                  From tinsel-laden trees to Rudolph with an excessively shiny
                  nose, embrace the classics! The Ugly Christmas Jumper theme
                  allows you to revisit those timeless festive motifs with a
                  cheeky twist. Dust off that jumper adorned with sequined
                  Santas and pom-pom snowflakes—after all, it’s a season to be
                  delightfully tacky!
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">2. DIY Delights:</h3>
                <p className="blog-inner-lists-title">
                  Get creative and unleash your inner DIY maven. Grab some
                  fabric paint, jingle bells, and perhaps a hot glue gun, and
                  transform an ordinary jumper into a masterpiece of kitsch.
                  Bonus points for handmade ornaments dangling from your
                  sleeves!
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  3.Friendly Competition:
                </h3>
                <p className="blog-inner-lists-title">
                  Turn your Ugly Christmas Jumper party into a friendly fashion
                  face-off. Organize a contest for the most outrageously festive
                  jumper, complete with categories like "Most Sparkly", "Most
                  Creative", and, of course, the coveted "Ugliest of Them All".
                  A trophy or bragging rights await the winner!
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  4. Jumper Swap Shenanigans:
                </h3>
                <p className="blog-inner-lists-title">
                  Add a twist to the celebration with a Ugly Christmas Jumper
                  Swap. Encourage guests to bring an extra festive jumper to
                  exchange, adding an element of surprise and ensuring everyone
                  gets to experience the joy of donning something delightfully
                  hideous.
                </p>
                <p className="blog-inner-lists-title">
                  The Ugly Christmas Jumper theme is not just an outfit choice;
                  it’s a statement. So, this holiday season, dare to be tacky,
                  embrace the kitsch, and remember—the uglier, the merrier!
                  Because in the world of festive fashion, there’s no such thing
                  as too much glitter or too many pom-poms! Most importantly the
                  Ugly Jumper represents what Vendoir stands for, it is
                  sustainable, inclusive and the easiest option 🎅🎁 Share your
                  ugly Christmas Jumper with us using the
                  #VendoirUglyChristmasJumper and you can feature it on our
                  Twitter page!
                </p>
              </div>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Chrismas;
