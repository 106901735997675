import React from "react";
import Bnb from "../Bnb";
import BFooter from "../BFooter";

function ResourceFifth() {
  return (
    <>
      <Bnb />
      <div className="blog-image-sec resource-image-fifth">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="resource-heading">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="blog-sec-heading resource-sec-title">
                Guiding Businesses to Sustainable Events
              </h2>
            </div>
          </div>
        </div>

        <div className="resource-content">
          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              The global awareness of environmental sustainability is growing,
              and businesses are facing greater scrutiny for their environmental
              impact. This is particularly relevant for businesses organizing
              events, as they often generate a substantial amount of waste and
              emissions.
              <br />
              <br />
              Fortunately, there are numerous strategies businesses can employ
              to enhance the sustainability of their events, and Vendoir is
              dedicated to assisting them in achieving this objective. Vendoir
              is a comprehensive platform that offers businesses the necessary
              tools and resources to efficiently plan, oversee, and execute
              eco-friendly events.
            </p>
          </div>
          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 1: Establish Sustainable Objectives
            </h5>
            <p className="resource-sub-desc">
              To ensure the sustainability of your events, the initial step is
              to establish explicit and quantifiable objectives. What exactly do
              you aim to accomplish? Are you looking to decrease the carbon
              footprint of your event by 20%? Or perhaps divert 90% of the
              event's waste away from landfills? Once you have defined your
              objectives, you can proceed to create a strategy to attain them.
            </p>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 2: Opt for an Eco-Friendly Venue
            </h5>
            <p className="resource-sub-desc">
              The choice of venue significantly impacts the environmental impact
              of your event. Select a venue that is dedicated to adopting
              sustainable practices, such as energy efficiency, water
              conservation, and waste reduction. Seek out venues that possess
              certifications like LEED or Green Seal to ensure their commitment
              to sustainability.
            </p>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 3: Seek Sustainable Materials
            </h5>
            <p className="resource-sub-desc">
              Whenever feasible, opt for reusable materials like tablecloths,
              napkins, and silverware. If disposable materials are necessary,
              opt for biodegradable or compostable alternatives. For signage and
              handouts, prioritize recycled paper.
            </p>
          </div>
          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 4: Embrace Sustainable Food and Beverages
            </h5>
            <p className="resource-sub-desc">
              Whenever possible, prioritize sourcing local and organic food and
              beverages. This choice will decrease the ecological impact of
              transportation and promote sustainable farming techniques.
              Additionally, consider providing plant-based and vegetarian
              options to minimize meat consumption.
            </p>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 5: Implement an Effective Waste Reduction and Recycling
              Program
            </h5>
            <p className="resource-sub-desc">
              Take action to improve waste management by implementing a
              comprehensive program that focuses on reducing waste and
              increasing recycling. Tactics that can be employed include getting
              rid of single-use plastics, introducing composting bins, and
              establishing partnerships with local waste management companies to
              ensure proper recycling and disposal.
            </p>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 6: Encourage Sustainable Transportation
            </h5>
            <p className="resource-sub-desc">
              Motivate attendees to choose sustainable transportation options,
              such as public transportation, carpooling, or cycling, to diminish
              carbon emissions caused by transportation. Collaborate with local
              transportation providers to offer incentives or discounts for
              eco-friendly travel choices.
            </p>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 7: Minimize Energy Consumption
            </h5>
            <p className="resource-sub-desc">
              Ensure efficient energy usage by utilizing energy-saving lighting
              fixtures, appliances, and equipment. Whenever possible, consider
              incorporating renewable energy sources into the event's energy
              requirements.
            </p>
          </div>

          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 8: Share Your Sustainability Initiatives
            </h5>
            <p className="resource-sub-desc">
              Ensure that your dedication to sustainability is well-known by
              communicating it effectively to all participants, collaborators,
              and stakeholders. Emphasize the eco-friendly initiatives you have
              implemented and motivate others to engage in your sustainability
              endeavours.
            </p>
          </div>
          <div className="resorce-sub-content">
            <h5 className="resource-sub-title">
              Step 9: Assess and Document Your Influence
            </h5>
            <p className="resource-sub-desc">
              Monitor your advancement towards achieving your sustainability
              objectives and evaluate the environmental consequences of your
              event. Utilize this information to fine-tune your strategies and
              illustrate your unwavering dedication to sustainability.
            </p>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default ResourceFifth;
