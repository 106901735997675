import React, { useState } from "react";
import { Link } from "react-router-dom";

function Contactform() {
  // const [isfieldempty, Setisfieldempty] = useState(false);
  const [message, setMessage] = useState("");

  const [fNameError, setFNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");
  const [descError, setDescError] = useState("");
  const [desc, setDesc] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleSubscribeClick = () => {
    if (!validateForm()) {
      setSuccessMessage("");
      setMessage(" Please Fill the form Field");
    } else if (!isCheckboxChecked) {
      setMessage("Please agree to term and condition");
    } else {
      const payload = {
        first_name: fName,
        last_name: lName,
        email: email,
        phone: phone,
        message: desc,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/contact`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));

      setSuccessMessage(
        "Thank you for submitting, we will be in touch shortly"
      );
      setFName("");
      setLName("");
      setPhone("");
      setEmail("");
      setDesc("");
      setIsCheckboxChecked(false);
    }
  };

  const validateForm = () => {
    let isValid = true;

    // Validate First Name
    if (fName.trim() === "") {
      setFNameError("First Name is required");
      isValid = false;
    } else {
      setFNameError("");
    }

    // Validate First Name
    if (lName.trim() === "") {
      setLNameError("Last Name is required");
      isValid = false;
    } else {
      setLNameError("");
    }

    // Validate Country
    if (desc.trim() === "") {
      setDescError("Message is required");
      isValid = false;
    } else {
      setDescError("");
    }

    // Validate Email
    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (phone.trim() === "") {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      const cleanedPhone = phone.replace(/\D/g, "");

      if (!/^\+?\d+$/.test(cleanedPhone)) {
        setPhoneError("Please enter a valid phone number with numbers only");
        isValid = false;
      } else {
        setPhoneError("");
      }
    }

    // Validate Checkbox

    if (!isCheckboxChecked) {
      setCheckBoxError("Please agree to term and condition");
      isValid = false;
    } else {
      setCheckBoxError("");
    }

    return isValid;
  };

  // const fieldempty = () => {
  //   Setisfieldempty(!isfieldempty);
  // };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <>
      <div className="Contactform-sec">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="Contactform-sec-heading">Contact Us</h2>
              <p className="Contactform-sec-title">
                If you have any questions or would like to request more info on
                our services please get in touch.
              </p>
            </div>
          </div>

          <div className="Contactform-inner">
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <div className="Contactform-inner-left" style={{marginTop: '50px'}}>
                  <h2 className="Contactform-inner-left-heading">
                    Contact Information
                  </h2>

                  <div className="Contactform-inner-lower">
                    <p className="Contactform-inner-title">For enquiry</p>
                    <div className="enquiry-info d-flex gap-1">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.8594 4H2.85938V20H22.8594V4ZM20.8594 8L12.8594 13L4.85938 8V6L12.8594 11L20.8594 6V8Z"
                          fill="white"
                        />
                      </svg>
                      <p className="contact-info Contactform-inner-title">
                        info@vendoir.com
                      </p>
                    </div>

                    <p className="Contactform-inner-title">For Support</p>
                    <div className="enquiry-info d-flex gap-1">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.8594 4H2.85938V20H22.8594V4ZM20.8594 8L12.8594 13L4.85938 8V6L12.8594 11L20.8594 6V8Z"
                          fill="white"
                        />
                      </svg>
                      <p className="contact-info Contactform-inner-title">
                        support@vendoir.com
                      </p>
                    </div>

                    <p className="Contactform-inner-title">Address</p>
                    <div className="enquiry-info d-flex gap-1">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.8594 1.5C10.6721 1.50258 8.57522 2.3726 7.0286 3.91922C5.48199 5.46584 4.61196 7.56276 4.60938 9.75C4.60676 11.5374 5.19062 13.2763 6.27138 14.7C6.27138 14.7 6.49638 14.9963 6.53313 15.039L12.8594 22.5L19.1886 15.0353C19.2216 14.9955 19.4474 14.7 19.4474 14.7L19.4481 14.6978C20.5284 13.2747 21.1119 11.5366 21.1094 9.75C21.1068 7.56276 20.2368 5.46584 18.6902 3.91922C17.1435 2.3726 15.0466 1.50258 12.8594 1.5ZM12.8594 12.75C12.266 12.75 11.686 12.5741 11.1927 12.2444C10.6993 11.9148 10.3148 11.4462 10.0877 10.8981C9.86068 10.3499 9.80127 9.74667 9.91703 9.16473C10.0328 8.58279 10.3185 8.04824 10.7381 7.62868C11.1576 7.20912 11.6922 6.9234 12.2741 6.80764C12.8561 6.69189 13.4593 6.7513 14.0074 6.97836C14.5556 7.20542 15.0241 7.58994 15.3538 8.08329C15.6834 8.57664 15.8594 9.15666 15.8594 9.75C15.8584 10.5453 15.542 11.3078 14.9796 11.8702C14.4172 12.4326 13.6547 12.749 12.8594 12.75Z"
                          fill="white"
                        />
                      </svg>

                      <p className="contact-info Contactform-inner-title">
                        128 City Road, London, EC1V 2NJ.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="Contactform-inner-right">
                  <form className="contact-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            First Name<span className="color-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            value={fName}
                            onChange={(e) => setFName(e.target.value)}
                          />
                          {fNameError && (
                            <p className="error-message">{fNameError}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 ">
                        <div className="mb-3">
                          <label htmlFor="lastName" className="form-label">
                            Last Name<span className="color-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            value={lName}
                            onChange={(e) => setLName(e.target.value)}
                          />
                          {lNameError && (
                            <p className="error-message">{lNameError}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="emailAddress" className="form-label">
                            Email<span className="color-red">*</span>
                          </label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="emailAddress"
                          />
                          {emailError && (
                            <p className="error-message">{emailError}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 ">
                        <div className="mb-3">
                          <label htmlFor="phoneNumber" className="form-label">
                            Phone Number<span className="color-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          {phoneError && (
                            <p className="error-message">{phoneError}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3 d-flex flex-column">
                          <label htmlFor="message" className="form-label">
                            Message<span className="color-red">*</span>
                          </label>
                          <textarea
                            id="message"
                            rows="4"
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                            cols="50"
                            name="comment"
                            form="usrform">
                            {desc ? desc : "Write your message.."}
                          </textarea>
                          {descError && (
                            <p className="error-message">{descError}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="checkbox-msg">
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={isCheckboxChecked}
                      />
                      <label className="tc-desc">
                        {" "}
                        I agree to Vendoir’s{" "}
                        <Link to="/Terms">
                          <span className="termsandcondition">T&Cs</span>
                        </Link>{" "}
                        and{" "}
                        <Link to="/Privacy">
                          <span className="termsandcondition">
                            {" "}
                            Privacy Policy
                          </span>
                        </Link>{" "}
                        <span className="color-red">*</span>
                      </label>
                    </div>
                    {checkBoxError && (
                      <p className="error-message">{checkBoxError}</p>
                    )}
                  </form>

                  <button
                    onClick={handleSubscribeClick}
                    type="submit"
                    className="contact-button">
                    Send Message
                  </button>
                </div>

                {successMessage && (
                  <p className="Join Waitlist-message">{successMessage}</p>
                )}
                {message && <p className="d-none"></p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactform;
