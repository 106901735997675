/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';

import img1 from '../src/image/account-deletion-1.jpg';
import img2 from "../src/image/account-deletion-2.jpg";
import img3 from '../src/image/account-deletion-3.jpg';
import img4 from "../src/image/account-deletion-4.jpg";
import img5 from '../src/image/account-deletion-5.jpg';
import Aheader from './Aheader';
import Afooter from './Afooter';

const title = 'Vendoir | Account Deletion';

class AccountDelete extends PureComponent {
  componentDidMount() {
    document.title = title;
  }

  render() {
    return (
      <>
        <Aheader/>
        <div className="about-hero-section mb-5">
            <div className='container'>
              <div className="wrapper about-hero">
            <div
              data-w-id="6089d2af-7677-137c-9852-3dc738e7be00"
              className="sub-text-wrapper hero-about-us"
            >
              <h6  className='accountdelete-name'>Last updated January 09, 2024</h6>
            </div>
            <div
              data-w-id="76f20f21-b32e-d42b-dbf5-0e41ce107a63"
              className="about-hero-heading-wrapper"
            >
              <h1 className='account-delete-heading'>
                Account deletion
                <br />
              </h1>
            </div>
            <div className="values-item-text-wrapper mt-5">
              <div className="text-block values-item">
                <div className="text-block accountdelete-title">
                  You can delete your account along with all personal data.
                  We’ll review your account and ensure all requirements for deletion are
                  met. Once confirmed, we’ll remove your account and all personal info
                  within 30 days and unsubscribe you from our emails.
                </div>

                <div className="text-block accountdelete-title">
                  To delete your account, please submit a request to delete by logging into
                  Vendoir app and following the steps outlined below.
                </div>

                <div className="text-block values-item">
                  Step 1) Tap &quot;Profile&quot; in the bottom menu and go to &quot;Preferences&quot;.
                </div>

                <img
                  id="bloImg"
                  src={img1}
                  loading="lazy"
                  alt=""
                  className='account-delete-img'
                />

                <div className="text-block values-item">
                  Step 2) Tap &quot;Request to delete account&quot; at the bottom of the screen.
                </div>

                <img
                  id="bloImg"
                  src={img2}
                  loading="lazy"
                  alt=""
                  className='account-delete-img'
                />

                <div className="text-block values-item">
                  Step 3) Select reason for deletion and give any extra information about your case. Tap &quot;Next&quot; once finished.
                </div>

                <img
                  id="bloImg"
                  src={img3}
                  loading="lazy"
                  alt=""
                  className='account-delete-img'
                />

                <div className="text-block values-item">
                  Step 4) Read how our deletion process works. Tap &quot;Next&quot; once finished.
                </div>

                <img
                  id="bloImg"
                  src={img4}
                  loading="lazy"
                  alt=""
                  className='account-delete-img'
                />

                <div className="text-block values-item">
                  Step 5) Type in &quot;DELETE&quot; (all capitals), as well as your password and tap &quot;Request deletion&quot;.
                </div>

                <img
                  id="bloImg"
                  src={img5}
                  loading="lazy"
                  alt=""
                  className='account-delete-img'
                />

                <div className="text-block values-item">
                  To confirm you are the
                  true owner of this account, we may contact you via email. We will only
                  be able to proceed with your request once you follow the steps. A
                  confirmation email will be sent once your account is deleted.
                </div>

                <div className="text-block values-item">
                  If you are unable to log into your account, and you would like to
                  request to review, update, or delete your personal information,
                  please submit a request to
                  {' '}
                  <a href="mailto:support@vendoir.com">
                    support@vendoir.com
                  </a>
                  .
                </div>

              </div>
            </div>
              </div>
          </div>
        </div>

        <Afooter/>
      </>
    );
  }
}
export default AccountDelete;
