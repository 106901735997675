import React, { useState } from "react";
import Nb from "./Nb";
import Footer from "./Footer";
import { useEffect } from "react";
import visionimg from "./image/vision-img.webp";
import missionimg from "./image/mission-img.webp";
import seeimg from "../src/image/as-i-see-img.webp";
import seeimg2 from "../src/image/as-i-see-img2.webp";
import seeimg3 from "../src/image/as-i-see-img3.webp";
import seeimg4 from "../src/image/as-i-see-img4.webp";
import seeimg5 from "../src/image/as-i-see-img5.webp";
import light from "../src/image/light-img.png";
import light2 from "../src/image/light-img2.png";
import light3 from "../src/image/light-img3.png";
import startedimg from "../src/image/startingimg.webp";
import startedimg2 from "../src/image/how-its-going-img2.png";
import { Link, useLocation } from "react-router-dom";

function About() {
  const location = useLocation();

  useEffect(() => {
    // Save the current route to local storage when the component mounts
    localStorage.setItem("previousRoute", location.pathname);
  }, [location.pathname]);

  const handleLinkClick = () => {
    // Save the current route to local storage when a link is clicked
    localStorage.setItem("previousRoute", location.pathname);
  };
  const [showMoreOne, setShowMoreOne] = useState(true);
  // const [showMoreTwo, setShowMoreTwo] = useState(true);

  const handleReadMoreOne = () => {
    setShowMoreOne(!showMoreOne);
  };


  // const [MoreOne, setMoreOne] = useState(true);
  const [MoreTwo, setMoreTwo] = useState(true);

  const handleReadMoreTwo = () => {
    setMoreTwo(!MoreTwo);
};


  return (
    <>
      <Nb />

      {/* image-sec */}
 
      <div className="image-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="image-title">
                We are on a mission to make events sustainable,<br></br>{" "}
                stress-free & inclusive.
              </h4>
            </div>
          </div>
        </div>
      </div>

      {/* blog-sec */}

      <div className="about-blog-sec">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="about-sec-heading">About Us</h2>
            </div>
          </div>

          <div className="blog-sec-inner">
            <div className="row align-items-center pb-5">
              <div className="col-lg-6">
                <h4 className="about-blog-title">Our vision</h4>
                <p className="about-blog-desc">
                  At Vendoir, we get events - it's a world we live and breathe.
                  And it's why we're serious about what we're doing, and why we
                  plan to revolutionise event planning through our tech. We're
                  taking events to the next level… because the industry deserves
                  it.
                </p>
              </div>

              <div className="col-lg-6">
                <img src={visionimg} className="img-fluid" alt="vision" />
              </div>
            </div>

            <div className="row align-items-center pt-5">
              <div className="col-lg-6 order-2 order-lg-1">
                <img src={missionimg} className="img-fluid" alt="mission" />
              </div>

              <div className="col-lg-6 order-1 order-lg-2">
                <h4 className="about-blog-title">Our mission</h4>
                <p className="about-blog-desc">
                  We're taking back control: seamless sourcing, effortless
                  booking, and comprehensive management - all done in just a few
                  taps. We are on a mission to make events sustainable,
                  stress-free & inclusive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* card-sec */}

      <div className="cards-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="cards-sec-inner-left">
                 <img src={startedimg2} className="img-fluid" alt=""/>
                <h4 className="cards-sec-inner-left-question">
                How it Started
                </h4>

                <p className="cards-sec-inner-left-question-answer">
                In 2016, our CEO Oshoma faced a familiar event planning nightmare: finding the right suppliers. With a clear budget and vision, he was left scouring referrals and Google – a time-consuming and frustrating process. The result? Overpriced, unavailable sustainable options, and a growing sense of helplessness.</p>

                {!showMoreOne && (
                <p className="cards-sec-inner-left-question-answer">Rounding out the team was Yoma, CPO and founder of City Vybez, an event promotion company. Yoma understood the pain of booking DJs, hosts, MCs, and videographers – a notoriously time-consuming and expensive process. When Charles and Oshoma told him about the idea, Yoma knew Vendoir could revolutionize the industry.
                And so, Vendoir was born...
                </p>
                )}
                <a href="#0" className="readmore" onClick={handleReadMoreOne}>{showMoreOne ? 'Read More..' : 'Read Less'}</a>

              </div>
            </div>

            <div className="col-lg-6">
              <div className="cards-sec-inner-right">
                <img src={startedimg} className="img-fluid" alt=""/>
                <h4 className="cards-sec-inner-left-question">
                How it is going
                </h4>

                <p className="cards-sec-inner-left-question-answer">
                In 2020, Oshoma, Charles, and Yoma, united by their event planning vision, started Vendoir. They were tired of the time-consuming, expensive struggle of finding sustainable or reliable event suppliers. Their vision? A one-stop-shop platform to simplify everything – sourcing, booking, and managing suppliers
</p>

                {!MoreTwo && (
                <p className="cards-sec-inner-left-question-answer"> – all in one easy, affordable, and dare we say, fun experience.Fast forward to today, and Vendoir is shaking up the event industry, making life easier for businesses, planners, and suppliers alike. They tackled their problem, and are confident you'll love the solution. From work gatherings to awards ceremonies and milestone celebrations, Vendoir connects you with the best suppliers – all in one convenient place. Suppliers, showcase your talents and boost your revenue. It doesn't get simpler than that!
                Download the Vendoir app now!</p>
                )}
                <a href="#0" className="readmore" onClick={handleReadMoreTwo}>{MoreTwo ? 'Read more..' : 'Read less'}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* our-team-sec */}

      <div className="our-team">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h4 className="team-heading">Our team</h4>
              <div className="our-team-inner">
                <p className="our-team-desc">
                  The Vendoir team is made up of experienced and passionate
                  event planners,<br></br> sustainability experts, and
                  technologists. We are committed to providing our<br></br>{" "}
                  customers with the best possible service and helping them to
                  organize successful<br></br> and sustainable events.
                </p>
                <p className="our-team-desc">
                  Vendoir is the sustainable event planning platform that
                  businesses can trust. We<br></br> offer a wide range of
                  services to help businesses organize greener events, and we{" "}
                  <br></br>are committed to helping businesses reduce their
                  environmental impact. Contact <br></br>us today to learn more
                  about how we can help you plan your next event.
                </p>
                <p className="our-team-desc">
                  If you are interested in learning more about how Vendoir can
                  help you organize<br></br> your next event, please contact us
                  today. We would be happy to discuss your<br></br> needs and
                  provide you with a free consultation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* as-i-see-sec */}

      <div className="as-i-see-sec">
        <div className="container">
          <div className="row">
            <div className="as-i-see-sec-inner">
              <h2 className="as-i-see-haeding">As seen in</h2>
              <div className="as-i-see-sec-img">
                <img src={seeimg} alt="" />
                <img src={seeimg2} alt="" />
                <img src={seeimg3} alt="" />
                <img src={seeimg4} alt="" />
                <img src={seeimg5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* commitment-sec */}

      <div className="commitment-sec">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="glow-up-heading">
                Our commitment to our values<br></br> fuels our dedication to
                excellence.
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="commitment-card text-center">
                <div className="commitment-icon">
                  <img src={light} className="img-fluid" alt="light" />
                </div>

                <div className="commitment-card-inner">
                  <h4 className="commitment-card-inner-heading">Experience</h4>
                  <p className="commitment-card-inner-title">
                    With a team boasting a wealth of event<br></br>
                    industry wizardry, we've got the mojo to<br></br> crack any
                    problem you throw our wa
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="commitment-card text-center">
                <div className="commitment-icon">
                  <img src={light2} className="img-fluid" alt="light" />
                </div>

                <div className="commitment-card-inner">
                  <h4 className="commitment-card-inner-heading">Innovative</h4>
                  <p className="commitment-card-inner-title">
                    We're on a tech-fuelled mission to<br></br> sprinkle some
                    magic into your experience,<br></br> turning it from
                    ordinary to extraordinary!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="commitment-card text-center">
                <div className="commitment-icon">
                  <img src={light3} className="img-fluid" alt="light" />
                </div>

                <div className="commitment-card-inner">
                  <h4 className="commitment-card-inner-heading">Ethical</h4>
                  <p className="commitment-card-inner-title">
                    At the core of our values lie unwavering <br></br>
                    principles: integrity, synergy, and<br></br> customer
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="glow-blue-white-btn">
          <div className="glow-blue-btn">
            <Link to="/event-planning-consumer" onClick={handleLinkClick}>
              Start planning
            </Link>
          </div>
          <div className="glow-whit-btn">
            <Link to="/supplier" onClick={handleLinkClick}>
              Become a Supplier
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default About;
