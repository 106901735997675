import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogTwentyone() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-twentyOne">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
              Notting Hill Carnival Helping Guide
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">12 April 2024</p>
              </div>

              <p className="detail-inner-desc">
              The Notting Hill Carnival is just around the corner! This Caribbean inspired celebration will
              commence in late August. This 3 day long festival will need tons of preparation in order to make
              this the best Notting Hill Carnival to date. Vendoir can help ease this process in a number of
              ways!
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                1. Communication is key:
                </h3>
                <p className="blog-inner-lists-title">
                By providing a single platform for all communication related to vendor services, Vendoir
                streamlines interactions between organizers and vendors. This eliminates the need for
                multiple communication channels, such as emails, phone calls, or in-person meetings,
                which can be time-consuming and prone to miscommunication.

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                2. Easy Payments:
                </h3>
                <p className="blog-inner-lists-title">
                Offer secure and convenient payment processing within the app, reducing the need for
                cash transactions and effectively managing financial operations for both vendors and
                organizers.

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                3. Track Inventory:
                </h3>
                <p className="blog-inner-lists-title">
                With a large event like the Notting Hill Carnival, with almost a million expected
                attendees, quantitative data is hard to predict. Through the Vendoir app, we can enable
                vendors to manage their inventory efficiently through seamless communication. This will
                allow the tracking of popular items in real-time and help vendors meet demand
                effectively.

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                4. Sustainability:
                </h3>
                <p className="blog-inner-lists-title">
                Vendoir calculates the predicted carbon emission for each event it aids in planning.
                Vendoir can connect planners of the Notting Hill Carnival with sustainable vendors that
                pride themselves in reducing carbon footprint. Additionally, they can give these vendors
                an estimate of the total carbon emission of the event by including food and consumption
                waste and green transportation methods.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogTwentyone;
