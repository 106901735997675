import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import blogo from "../src/image/blogoimg.png";

function Bnb() {
  window.addEventListener("scroll", function () {
    var header = document.querySelector(".header");
    if (header) {
      if (window.scrollY > 50) {
        header.classList.add("header-bg");
      } else {
        header.classList.remove("header-bg");
      }
    }
  });

  return (
    <>
      <section className="header">
        <div className=" container nav_bg">
          <div className="row">
            <div className="col-12">
              <Navbar expand="lg">
                <Container>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Navbar.Brand>
                    <div className="header-logo">
                      <Link to="/business">
                        <img src={blogo} className="chome-logo" alt="logo" />
                      </Link>
                    </div>
                  </Navbar.Brand>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto ">
                      <NavLink to="/business" className="nav-link">
                        Home{" "}
                      </NavLink>
                      <NavLink to="/business-about" className="nav-link">
                        About Us{" "}
                      </NavLink>
                      <div className="dropdown">
                        <button>Service</button>
                        <div className="dropdown-content">
                          <Link to="/service" className="dropdown-content-name">
                            Service
                          </Link>
                          <Link
                            to="/busniess-refer"
                            className="dropdown-content-name">
                            Refer a business
                          </Link>
                        </div>
                      </div>
                      <NavLink to="/pricing" className="nav-link">
                        Pricing{" "}
                      </NavLink>
                      <div className="dropdown">
                        <button>Sustainability</button>
                        <div className="dropdown-content">
                          <Link
                            to="/sustainability"
                            className="dropdown-content-name">
                            Sustainability
                          </Link>
                          <Link
                            to="/resource"
                            className="dropdown-content-name">
                            Resources{" "}
                          </Link>
                        </div> 
                      </div>
                    </Nav>

                    <div className="header-button">
                      <div className="header-button-inner">
                        <Link
                          to="/"
                          className="Businesses-button nb-button active">
                          For Consumers
                        </Link>
                      </div>
                    </div>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bnb;
