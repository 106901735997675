import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../src/image/vendior-logo.svg";

function Nb() {
  // const navigate = useNavigate();


  window.addEventListener("scroll", function () {
    var header = document.querySelector(".header");
    if (header) {
      if (window.scrollY > 50) {
        header.classList.add("header-bg");
      } else {
        header.classList.remove("header-bg");
      }
    }
  });

  // const handleGetApp = () => {
  //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   if (/android/i.test(userAgent)) {
  //     // Open Android Store link
  //     openAndroidStore();
  //     return "Android";
  //   }

  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     // Open iOS App Store link
  //     openiOSAppStore();
  //     return "iOS";
  //   }

  //   // For Windows (desktop) or unknown platforms, open a generic link
  //   openGenericLink();
  //   return "Windows or unknown";
  // };

  // const openAndroidStore = () => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.vendoir.app.prod&pli=1",
  //     "_blank"
  //   );
  // };

  // const openiOSAppStore = () => {
  //   // Replace 'YOUR_IOS_APP_STORE_LINK' with the actual link for your iOS app
  //   window.open("https://apps.apple.com/gb/app/vendoir/id1630965089", "_blank");
  // };

  // const openGenericLink = () => {
  //   // Replace 'YOUR_GENERIC_LINK' with the actual link for your application for Windows or other platforms
  //   navigate("/getapp");
  // };

  return (
    <>
      <section className="header">
        <div className=" container nav_bg">
          <div className="row">
            <div className="col-12">
              <Navbar expand="lg">
                <Container>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Navbar.Brand>
                    <div className="header-logo">
                      <Link to="/">
                        <img src={logo} className="header-logo" alt="logo" />{" "}
                      </Link>
                      {/* <NavLink to="/getapp" className=" get-app get-mobile-app">
                        Get App{" "}
                      </NavLink> */}
                      {/* <button
                        className="get-app get-mobile-app"
                        onClick={handleGetApp}>
                        Get App{" "}
                      </button> */}
                    </div>
                  </Navbar.Brand>

                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto ">
                      <NavLink to="/" className="nav-link ">
                        Home{" "}
                      </NavLink>
                      <NavLink to="/about" className="nav-link">
                        About Us{" "}
                      </NavLink>
                      <div className="dropdown">
                        <button>Community</button>
                        <div className="dropdown-content">
                          <a
                            href="https://www.facebook.com/groups/890675242565244/"
                            target="_blank"
                            rel="noreferrer"
                            className="dropdown-content-name">
                            Community
                          </a>
                          <Link
                            to="/referfriend"
                            className="dropdown-content-name">
                            Refer a friend
                          </Link>
                        </div>
                      </div>
                      <NavLink to="/blog" className="nav-link">
                        Blog{" "}
                      </NavLink>
                      {/* <NavLink to="/getapp" className=" get-app get-desktop-app ">
                        Get App{" "}
                      </NavLink> */}
                      {/* <button
                        className="get-app get-desktop-app"
                        onClick={handleGetApp}>
                        Get App{" "} 
                      </button> */}
                    </Nav>

                    <div className="header-button">
                      <div className="header-button-inner">
                        <Link
                          to="/business"
                          className="consumer-button nb-button active">
                          {" "}
                          For Businesses
                        </Link>
                      </div>
                    </div>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Nb;
