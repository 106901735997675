import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogSeventeen() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-seeventeen">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                Vendoir live in beta on iOS and android
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">25 April,2022</p>
              </div>

              <p className="detail-inner-desc">
                The cat's out of the bag. It's official - Vendoir is now live in
                Beta on iOS and Android. This is huge for us after many months
                of building and testing in Alpha. The app is available to test
                for anyone in the UK. We couldn't have done this without our
                fantastic team of talented developers who have managed to build
                a fully-fledged 2-sided marketplace in a relatively short time
                with several API integrations in place.
                <br />
                <br />
                We will continue to test until the end of May as that's when we
                aim to bring on our first suppliers and organisers to test live
                transactions with a full product launch aimed for in June to our
                waitlist of +3,000.
                <br />
                <br />
                by Charles Ofoegbu, 25/04/2022
              </p>

              <p className="vendior-date">Co-Founder & CTO</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogSeventeen;
