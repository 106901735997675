import React from "react";
import { Link } from "react-router-dom";

function Bcard(props) {
  return (
    <>
      <Link to={props.linktag} className="business-blog-card-right-link ">
        <div className="business-blog-card">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4">
                <div className="business-blog-card-left">
                  <img src={props.img} className="img-fluid" alt="blog" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="business-blog-card-right">
                  <div className="business-blog-card-right-top">
                    <p className="business-blog-card-right-title">
                      {props.title}{" "}
                    </p>
                    <p className="business-blog-card-right-date">
                      {props.date}
                    </p>
                  </div>

                  <h4 className="business-blog-card-right-heading">
                    {props.heading}
                  </h4>
                  <p className="business-blog-card-right-desc">{props.desc} </p>

                  <div className="text-end">
                    <Link
                      to={props.linktag}
                      className="business-blog-card-right-link ">
                      {props.link}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Bcard;
