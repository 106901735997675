import React from "react";
import Bnb from "./Bnb";
import BFooter from "./BFooter";


function BusinessFounder() {
  return (
    <div>
      <Bnb />

      <section className="privacy">
        <div className="container">
          <div className="privacy-heading">
            <h5>Our founding members</h5>
            <h1>Our very first supporters</h1>
          </div>

          <div className="privacy-content">
            <p className="privacy-desc">
              Vendoir could never be Vendoir without our founding members who
              supported us through our very first crowdfunding campaign. This
              page is specially dedicated to those who meet the founding member
              criteria and has opted in to having their names listed. If you
              missed our 'opt in' correspondence and would still like to have
              your name included please let us know via{" "}
              <a href="info@vendoir.com">info@vendoir.com</a>.
              <br />
              <br />
              Founding Members:
            </p>
            <ul className="privacy-desc">
              <li className="mt-3  privacy-desc">
                <b>Oshoma Zekeri</b>
              </li>
              <li className="mt-3  privacy-desc">
                <b>Charles Ofoegbu</b>
              </li>
              <li className="mt-3  privacy-desc">
                <b>Yoma James Kukor</b>{" "}
              </li>
              <li className="mt-3  privacy-desc">Inua Momodu</li>
              <li className="mt-3  privacy-desc">Michael Brewer</li>
              <li className="mt-3  privacy-desc">Jenel Brandon</li>
              <li className="mt-3  privacy-desc">Tobi Afilaka</li>
              <li className="mt-3  privacy-desc">Chris Parkhouse Turner</li>
              <li className="mt-3  privacy-desc">Adnaan Ali</li>
              <li className="mt-3  privacy-desc">Shalom Zekeri</li>
              <li className="mt-3  privacy-desc">Ebi Obode</li>
              <li className="mt-3  privacy-desc">Tiago Silva</li>
              <li className="mt-3  privacy-desc">Kim Craven</li>
              <li className="mt-3  privacy-desc">Moheen Mehmood</li>
              <li className="mt-3  privacy-desc">Afolabi Gbajobi</li>
              <li className="mt-3  privacy-desc">Onyebuchi Egemonye</li>
              <li className="mt-3  privacy-desc">Grzegorz Janas</li>
              <li className="mt-3  privacy-desc">Andrey Vayner</li>
              <li className="mt-3  privacy-desc">Thomas Hosszu</li>
              <li className="mt-3  privacy-desc">Felix Ortiz Gil</li>
              <li className="mt-3  privacy-desc">Kenneth Omoya</li>
            </ul>
          </div>
        </div>
      </section>

      <BFooter />
    </div>
  );
}

export default BusinessFounder;
