import React from "react";
import blogo from "../src/image/blogoimg.png";
import payment from "../src/image/scanner-srvice-img.webp";
import apple from "../src/image/apple-icon.png";
import playstore from "../src/image/google-play-icon.png";
import logo from "../src/image/twitter-logo.png";
import logo1 from "../src/image/facebook-logo.png";
import logo2 from "../src/image/linkdin-logo.png";
import logo3 from "../src/image/instragram-logo.png";
import logo4 from "../src/image/music-logo.png";
import { Link } from "react-router-dom";

function Afooter() {
  return (
    <>
      <section className="footer-sec app-footer.                                                                ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-social">
                <img src={blogo} className="app-logo" alt="logo" />
              </div>
            </div>
          </div>

          <div className="footer-inner">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-inner-bottom">
                  <a
                    href=" https://twitter.com/vendoirapp "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo}
                      className="footer-bottom-link"
                      alt="twitter"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/vendoirapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo1}
                      className="footer-bottom-link"
                      alt="facebook"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/vendoir/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo2}
                      className="footer-bottom-link"
                      alt="linkedin"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/vendoirapp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo3}
                      className="footer-bottom-link"
                      alt="instagram"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@vendoirapp?_t=8jFY4fdZqs4&_r=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo4}
                      className="footer-bottom-link"
                      alt="tiktok"
                    />
                  </a>
                </div>

                <p className="footer-bottom-heading text-center">
                  {" "}
                  Vendoir Limited. Registration number: 12986100. Head Office:
                  128 City Rd, London EC1V 2NX, United Kingdom © 2024 Vendoir
                  Ltd. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Afooter;
