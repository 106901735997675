import React from "react";
import { Link } from "react-router-dom";

function Thankyou() {
  return (
    <>
      <div className="Thank-you-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="Thankyou-inner-sec">
                <Link to="/Chome" className="thank-close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="47"
                    height="48"
                    viewBox="0 0 47 48"
                    fill="none">
                    <path
                      d="M11.7188 35.564L34.8912 12.3916M11.7188 12.3916L34.8912 35.564"
                      stroke="white"
                      strokeWidth="4.34483"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
                <h3 className="thank-you-heading text-center">Thank you!</h3>
                <p className="thank-you-title text-center">
                  Thank you for completing our event planning form. We'll review
                  your<br></br> submission and reach out to schedule a
                  consultation within the next 48 hours<br></br> to discuss
                  yourevent goals and create a personalized plan.
                </p>

                <p className=" vendior-team text-center ">
                  We look forward to connecting with you soon!
                </p>
                <p className="vendior-team text-center">The Vendoir Team</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Thankyou;
