import React from "react";
import { useNavigate } from "react-router-dom";
import apple from "../src/image/apple-icon.png";
import playstore from "../src/image/google-play-icon.png";

function Bthank() {
  const navigate = useNavigate();
  const handleRoute = () => {
    const previousRoute = localStorage.getItem("previousRoute");

    // Navigate to the previous route
    navigate(previousRoute);
  };
  return (
    <>
      <div className="Thank-you-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="Thankyou-inner-sec">
                <div className="close-flex">
                  <button
                    onClick={handleRoute}
                    className="thank-close close "
                    id="close-route">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="47"
                      height="48"
                      viewBox="0 0 47 48"
                      fill="none">
                      <path
                        d="M11.7188 35.564L34.8912 12.3916M11.7188 12.3916L34.8912 35.564"
                        stroke="white"
                        strokeWidth="4.34483"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <h3 className="thank-you-heading text-center">thank yOU!</h3>
                <p className="thank-you-title text-center">
                  Thank you for completing our event planning form. We'll review
                  your submission and reach out to<br></br>
                  schedule a consultation call within the next 48 hours to
                  discuss your event goals and create a<br></br> personalized
                  plan.
                </p>

                <div className="visit-our-website">
                  <button onClick={handleRoute} className="visit-button">
                    visit our website
                  </button>
                </div>
              </div>

              <div className="Bthank-bottom text-center">
                <p className="Bthank-bottom-title">
                  We look forward to connecting with you soon!<br></br> The
                  Vendoir Team
                </p>

                <div className="bthank-social-link">
                  <a
                    href="https://apps.apple.com/gb/app/vendoir/id1630965089"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src={apple}
                      className="bthank-store"
                      alt="apple-store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.vendoir.app.prod&pli=1"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src={playstore}
                      className="bthank-store"
                      alt="play-store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bthank;
