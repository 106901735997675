import { useState } from "react";
import blogo from "../src/image/blogoimg.png";
import payment from "../src/image/scanner-srvice-img.webp";
import apple from "../src/image/apple-icon.png";
import playstore from "../src/image/google-play-icon.png";
import logo from "../src/image/twitter-logo.png";
import logo1 from "../src/image/facebook-logo.png";
import logo2 from "../src/image/linkdin-logo.png";
import logo3 from "../src/image/instragram-logo.png";
import logo4 from "../src/image/music-logo.png";
import { Link } from "react-router-dom";

function BFooter() {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleSubscribeClick = () => {
    if (email.trim() === "") {
      setMessage(" Please fill the Email");
    } else if (!isCheckboxChecked) {
      setMessage("please agree to term and condition");
    } else {
      const payload = {
        email: email,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/subscribe`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));
      setEmail("");
      setMessage("Thank you for submitting, we will be in touch shortly");
      setIsCheckboxChecked(false);
    }
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <>
      <section className="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="footer-social">
                <img src={blogo} className="footer-logo" alt="logo" />
                <div className="footer-social-inner">
                  <div className="footer-social-link">
                    <a
                      href="https://apps.apple.com/gb/app/vendoir/id1630965089"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={apple}
                        className="social-link"
                        alt="apple-store"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.vendoir.app.prod&pli=1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={playstore}
                        className="social-link"
                        alt="play-store"
                      />
                    </a>
                  </div>

                  <div className="footer-social-payment">
                    <img src={payment} alt="scanner" />
                  </div>
                </div>
                <div
                  className="hero-sub-text"
                  style={{
                    fontSize: "18px",
                    color: "#fff",
                    margin: "0.5rem 0",
                  }}
                >
                  <span style={{ color: "#ffc700", marginRight: "5px" }}>
                    ★★★★★&nbsp;
                  </span>
                  50+ Reviews
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 col-6">
              <div className="Who-we-are">
                <p className="Who-we-are-main text-center footer-main">
                  WHO WE ARE
                </p>
                <ul className="Who-we-are-list list-heading">
                  <Link to="/business_about">
                    <li className="list-title">About Us</li>
                  </Link>
                  <a
                    href="https://www.facebook.com/groups/890675242565244/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className="list-title">Community</li>
                  </a>
                  <Link to="/BusinessFounder">
                    <li className="list-title">Founding Members</li>
                  </Link>
                  <Link to="/Sitemap">
                    <li className="list-title">Sitemap</li>
                  </Link>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 col-6">
              <div className="Help">
                <p className="help-main footer-main">HELP</p>
                <ul className="Help-list list-heading">
                  <Link to="/Bterms">
                    <li className="list-title">Website Terms</li>
                  </Link>
                  <Link to="/business-cookies">
                    <li className="list-title">Cookie Policy</li>
                  </Link>
                  <Link to="/business-disclamier">
                    {" "}
                    <li className="list-title">Disclaimer</li>
                  </Link>
                  <Link to="/business-privacy">
                    <li className="list-title">Privacy Policy</li>
                  </Link>
                  <Link to="/busniess-refer">
                    <li className="list-title">Refer a business</li>
                  </Link>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="Subscribe">
                <p className="Subscribe-heading footer-main">
                  SUBSCRIBE TO OUR NEWSLETTER
                </p>

                <div className="subscripe-links" id="subscripe">
                  <input
                    type="email"
                    placeholder="Email address"
                    name="mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <a
                    href="#0"
                    className="Subscribe-btn"
                    onClick={handleSubscribeClick}
                  >
                    SUBSCRIBE
                  </a>
                </div>

                <div className="checkbox-msg">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={isCheckboxChecked}
                  />
                  <label className="checkbox-desc">
                    {" "}
                    I agree to the terms and conditions
                    <span className="color-red">*</span>
                  </label>

                  <br />
                </div>

                <p className="thank-you-message">{message}</p>
              </div>
            </div>
          </div>

          <div className="footer-inner">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-inner-bottom">
                  <a
                    href=" https://twitter.com/vendoirapp "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo}
                      className="footer-bottom-link"
                      alt="twitter"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/vendoirapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo1}
                      className="footer-bottom-link"
                      alt="facebook"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/vendoir/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo2}
                      className="footer-bottom-link"
                      alt="linkedin"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/vendoirapp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo3}
                      className="footer-bottom-link"
                      alt="instagram"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@vendoirapp?_t=8jFY4fdZqs4&_r=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={logo4}
                      className="footer-bottom-link"
                      alt="tiktok"
                    />
                  </a>
                </div>

                <p className="footer-bottom-heading text-center">
                  {" "}
                  Vendoir Limited. Registration number: 12986100. Head Office:
                  128 City Rd, London EC1V 2NX, United Kingdom © 2024 Vendoir
                  Ltd. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BFooter;
