import React, { PureComponent } from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Aheader from "./Aheader";
import Afooter from "./Afooter";

const title = "Vendoir | Frequently Asked Questions";

class Faqpage extends PureComponent {
  componentDidMount() {
    document.title = title;
  }

  render() {
    return (
      <>
        {/* <Aheader/> */}
        <div className="about-hero-section">
          <div className="container">
            <h2 className="text-center according-main-title">
              Frequently Asked Questions
            </h2>
            <div className="privacy-faq">
              <MDBContainer>
                <MDBAccordion alwaysOpen initialActive={1}>
                  <MDBAccordionItem
                    collapseId={1}
                    headerTitle="1. WHAT IS VENDOIR"
                  >
                    <p className="privacy-desc">
                      Vendoir is an on-demand app where people can instantly
                      source and book event suppliers that meets their needs in
                      minutes. We offer the ability for customers to search,
                      compare, review, source, and book suppliers on-demand
                      instantly for any type of event with a few taps. By
                      providing key event details and requirements on our app,
                      you will have the ability to browse through our list of
                      vetted suppliers based on your needs and either you
                      request to book or instantly book depending on the booking
                      process selected by the supplier. Before this, you can
                      message the supplier via instant messaging to discuss your
                      requirements, read reviews from previous clients before
                      making a payment which has guaranteed protection. We have
                      tons of vetted and experienced suppliers on our platform
                      including, photographers, videographers, Mc/Host, Live
                      bands and many more.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={2}
                    headerTitle="2. WHAT IS VENDOIR’S COMMISSION "
                  >
                    <p className="privacy-desc">
                      We charge our organisers a 10% fee that is included in the
                      overall booking fee, and this commission is a percentage
                      amount deducted from your total booking price. This
                      reflects the difference between what the customer pays and
                      the supplier take-home earnings. Our third-party payment
                      gateway Stripe will charge a small transactional fee
                      during payout. This will include a fee of £2 per active
                      account per month as well as 0.25% of the total payout
                      amount per month and 10p per payout: visit
                      <a
                        href="https://stripe.com/gb/connect/pricing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        https://stripe.com/gb/connect/pricing{" "}
                      </a>
                      for more information. Taking a commission allows us to
                      maintain and make continuous investments in the Vendoir
                      app. It covers essential operational costs of the business
                      such as marketing, app development, and payment processing
                      charges.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={3}
                    headerTitle="3. WHO ARE OUR SUPPLIERS?"
                  >
                    <p className="privacy-desc">
                      Our suppliers are event suppliers such as photographers,
                      videographers and, mc/hosts using our platform to showcase
                      their service to customers. We have a robust supplier
                      selection process, and each one would have gone through
                      our vetted process before being allowed onto the platform.
                      We only work with the best of the best and turn down
                      supplier applications that do not meet the quality
                      requirement.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={4}
                    headerTitle="4. CAN I NEGOTIATE THE PRICE WITH A SUPPLIER?"
                  >
                    <p className="privacy-desc">
                      Yes, via our instant messaging, but please be respectful
                      and understanding that everyone has a business to run and
                      a livelihood to make. Most suppliers pricing is based on
                      their experience and quality of work which matches the
                      requirement of your event. Once you have paid the
                      deposit/service charge, the price will not change, and all
                      agreed terms will be legally binding
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={5}
                    headerTitle="5. IS THERE SOMEWHERE I CAN READ REVIEWS & RATINGS, SERVICE OFFERED
                  AND SUPPLIERS CONTENTS?"
                  >
                    <p className="privacy-desc">
                      Yep! You can view all of these via the supplier’s profile.
                      They should also have further information about their
                      services, such as photographs or videos and an overview of
                      their profile. All that is required is to click on the
                      supplier’s profile via your Wishlist, event page, or
                      search carried out via the explore page, and you will be
                      able to click on the supplier’s name to view their profile
                      information.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={6}
                    headerTitle="6.   WHERE CAN I FIND SUPPLIER’S CONTACT DETAILS?"
                  >
                    <p className="privacy-desc">
                      If you need to contact a supplier, please use the instant
                      messaging system or the call supplier feature available
                      once you place a booking. Additionally, you can contact
                      our support team at&nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>{" "}
                      if you would like some assistance from us.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={7}
                    headerTitle="7.  DO YOU PERFORM BACKGROUND CHECKS ON YOUR SUPPLIERS?"
                  >
                    <p className="privacy-desc">
                      We carry out ID and proof of address verification via
                      Stripe to ensure we know the identity of our suppliers.
                      While we do our best to address any issues that are
                      brought to our attention, we do not endorse suppliers who
                      are listed on our platform. We recommend that customers
                      carry out their due diligence by asking relevant questions
                      before booking a supplier(s). You can also read reviews
                      and ratings of the supplier through their profile and/or
                      message them via instant messaging.
                      <br />
                      <br />
                      We pride ourselves on establishing a long-term, reliable
                      relationship with our suppliers. However, if any issues
                      arise with our customers and suppliers, please note we do
                      not take this likely. We would support the investigation
                      and act according to Vendoir&#39;s terms and condition.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={8}
                    headerTitle="8.  I NEED TO CANCEL MY BOOKING; WILL I GET A DEPOSIT REFUND?"
                  >
                    <p className="privacy-desc">
                      If you need to cancel your booking for whatever reason,
                      you can request cancellation via the app or get in touch
                      with the support team via support@vendoir.com. The deposit
                      may or may not be refundable, this will be completely
                      dependent on the cancellation policy in the Vendoir’
                      T&C’s. We understand that plans change, and you may need
                      to change the date or details of your Event or even cancel
                      it. Whilst we give you the ability to cancel bookings,
                      work may already have been undertaken by relevant
                      Suppliers at the point of cancellation. If you cancel your
                      Booking:
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={9}
                    headerTitle="9. DO WE COLLECT INFORMATION FROM MINORS?"
                  >
                    <p className="privacy-desc">
                      If you need to cancel your booking for whatever reason,
                      you can request cancellation via the app or get in touch
                      with the support team via support@vendoir.com. The deposit
                      may or may not be refundable, this will be completely
                      dependent on the cancellation policy in the Vendoir’
                      T&C’s. We understand that plans change, and you may need
                      to change the date or details of your Event or even cancel
                      it. Whilst we give you the ability to cancel bookings,
                      work may already have been undertaken by relevant
                      Suppliers at the point of cancellation. If you cancel your
                      Booking.
                      <ol>
                        <li>
                          Please note that a non-refundable service fee is
                          charged for each booking
                        </li>
                        <li>
                          31 days or more before the Event, you will receive a
                          refund of 100% of the Booking Fees; minus service fee
                        </li>
                        <li>
                          between 30 days and 21 days before the Event, you will
                          receive a refund of 90% of the Booking Fees; minus
                          service fee
                        </li>
                        <li>
                          between 20 days and 8 days before the Event, you will
                          receive a refund of 80% of the Booking Fees; minus
                          service fee
                        </li>
                        <li>
                          between 7 days and 48 hours before the Event, you will
                          receive a refund of 60% of the Fees; minus service fee
                          except where the affected Supplier receives an
                          alternative booking in respect of the Products for the
                          same date as your cancelled Event, in which case you
                          will receive a refund of up to 80% of the Booking
                          Fees; minus service fee, and
                        </li>
                        <li>
                          within 48 hours before the Event, you will receive a
                          refund of 30% of the Fees; minus service fee unless
                          the affected Supplier receives an alternative booking
                          in respect of the Products for the same date as your
                          cancelled Event, in which case you will receive a
                          refund of 80% of the Booking Fees; minus service fee.
                        </li>
                      </ol>
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={10}
                    headerTitle="10.  HELP - MY SUPPLIER HAS CANCELLED! WHAT HAPPENS NEXT?"
                  >
                    <p className="privacy-desc">
                      These are rare situations; however, on occasion, they can
                      happen. If your Supplier(s) has not shown up for your
                      event, please try getting in touch with them directly to
                      see why (they might just be delayed!). Also, get in touch
                      with our support team via the app (profile section) or
                      email&nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>{" "}
                      to let us know, so we can try and chase up the supplier if
                      required. Once we have received the full information of
                      the situation, we will assess and evaluate your case and
                      be in touch to discuss the outcome and process any refund
                      due to you.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={11}
                    headerTitle="11. HOW DO I RESET MY PASSWORD IF FORGOTTEN?"
                  >
                    <p className="privacy-desc">
                      If you have forgotten your password, you can request it by
                      clicking on forgot password and an email will be sent to
                      you with further instructions on how to reset it. Please
                      remember to check your ‘Junk’ folder in your email inbox.
                      If you are still having difficulties, please email our
                      support team via&nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>{" "}
                      or through the app (profile section), and they will be
                      able to assist you further.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={12}
                    headerTitle="12. MY EVENT DETAILS ARE INCORRECT, WHAT SHOULD I DO?"
                  >
                    <p className="privacy-desc">
                      Location change, the number of hours, and the date could
                      affect the total booking fee, so reach out to the support
                      team via &nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>
                      , who will assist you with this further as in most cases,
                      the booking might need to be cancelled and rebooked. and
                      rebooked.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={13}
                    headerTitle="13. HOW DO I TURN OFF/ON MY TEXT NOTIFICATIONS?"
                  >
                    <p className="privacy-desc">
                      This can be done via the unsubscribe link in any Vendoir
                      email footer.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={14}
                    headerTitle="14. HOW CAN I REMOVE MY ACCOUNT?"
                  >
                    <p className="privacy-desc">
                      If you no longer want to be part of the Vendoir community
                      and would like to remove your account, please reach out to
                      our support team at&nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>
                      , and we will be able to assist you in your account
                      removal. Although, we ensure to address any concerns or
                      reasons behind taking this decision to help with a
                      resolution.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={15}
                    headerTitle="15.  HOW CAN I HOST A SAFE EVENT UNDER CURRENT GOVERNMENT GUIDELINES?"
                  >
                    <p className="privacy-desc">
                      Provided you are not breaking any local lockdown laws,
                      your event can still go ahead. We always recommend
                      checking with your local authorities, government as well
                      as the CMA for guidance before committing to anything.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={16}
                    headerTitle="16. MY EVENT CAN NO LONGER GO AHEAD INDEFINITELY DUE TO EXTENUATING
                  CIRCUMSTANCES, CAN I GET A REFUND?"
                  >
                    <p className="privacy-desc">
                      All Refunds are treated based on the cancellation policy
                      in clause 6 of the customers T&C’s. Further to this, for
                      all cases, we consider various factors. Please note that
                      for cancellations related to COVID-19, our cancellations
                      policy set out in clauses 5.1 and 5.2 applies and COVID-19
                      is not classed as an event beyond any party’s reasonable
                      control. If any booking is cancelled for a reason relating
                      to COVID-19, we may in our sole and absolute discretion
                      vary the terms of our cancellations policy accordingly
                      (but for the avoidance of doubt you acknowledge that we
                      shall at no time be obliged to make such a variation).
                      Please email our support team at&nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>
                      , and our team will be able to assist you further.
                    </p>
                  </MDBAccordionItem>

                  <MDBAccordionItem
                    collapseId={17}
                    headerTitle="17. MY EVENT HAS BEEN POSTPONED AND MY ORIGINAL SUPPLIER IS NOT
                  AVAILABLE FOR THE NEW DATE OF MY EVENT, WHAT DO I DO NOW?"
                  >
                    <p className="privacy-desc">
                      Don’t Panic! we can find you a suitable replacement with
                      100’s vetted suppliers available on the platform. We would
                      work with you and support you in finding a suitable
                      replacement and help transfer your deposit over to your
                      new booking, meaning you’re all good to go. Please email
                      our Support team via the app or on &nbsp;
                      <a href="mailto:support@vendoir.com">
                        support@vendoir.com
                      </a>{" "}
                      to notify us, and we will do the rest!
                    </p>
                  </MDBAccordionItem>
                </MDBAccordion>
              </MDBContainer>
            </div>
          </div>
        </div>

        <Afooter />
      </>
    );
  }
}
export default Faqpage;
