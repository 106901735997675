import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogTwentyfour() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-twentyFour">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
              Host a Unique Mother’s Day!
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">4 February 2024</p>
              </div>

              <p className="detail-inner-desc">
              Mother’s Day is quickly approaching. Let’s shake things up this year and make your mother feel the utmost love this Mothers Day.
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                Flowers!
                </h3>
                <p className="blog-inner-lists-title">
                It would not be mother’s day without a beautiful flower bouquet for your mother. Vendoir 
                provides a variety of different flower vendors so you can find the perfect fit! Choose from an
                abundance of arrangements to tailor to your mother’s dream bouquet.

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                Throw a Mother’s Day Party!                </h3>
                <p className="blog-inner-lists-title">
                A celebration of not only your mother, but all of the mothers in your life. We can assist in creating the most unique Mother’s Day Party yet! Here is how to do it:
                <br/><br/>
                1. Invite all of your closest friends and family.
                <br/>
                2. Rent a venue that accommodates your party guests!
                <br/>
                3. Find the perfect florist through our app and select the perfect flower arrangements for the party.
                <br/>
                4. Book a catering service that provides your mother’s favorite cuisine.
                <br/>
                5. Hire a photographer or videographer to capture the most important women in your life all in one place!              

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                3. Track Inventory:
                </h3>
                <p className="blog-inner-lists-title">
                With a large event like the Notting Hill Carnival, with almost a million expected
                attendees, quantitative data is hard to predict. Through the Vendoir app, we can enable
                vendors to manage their inventory efficiently through seamless communication. This will
                allow the tracking of popular items in real-time and help vendors meet demand
                effectively.

                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                4. Sustainability:
                </h3>
                <p className="blog-inner-lists-title">
                Vendoir calculates the predicted carbon emission for each event it aids in planning.
                Vendoir can connect planners of the Notting Hill Carnival with sustainable vendors that
                pride themselves in reducing carbon footprint. Additionally, they can give these vendors
                an estimate of the total carbon emission of the event by including food and consumption
                waste and green transportation methods.
                </p>
              </div>


            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogTwentyfour;
