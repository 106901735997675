import Rcard1 from "../src/image/resource-img1.webp";
import Rcard2 from "../src/image/resource-img2.webp";
import Rcard3 from "../src/image/resource-img3.webp";
import Rcard4 from "../src/image/resource-img4.webp";
import Rcard5 from "../src/image/resource-img5.webp";
import Rcard6 from "../src/image/resource-img6.webp";

const Resourcedata = [
  {
    img: Rcard1,
    heading:
      "Embracing sustainability in the UK event industry: A comprehensive guide for event organizers and businesses",
    desc: "In the UK event industry, there is a growing recognition of the significant environmental impact caused by various aspects of events, such as transportation, catering, waste management, and energy consumption.",
    link: "Read More",
    linktag: "/resource/embracing-sustainability",
  },

  {
    img: Rcard2,
    heading: "Key steps to organizing sustainable events",
    desc: "Organizing sustainable events necessitates a comprehensive approach that encompasses all facets of event planning and execution.",
    link: "Read More",
    linktag: "/resource/keysteps",
  },

  {
    img: Rcard3,
    heading:
      "How to make the right selection when planning a sustainable event",
    desc: "When selecting a venue, look for those that have adopted eco-friendly practices, such as using LED lighting, motion sensors, and water-saving plumbing systems.",
    link: "Read More",
    linktag: "/resource/make-right-selection",
  },

  {
    img: Rcard4,
    heading:
      "How Vendoir can support event organizers and businesses in their sustainability efforts",
    desc: "Vendoir is a one-stop shop platform on a mission to make events sustainable, stress-free and inclusive.",
    link: "Read More",
    linktag: "/resource/support-organizer",
  },

  {
    img: Rcard5,
    heading: "Guiding Businesses to Sustainable Events",
    desc: "The global awareness of environmental sustainability is growing, and businesses are facing greater scrutiny for their environmental impact. This is particularly relevant for businesses organizing events, as they often generate a substantial amount of waste and emissions.",
    link: "Read More",
    linktag: "/resource/business-to-sustainable",
  },

  {
    img: Rcard6,
    heading: "How Vendoir can help businesses make their events sustainable",
    desc: "Vendoir offers a range of assistance to businesses looking to make their events sustainable: We provide a carefully selected directory of sustainable vendors, making it simple for businesses to find eco-friendly options.",
    link: "Read More",
    linktag: "/resource/help-business-events",
  },
];

export default Resourcedata;
