// SwiperSlider.js
import React, { useState } from "react";
import image1 from "../src/image/vendior-work-img.webp";
import image2 from "../src/image/vendior-work-img3.webp";
import image3 from "../src/image/vendior-work-img2.webp";

const SwiperSlider = () => {
  const [selectedImage, setSelectedImage] = useState('image1'); // Initialize with 'image1'

  // Event handler to update the selected image state based on the div clicked
  const handleDivClick = (imageName) => {
    setSelectedImage(imageName);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="Vendoir-heading">How Vendoir Works</h2>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <div>
                <div>
                  <div className={`vendior-works-checkbox ${selectedImage === 'image1' ? 'active' : ''}`} onClick={() => handleDivClick('image1')}>
                    <h2 className="vendior-works-heading">Step 1. Onboard</h2>
                    <p className="vendior-works-desc">In just seconds, create an account through social sign-on, provide event details and then search for and connect with the perfect suppliers to bring your vision to life.</p>
                  </div>

                  <div className={`vendior-works-checkbox ${selectedImage === 'image2' ? 'active' : ''}`} onClick={() => handleDivClick('image2')}>
                    <h2 className="vendior-works-heading">Step 2. Supplier Matching</h2>
                    <p className="vendior-works-desc">To help you find the perfect fit, the app recommends suppliers in various categories like catering, photography, and decor, allowing you to browse their profiles with photos, reviews, and pricing, and even message them directly within the app.</p>
                  </div>

                  <div className={`vendior-works-checkbox ${selectedImage === 'image3' ? 'active' : ''}`} onClick={() => handleDivClick('image3')}>
                    <h2 className="vendior-works-heading">Step 3. Power up your supplier business</h2>
                    <p className="vendior-works-desc">After selecting suppliers and communicating through the secure in-app chat, users can complete secure payments via various methods like Apple Pay, credit cards, or debit/credit cards, with clear cost breakdowns and invoicing. The app then confirms bookings with </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="Vendoir-different-left">
                <div>
                  {selectedImage && (
                    <img
                      src={
                        selectedImage === 'image2' ? image2 :
                        selectedImage === 'image3' ? image3 :
                        image1
                      }
                      alt={`Image ${selectedImage.substring(5)}`}
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwiperSlider;
