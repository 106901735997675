import React from "react";
import BFooter from "../BFooter";
import Bnb from "../Bnb";

function ResourceFourth() {
  return (
    <>
      <Bnb />
      <div className="blog-image-sec resource-image-fourth">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="resource-heading">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="blog-sec-heading resource-sec-title">
                How Vendoir can support event organizers and businesses in their
                sustainability efforts
              </h2>
            </div>
          </div>
        </div>

        <div className="resource-content">
          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              Vendoir is a one-stop shop platform on a mission to make events
              sustainable, stress-free and inclusive. Vendoir can support event
              organizers and businesses in their sustainability efforts by
              providing the following:
            </p>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                A curated marketplace of sustainable vendors: Vendoir connects
                event organizers with a wide range of sustainable vendors,
                including caterers, venues, décor providers, and entertainment
                companies that are committed to eco-friendly practices.
              </li>
              <li className="resource-sub-desc">
                Sustainability tools and resources: Vendoir provides event
                organizers with a variety of sustainability tools and resources,
                such as checklists, guides, and case studies, to help them plan
                and execute sustainable events.
              </li>
              <li className="resource-sub-desc">
                Sustainability tracking and reporting: Vendoir helps event
                organizers track their sustainability progress and measure the
                environmental impact of their events.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              Specific examples of how Vendoir can support event organizers and
              businesses in their sustainability efforts include:
            </p>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Helping event organizers find sustainable venues that are
                committed to eco-friendly practices, such as energy efficiency,
                water conservation, and waste reduction.
              </li>
              <li className="resource-sub-desc">
                Connect event organizers with sustainable caterers that use
                local and organic ingredients, minimize food waste and compost
                food scraps.
              </li>
              <li className="resource-sub-desc">
                Providing event organizers with access to a network of
                sustainable transportation providers, such as public
                transportation companies and carpooling services.
              </li>
              <li className="resource-sub-desc">
                Helping event organizers source eco-friendly event materials,
                such as recycled paper, biodegradable packaging, and reusable
                tableware.
              </li>
              <li className="resource-sub-desc">
                Offering event organizers a platform to track their
                sustainability progress and measure the environmental impact of
                their events.
              </li>
            </ul>
          </div>

          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              In addition to these specific examples, Vendoir also provides
              event organizers with several other support services, such as:
            </p>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Event planning and management: Vendoir can help event organizers
                with all aspects of event planning and management, from venue
                selection and catering to budgeting and marketing.
              </li>
              <li className="resource-sub-desc">
                Vendor management: Vendoir can help event organizers manage
                their vendors, including negotiating contracts, handling
                payments, and ensuring that vendors adhere to the event's
                sustainability requirements.
              </li>
              <li className="resource-sub-desc">
                On-site event support: Vendoir can provide event organizers with
                on-site support to ensure that their events run smoothly and
                sustainably.
              </li>
            </ul>
          </div>
          <div className="resorce-sub-content">
            <p className="resource-sub-desc">
              By using Vendoir, event organizers and businesses can save time,
              money, and resources while planning and executing sustainable
              events. Vendoir is committed to helping the event industry become
              more sustainable and creating a more environmentally friendly
              future.
              <br />
              <br />
              Here are some additional ways that Vendoir can support event
              organizers and businesses in their sustainability efforts:
            </p>
            <ul className="resorce-sub-list">
              <li className="resource-sub-desc">
                Partner with environmental organizations to provide event
                organizers with access to expert advice and resources.
              </li>
              <li className="resource-sub-desc">
                Develop a sustainability certification program for event
                organizers and businesses.
              </li>
              <li className="resource-sub-desc">
                Host workshops and webinars on sustainable event planning and
                execution.
              </li>
              <li className="resource-sub-desc">
                Advocate for policies that support the adoption of sustainable
                practices in the event industry.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <BFooter />
    </>
  );
}

export default ResourceFourth;
