import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Routes,  } from "react-router-dom";
import Nb from "./Nb";
import Footer from "./Footer";
import Swiperslider from "./Swiperslider";
import Worldslider from "./Worldslider";
import About from "./About";
import Blog from "./Blog";
import Bcard from "./Bcard";
import Business from "./Chome";
import Contactform from "./Contactform";
import Service from "./Service";
import Pricing from "./Ourpackage";
import BusinessAbout from "./BusinessAbout";
import Bnb from "./Bnb";
import Sustainability from "./Sustainbility";
import Referfriend from "./Referfriend";
import Referbusiness from "./Referbusiness";
import Supplier from "./Supplier";
import Bstepper from "./BStepper";
import Button from "./Button";
import Gapp from "./Gapp";
import BFooter from "./BFooter";
import Cstepper from "./Cstepper";
import Organise from "./Eorganise";
import EventPlanningBusiness from "./EventPlanningBusiness";
import Thankyou from "./Thankyou";
import ScrollToTop from "./Scrolltop";
import Bthank from "./Bthank";
import Cthank from "./Cthank";
import Cookies from "./Cookies";
import Terms from "./Terms";
import Disclamer from "./Disclamer";
import Privacy from "./Privacy";
import Rcard from "./Rcard";
import Resource from "./Resource";
import ResourceFifth from "./Resourcecomponent/ResourceFifth";
import ResourceFirst from "./Resourcecomponent/ResourceFirst";
import ResourceFourth from "./Resourcecomponent/ResourceFourth";
import ResourceSixth from "./Resourcecomponent/ResourceSixth";
import ResourceThird from "./Resourcecomponent/ResourceThird";
import ResourceSecond from "./Resourcecomponent/ResourceSecond";
import Bdetail from "./Blogcomponent/Bdetail";
// import blogTwentyOne from "./Blogcomponent/BlogTwentyone";
import Chrismas from "./Blogcomponent/Chrismas";
import Nigating from "./Blogcomponent/Nigating";
import Top5 from "./Blogcomponent/Top5";
import Invest from "./Blogcomponent/Invest";
import Eventplanning from "./Blogcomponent/Eventplanning";
import Costchrismas from "./Blogcomponent/Costchrismas";
import BlogSix from "./Blogcomponent/BlogSix";
import BlogEight from "./Blogcomponent/BlogEight";
import BlogNine from "./Blogcomponent/BlogNine";
import BlogTen from "./Blogcomponent/BlogTen";
import Booking from "./Blogcomponent/Booking";
import BlogThirteen from "./Blogcomponent/BlogThirteen";
import BlogFourteen from "./Blogcomponent/BlogFourteen";
import BlogFifteen from "./Blogcomponent/BlogFifteen";
import BlogSixteen from "./Blogcomponent/BlogSixteen";
import BlogSeventeen from "./Blogcomponent/BlogSeventeen";
import BlogEighteen from "./Blogcomponent/BlogEighteen";
import BlogNineteen from "./Blogcomponent/BlogNineteen";
import BlogTwenty from "./Blogcomponent/BlogTwenty";
import BlogTwentyOne from "./Blogcomponent/BlogTwentyone";
import BlogTwentytwo from "./Blogcomponent/BlogTwentyonetwo";
import BlogTwentythree from "./Blogcomponent/BlogTwentyonethree";
import BlogTwentyFour from "./Blogcomponent/BlogTwentyfour";
import BlogTwentyFive from "./Blogcomponent/BlogTwentyfive";
import Bprivacy from "./Bprivacy";
import Bdisclaimer from "./Bdisclaimer";
import Bcookies from "./Bcookies";
import Bterms from "./Bterms";
import Error from "./Error";
import Sitemap from "./Sitemap";
import BusinessFounder from "./BusinessFounder";
import Founder from "./Founding";
import Faqpage from "./Fagpage";
import VendorTerms from "./VendorTerms";
import OrganizerTerms from "./OrganizerTerms";
import AccountDelete from "./AccountDelete";
import Aheader from "./Aheader";
import Afooter from "./Afooter";
import Home from "./Home";
import BlogTwentyfour from "./Blogcomponent/BlogTwentyfour";




function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
   
          <Route path="/" element={<Home />} />
          <Route path="/nb" element={<Nb />} />
          <Route path="/bnb" element={<Bnb />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/swiper" element={<Swiperslider />} />
          <Route path="/worldslider" element={<Worldslider />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/bcard" element={<Bcard />} />
          <Route path="/business" element={<Business />} />
          <Route path="/contactform" element={<Contactform />} />
          <Route path="/service" element={<Service />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/business-about" element={<BusinessAbout />} />
          <Route path="/blog/corporate-event-trends" element={<Bdetail />} />
          {/* <Route path="/Blog/blogTwentyOne" element={<blogTwentyOne />} /> */}
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/Referfriend" element={<Referfriend />} />
          <Route path="/busniess-refer" element={<Referbusiness />} />
          <Route path="/supplier" element={<Supplier />} />
          <Route path="/business-event-form" element={<Bstepper />} />
          <Route path="/consumer-event-form" element={<Cstepper />} />
          <Route path="/button" element={<Button />} />
          <Route path="/getapp" element={<Gapp />} />
          <Route path="/businessfooter" element={<BFooter />} />
          <Route path="/event-planning-consumer" element={<Organise />} />
          <Route
            path="/event-planning-business"
            element={<EventPlanningBusiness />}
          />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/scrolltop" element={<ScrollToTop />} />
          <Route path="/businessthankupage" element={<Bthank />} />
          <Route path="/thankupage" element={<Cthank />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/disclamer" element={<Disclamer />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/founders" element={<Founder />} />
          <Route path="/rcard" element={<Rcard />} />
          <Route path="/resource" element={<Resource />} />
          <Route
            path="/resource/embracing-sustainability"
            element={<ResourceFirst />}
          />
          <Route path="/resource/keysteps" element={<ResourceSecond />} />
          <Route
            path="/resource/make-right-selection"
            element={<ResourceThird />}
          />
          <Route
            path="/resource/support-organizer"
            element={<ResourceFourth />}
          />
          <Route
            path="/resource/business-to-sustainable"
            element={<ResourceFifth />}
          />
          <Route
            path="/resource/help-business-events"
            element={<ResourceSixth />}
          />
          <Route path="/blog/ugly-christmas-jumper" element={<Chrismas />} />

          <Route
            path="/blog/major-challenges-in-events-industry"
            element={<Nigating />}
          />
          <Route path="/blog/spook-tacular-halloween" element={<Top5 />} />
          <Route path="/blog/why-invest-in-sustainable" element={<Invest />} />
          <Route
            path="/blog/summer-event-planning-guide"
            element={<Eventplanning />}
          />
          <Route path="/blog/christmas-party-cost" element={<Costchrismas />} />
          <Route path="/blog/vendoir-for-business" element={<BlogSix />} />
          <Route path="/blog/winter-wedding" element={<BlogEight />} />
          <Route
            path="/blog/bonfire-night-london-2022"
            element={<BlogNine />}
          />
          <Route
            path="/blog/christmas-theme-ideas-guide"
            element={<BlogTen />}
          />
          <Route path="/blog/more-bookings-on-vendoir" element={<Booking />} />
          <Route path="/blog/wedding-photographer" element={<BlogThirteen />} />
          <Route path="/blog/wedding-trends-2022" element={<BlogFourteen />} />
          <Route path="/blog/find-perfect-supplier" element={<BlogFifteen />} />
          <Route path="/blog/sell-online" element={<BlogSixteen />} />
          <Route path="/blog/beta" element={<BlogSeventeen />} />
          <Route path="/blog/news" element={<BlogEighteen />} />
          <Route path="/blog/ceo" element={<BlogNineteen />} />
          <Route path="/blog/london-partners" element={<BlogTwenty />} />
          <Route path="/blog/nothing-hill-carnival" element={<BlogTwentyOne />} />
          <Route path="/blog/relax-during-this-may" element={<BlogTwentytwo />} />
          <Route path="/blog/summer-wedding" element={<BlogTwentythree />} />
          <Route path="/blog/mothers-day" element={<BlogTwentyFour />} />
          <Route path="/blog/dry-hiring" element={<BlogTwentyFive />} />
          <Route path="/business-privacy" element={<Bprivacy />} />
          <Route path="/business-disclamier" element={<Bdisclaimer />} />
          <Route path="/business-cookies" element={<Bcookies />} />
          <Route path="/Bterms" element={<Bterms />} />
          <Route path="/Sitemap" element={<Sitemap />} />
          <Route path="/BusinessFounder" element={<BusinessFounder/>} />
          <Route path="/Aheader"  element={ <Aheader/>} />
          <Route path="/Afooter"  element={ <Afooter/>} />
          <Route path="/faqpage"  element={ <Faqpage/>} />

          <Route
            path="/supplier-terms-and-conditions"
            element={<VendorTerms />}
          />
            <Route
            path="/organizer-terms-and-conditions"
            element={<OrganizerTerms/>}
          />
          <Route path="/account-deletion" element={<AccountDelete/>} />
          <Route path="*" element={<Error />} />
         </Routes>
         
      </Router>
    </>
  );
}

export default App;
