import React, { useState } from "react";
import Nb from "./Nb";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Supplier() {
  const [message, setMessage] = useState("");

  // const [isfieldempty, Setisfieldempty] = useState(false);

  const [fNameError, setFNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");
  const [countryError, setCountryError] = useState("");
  const [country, setCountry] = useState("");
  const [supplier, setSupplier] = useState("");
  const [supplierError, setSupplierError] = useState("");
  const [isEcoFriendly, setIsEcoFriendly] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleSubscribeClick = () => {
    if (!validateForm()) {
      setSuccessMessage("");
      setMessage(" Please Fill the form Field");
    } else if (!isCheckboxChecked) {
      setMessage("Please agree to term and condition");
    } else {
      const payload = {
        first_name: fName,
        last_name: lName,
        email: email,
        phone: phone,
        country: country,
        supplier_type: supplier,
        is_eco_friendly: isEcoFriendly,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      fetch(`${process.env.REACT_APP_API_URL}/supplier`, options)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error(error));

      setMessage("");
      setSuccessMessage(
        "Thank you for submitting, we will be in touch shortly"
      );
      setFName("");
      setLName("");
      setPhone("");
      setEmail("");
      setSupplier("");
      setCountry("");
      setIsCheckboxChecked(false);
    }
  };

  const validateForm = () => {
    let isValid = true;

    // Validate First Name
    if (fName.trim() === "") {
      setFNameError("First Name is required");
      isValid = false;
    } else {
      setFNameError("");
    }

    // Validate First Name
    if (lName.trim() === "") {
      setLNameError("Last Name is required");
      isValid = false;
    } else {
      setLNameError("");
    }

    // Validate Country
    if (country.trim() === "") {
      setCountryError("Country is required");
      isValid = false;
    } else {
      setCountryError("");
    }

    //Validate Supplier
    if (supplier.trim() === "") {
      setSupplierError("Supplier is required");
      isValid = false;
    } else {
      setSupplierError("");
    }

    // Validate Email
    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate Phone
    if (phone.trim() === "") {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      const cleanedPhone = phone.replace(/\D/g, "");

      if (!/^\+?\d+$/.test(cleanedPhone)) {
        setPhoneError("Please enter a valid phone number with numbers only");
        isValid = false;
      } else {
        setPhoneError("");
      }
    }

    if (!isCheckboxChecked) {
      setCheckBoxError("Please agree to term and condition");
      isValid = false;
    } else {
      setCheckBoxError("");
    }

    return isValid;
  };

  // const fieldempty = () => {
  //   Setisfieldempty(!isfieldempty);
  // };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  return (
    <>
      <Nb />

      <div className="Referbusiness-image-sec Bsuppliy-sec">
        <div className="container">
          <div className="row">
            <h4 className="image-title">
              We are on a mission to make events sustainable,<br></br>{" "}
              stress-free & inclusive.
            </h4>
          </div>
        </div>
      </div>

      <div className="Sustainability-new-sec bsupply-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-detail-sec-inner text-center">
                <h2 className="Blog-detai-heading">Become a Supplier</h2>
              </div>

              <div className="bsupply-middle text-center">
                <h3 className="bsupply-middle-heading">
                  List your supplier service on the Vendoir app and start
                  earning today{" "}
                </h3>
              </div>

              <div className="bsupply-sec-inner">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                      />
                      {fNameError && (
                        <p className="error-message">{fNameError}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 ">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">
                        Last Name<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                      />
                      {lNameError && (
                        <p className="error-message">{lNameError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label">
                        Email<span className="color-red">*</span>
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      {emailError && (
                        <p className="error-message">{emailError}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 ">
                    <div className="mb-3">
                      <label htmlFor="phoneNumber" className="form-label">
                        Phone Number<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {phoneError && (
                        <p className="error-message">{phoneError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label">
                        Country:<span className="color-red">*</span>
                      </label>
                      <select
                        className="form-select"
                        id="country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}>
                        <option value="Country">Country</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Aland Islands">Aland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua And Barbuda">
                          Antigua And Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia And Herzegovina">
                          Bosnia And Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, Democratic Republic">
                          Congo, Democratic Republic
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island &amp; Mcdonald Islands">
                          Heard Island &amp; Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic Of">
                          Iran, Islamic Republic Of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle Of Man">Isle Of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea">Korea</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States Of">
                          Micronesia, Federated States Of
                        </option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthelemy">
                          Saint Barthelemy
                        </option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts And Nevis">
                          Saint Kitts And Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Martin">Saint Martin</option>
                        <option value="Saint Pierre And Miquelon">
                          Saint Pierre And Miquelon
                        </option>
                        <option value="Saint Vincent And Grenadines">
                          Saint Vincent And Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome And Principe">
                          Sao Tome And Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia And Sandwich Isl.">
                          South Georgia And Sandwich Isl.
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard And Jan Mayen">
                          Svalbard And Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad And Tobago">
                          Trinidad And Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks And Caicos Islands">
                          Turks And Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United States Outlying Islands">
                          United States Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis And Futuna">
                          Wallis And Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      {countryError && (
                        <p className="error-message">{countryError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label">
                        Suppliers Type:<span className="color-red">*</span>
                      </label>
                      <select
                        className="form-select"
                        id="supplierType"
                        name="supplierType"
                        required=""
                        value={supplier}
                        onChange={(e) => setSupplier(e.target.value)}>
                        <option value="Bar Staff">Suppliers Type</option>
                        <option value="Bar Staff">Bar Staff</option>
                        <option value="Cake">Cake</option>
                        <option value="Caricaturist">Caricaturist</option>
                        <option value="Chauffeur Driven Car">
                          Chauffeur Driven Car
                        </option>
                        <option value="Children Entertainment">
                          Children Entertainment
                        </option>
                        <option value="Circus Entertainment">
                          Circus Entertainment
                        </option>
                        <option value="Cleaners">Cleaners</option>
                        <option value="Coach">Coach</option>
                        <option value="Comedian">Comedian</option>
                        <option value="Dance Act">Dance Act</option>
                        <option value="DJ">DJ</option>
                        <option value="Ensemble">Ensemble</option>
                        <option value="Event Decorator">Event Decorator</option>
                        <option value="Event Equipment">Event Equipment</option>
                        <option value="Event Organiser">Event Organiser</option>
                        <option value="Event Planner">Event Planner</option>
                        <option value="Event Security Staff">
                          Event Security Staff
                        </option>
                        <option value="Florist">Florist</option>
                        <option value="Games and Activities">
                          Games and Activities
                        </option>
                        <option value="Hair Stylist">Hair Stylist</option>
                        <option value="Impersonator or Look-alike">
                          Impersonator or Look-alike
                        </option>
                        <option value="Kids Party Bus">Kids Party Bus</option>
                        <option value="Lauxury Car">Lauxury Car</option>
                        <option value="Limousine">Limousine</option>
                        <option value="Live Band">Live Band</option>
                        <option value="Magician">Magician</option>
                        <option value="Makeup Artist">Makeup Artist</option>
                        <option value="MC / Host">MC / Host</option>
                        <option value="Minibus">Minibus</option>
                        <option value="Party Bus">Party Bus</option>
                        <option value="Photo Booth">Photo Booth</option>
                        <option value="Photographer">Photographer</option>
                        <option value="Quiz Master">Quiz Master</option>
                        <option value="Venue">Venue</option>
                        <option value="Singer">Singer</option>
                        <option value="Solo Musician">Solo Musician</option>
                        <option value="Speaker">Speaker</option>
                        <option value="Tribute Band">Tribute Band</option>
                        <option value="Videographer">Videographer</option>
                        <option value="Vintage &amp; Classic Wedding Car">
                          Vintage &amp; Classic Wedding Car
                        </option>
                        <option value="Waiting Staff">Waiting Staff</option>
                        <option value="Wedding Car">Wedding Car</option>
                        <option value="Other">Other</option>
                      </select>
                      {supplierError && (
                        <p className="error-message">{supplierError}</p>
                      )}
                    </div>
                  </div>
                </div>

                <label htmlFor="exampleInputEmail1" className="form-label">
                  Are you an eco-friendly Supplier:
                  <span className="color-red">*</span>
                </label>
                <div className="yes-no">
                  <div className="yes">
                    <input
                      type="radio"
                      id="age1"
                      name="age"
                      checked={isEcoFriendly}
                      onChange={() => setIsEcoFriendly(true)}
                      className="eco-friendly-inner-input"
                    />
                    <label htmlFor="age1" className="eco-friendly-inner-lable">
                      Yes
                    </label>
                  </div>

                  <div className="no">
                    <input
                      type="radio"
                      id="age2"
                      name="age"
                      className="eco-friendly-inner-input"
                      checked={!isEcoFriendly}
                      onChange={() => setIsEcoFriendly(false)}
                    />
                    <label htmlFor="age2" className="eco-friendly-inner-lable">
                      No
                    </label>
                  </div>
                </div>

                <div className="checkbox-msg">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={isCheckboxChecked}
                  />
                  <label className="tc-desc">
                    {" "}
                    I agree to Vendoir’s{" "}
                    <Link to="/Terms">
                      <span className="termsandcondition">T&Cs</span>
                    </Link>{" "}
                    and{" "}
                    <Link to="/Privacy">
                      <span className="termsandcondition"> Privacy Policy</span>
                    </Link>{" "}
                    <span className="color-red">*</span>
                  </label>
                </div>
                {checkBoxError && (
                  <p className="error-message">{checkBoxError}</p>
                )}
                <p className="Join Waitlist-message">{successMessage}</p>

                <div className="contact-btn">
                  <button
                    type="submit"
                    onClick={handleSubscribeClick}
                    className="contact-button">
                    Submit
                  </button>
                </div>
                {message && <p className="d-none error-message"></p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Supplier;
