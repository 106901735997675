import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function Nigating() {
  return (
    <>
      <Nb />
      <div className="blog-inner-image-sec blog-imag-third">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center ">
                Navigating the tumultuous waters
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">03 November,2023</p>
              </div>

              <p className="detail-inner-desc">
                The events industry is a dynamic and ever-evolving field, known
                for its capacity to adapt to various trends, technologies, and
                consumer preferences. However, like any other sector, it faces
                its share of challenges. Let’s take a look at the 5 main issues
                that plagues the industry and how you can navigate these
                tumultuous waters.
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  1. Post-Pandemic Recovery:
                </h3>
                <p className="blog-inner-lists-title">
                  Let’s be honest, The COVID-19 pandemic wreaked havoc on the
                  events industry, forcing cancellations, postponements, and
                  stringent health and safety regulations that are yet to be
                  caught up with to this day. While we have gradually returned
                  to in-person events, a full recovery remains a challenge.
                  Event planners now have the additional responsibility of
                  implementing robust health and safety measures, which can
                  increase costs and logistical complexity. The pandemic has
                  also accelerated the adoption of virtual and hybrid events,
                  creating a new norm that event planners must adapt to.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  2. Sustainability and Environmental Impact :
                </h3>
                <p className="blog-inner-lists-title">
                  Sustainability is not just a buzzword; it’s a pressing issue
                  that has a chokehold on the industry. The sector generates
                  vast amounts of waste and consumes substantial resources. More
                  specifically as indicated in a report by Net Zero Carbon
                  Events, 10-30% of waste contributes to the event’s carbon
                  footprint (Source). With so much waste being generated the
                  industry is screaming for a solution.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  3. Budget Constraints:{" "}
                </h3>
                <p className="blog-inner-lists-title">
                  Event budgets have faced pressure from both the economic
                  fallout of the pandemic and changing expectations from
                  clients. Companies and individuals are more cost-conscious,
                  making it challenging to justify large-scale, expensive
                  events. Event planners are now tasked with creating memorable
                  experiences while working within tighter budgets.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  4. Technological Advancements:
                </h3>
                <p className="blog-inner-lists-title">
                  The rapid evolution of technology presents both opportunities
                  and challenges. While innovative tech tools enhance the event
                  experience, they also require investment, expertise, and the
                  challenge of keeping up with the latest trends. Event
                  professionals must master the art of staying ahead of the
                  curve while ensuring that technology serves the event’s goals
                  and the audience’s needs
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  5. Diversity and Inclusion:
                </h3>
                <p className="blog-inner-lists-title">
                  The event industry is still exclusive to those with
                  disabilities, 54% choose not to return to a venue that has
                  inaccessible toilets and a further 48% will avoid those with
                  inaccessible entrances and navigation. In an increasingly
                  diverse and inclusive world, event professionals must create
                  events that reflect these values. This means ensuring
                  representation in speakers, attendees, and content as well as
                  accessibility within venues. Achieving diversity and inclusion
                  in events is an ongoing challenge that requires proactive
                  efforts and a commitment to change.
                </p>
                <p className="blog-inner-lists-title">
                  So here we have it, the choppy seas of the events are
                  difficult to navigate and are ever changing. The good news is
                  that we have the northern star that will help you through it
                  all, Vendoir app will help you create your events and the best
                  news in an inclusive and sustainable fashion! Gone are the
                  days where you had to keep track of everything and everyone,
                  now with the simple tap of your fingers all of the vendors are
                  compiled into one easy accessible place.
                </p>
              </div>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Nigating;
