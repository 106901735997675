import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function Costchrismas() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-eleven">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                What should the cost of your christmas<br></br> party be?
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">26 September,2022</p>
              </div>

              <p className="detail-inner-desc">
                Christmas is when people are more likely to spend money, and
                businesses know this. They will often offer special deals and
                discounts to encourage people to part with their cash.<br></br>{" "}
                <br></br>
                Christmas is also when people are more likely to socialize and
                visit stores and restaurants, so businesses must prepare for the
                influx of customers. Additionally, it is the time when many
                corporate parties occur. During all the excitement, one should
                not forget the cost of the party. The cost of your Christmas
                party will depend on several factors, including the size of the
                event, the location, the time of year, and the type of party you
                are hosting. If you are hosting a significant event or an
                upscale party, you may need to budget more accordingly. Of
                course, depending on your budget and preferences, you can always
                spend less or more.
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">1. Cost per head</h3>
                <p className="blog-inner-lists-title">
                  While businesses vary hugely in size and success, the average
                  spending on Christmas should not exceed £150 per head.
                  Companies that exceed the permitted £150 per employee per year
                  allowance for staff events are subject to severe penalties by
                  HMRC (the UK’s tax authority).
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">2. Food</h3>
                <p className="blog-inner-lists-title">
                  Food is what most people look for when they attend a party. It
                  is the one thing that can make or break a party. If you do not
                  have enough food, people will be disappointed and may not come
                  back.<br></br>
                  <br></br>
                  If you have too much food, people may be stuffed and not enjoy
                  themselves as much. Finding the right balance of food is key
                  to everyone’s enjoyment.<br></br>
                  Canapés and finger food are an excellent option for
                  budget-conscious partygoers. Expect to spend around £6 per
                  person on canapés and finger food, a fraction of the price of
                  a sit-down meal. Street food trucks or pop-up stalls are great
                  options for budget-friendly party food. Expect to spend around
                  £12 per person on street food, which is still relatively
                  affordable compared to other catering options. To have
                  Christmas dinner with all the trimmings, expect to spend £60
                  per person and £48 per person.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  3. Deciding on a venue{" "}
                </h3>
                <p className="blog-inner-lists-title">
                  It is best to host the party in your office to keep costs low.
                  Spending less on decorations, food, and drinks will give you
                  more money to spend. Having the party in your office will also
                  allow you to control the environment and ensure that things
                  stay under control. So, when you decide upon everything, the
                  next step would be finding the perfect supplier for the
                  event.Vendoir, our app, makes booking suppliers such as DJs,
                  photographers, or caterers easier and more affordable. We
                  offer a variety of suppliers to choose from and allow you to
                  book them on-demand.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">Final Remarks:</h3>
                <p className="blog-inner-lists-title">
                  Here are some suggestions for planning a Christmas party. The
                  first thing you must decide is the type of atmosphere you
                  desire. Will it be a formal event or a more casual gathering?
                  <br></br>
                  <br></br> Then, you can start researching different suppliers
                  that fit your budget and style. Finally, Vendoir is there to
                  provide you with a stress-free experience so you can focus on
                  enjoying your event.
                </p>
              </div>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Costchrismas;
