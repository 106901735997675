import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function BlogFourteen() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-fourteen">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                Biggest wedding trends 2022
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">09 June,2022</p>
              </div>

              <p className="detail-inner-desc">
                As we approach the end of the year, wedding planners everywhere
                are starting to finalize their plans for the coming years. And
                if you are also like most couples, you are probably wondering
                what the biggest wedding trends will be. You can choose one of
                these wedding trends to make your big day memorable. So, here
                are a few ideas to get you started:
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  1. Vintage-inspired weddings
                </h3>
                <p className="blog-inner-lists-title">
                  Some couples opt for sophistication on their big day.
                  Therefore, they go for vintage-inspired weddings. The
                  combination of classic gowns and accessories with modern flair
                  gives the guest a unique perspective on weddings overall.
                  Whether you decide to go with a vintage theme or not is up to
                  you. You can count on it giving charm and character to your
                  wedding! Make your special day memorable for your friends,
                  family, and all.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  2. Unique cake options
                </h3>
                <p className="blog-inner-lists-title">
                  The taste of cheese tower cakes is gaining popularity as an
                  alternative to traditional wedding cakes. The versatility and
                  delicious taste of cheese make it a versatile ingredient. It
                  can be served as part of a buffet or used to prepare a
                  dessert. Cheese towers are also an incredible way to show off
                  your wedding theme. If you have a themed wedding, you can use
                  cheese and cakes as one of the focal points of your
                  celebration.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">3. Casual dining</h3>
                <p className="blog-inner-lists-title">
                  Casual dining is one of the more affordable and less formal
                  trends in 2022. Millennials who love an easygoing dining
                  experience can find this an extremely favourable option. Some
                  of the most popular casual dining options include fast food,
                  delivery services, and pop-ups. Having a dining theme for your
                  big day will allow you to make it memorable on a budget.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  4. Destination weddings
                </h3>
                <p className="blog-inner-lists-title">
                  Destination weddings are one trend that is gaining a lot of
                  traction currently. For a destination wedding, you might
                  travel to somewhere exotic place next year. A destination
                  wedding will give you an unforgettable wedding experience.
                  After all, who doesn’t like an exotic beach or beautiful
                  jungle? Worldwide there are countless locations where you can
                  host your wedding and reception. Do your part of the research
                  and decide which destination appeals to you the most.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  5. Eco-friendly weddings
                </h3>
                <p className="blog-inner-lists-title">
                  The trend of eco-friendly weddings is increasing nowadays.
                  People who focus on the environment are looking for ideas to
                  have sustainable weddings without sacrificing their fun or
                  glamour. Some ideas include using recycled materials or
                  hosting your ceremony at a nature reserve instead of a
                  traditional venue.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">Conclusion:</h3>
                <p className="blog-inner-lists-title">
                  So, there you have it – the biggest wedding trends becoming
                  increasingly popular in 2022. If any of these interests or
                  intrigues you, keep track of upcoming events and trends and
                  get the most out of your Wedding Day!
                </p>
              </div>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogFourteen;
