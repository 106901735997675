import React, { PureComponent, useState } from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Aheader from './Aheader';
import Afooter from './Afooter';

const title = 'Vendoir | Organizer Terms and Conditions';

class OrganizerTerms extends PureComponent {
  componentDidMount() {
    document.title = title;
  }

 
   render() {

 
    return (
      <>
      <Aheader/>
        <div className="about-hero-section">
          <div className='container'>
          <div className="wrapper about-hero" style={{ display: 'block' }}>
            <h2 className="text-center according-main-title">Vendoir Organizer Terms and Conditions</h2>

            <div className="row" id="intro">
              <p className='text-center'>
                Your attention is particularly drawn to the provisions of clause 8 (Our Rights to Make Changes)
              </p>
            </div>

            <div className="privacy-faq">
            <MDBContainer>
              <MDBAccordion alwaysOpen initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="1. ABOUT US">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      <b>Who we are.</b>
                      {' '}
                      We are Vendoir Limited a company registered
                      in England and Wales. Our company registration number is
                      12986100 and our registered office is at 128 City Road,
                      London, United Kingdom, EC1V 2NJ (&quot;
                      <b>Vendoir</b>
                      &quot;, &quot;
                      <b>we</b>
                      &quot; or &quot;
                      <b>us</b>
                      &quot;). We operate the online platform
                      &quot;Vendoir&quot; accessible via the website https://www.vendoir.com/
                      (the &quot;
                      <b>Platform</b>
                      &quot;). In this document we will refer to
                      you, our organizer, as &quot;
                      <b>you</b>
                      &quot;).
                    </li>
                    <li>
                      <b>How to contact us.</b>
                      {' '}
                      You can contact our organizer
                      support team via our app or by emailing us at
                      support@vendoir.com. If we have to contact you, we will do so
                      by writing to you at the email address or postal address you
                      provided to us when signing up to use our Services.
                    </li>
                    <li>
                      <b>Our Platform and Services.</b>
                      {' '}
                      We provide the Platform
                      through which our organizers can connect with third party
                      suppliers (&quot;
                      <b>Suppliers</b>
                      &quot;) of events related goods and
                      services (&quot;
                      <b>Products</b>
                      &quot;) in relation to organizers&#39; events
                      (&quot;
                      <b>Events</b>
                      &quot;). Our services extend only to introducing you
                      to Suppliers via the Platform and allowing you to place orders
                      with them (&quot;
                      <b>Services</b>
                      &quot;).
                    </li>
                  </ol>
                  </p>
                  
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="2. THESE TERMS">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      <b>What these terms cover.</b>
                      {' '}
                      These are the terms and
                      conditions which govern your use of our Platform and the
                      Services. By clicking &quot;accept&quot;, you agree to these terms which
                      will bind you.
                    </li>
                    <li>
                      <b>Are you a business organizer or a consumer?</b>
                      {' '}
                      In some
                      areas you will have different rights under these terms
                      depending on whether you are a business or consumer. You are a
                      consumer if:
                      <ol type="a">
                        <li>You are an individual.</li>
                        <li>
                          You are using our services wholly or mainly for your
                          personal use (not for use in connection with your trade,
                          business, craft, or profession).
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Your authority to bind your business.</b>
                      {' '}
                      If you are using
                      the Platform on behalf of a business, you represent and
                      warrant personally that you have authority to agree to these
                      terms and conditions on behalf of the business and bind the
                      business to them.
                    </li>
                    <li>
                      <b>
                        If you are a business organizer this is our entire agreement
                        with you.
                      </b>
                      If you are a business organizer these terms constitute the
                      entire agreement between us in relation to your use of our
                      Platform and Services. You acknowledge that you have not
                      relied on any statement, promise, representation, assurance,
                      or warranty made or given by or on behalf of us which is not
                      set out in these terms and that you shall have no claim for
                      innocent or negligent misrepresentation or negligent
                      misstatement based on any statement in this agreement.
                    </li>
                    <li>
                      <b>&quot;Writing&quot; includes emails.</b>
                      {' '}
                      When we use the words
                      &quot;writing&quot; or &quot;written&quot; in these terms, this includes emails.
                    </li>
                    <li>
                      <b>We only sell to the UK.</b>
                      {' '}
                      Our website is solely for the
                      promotion of our products in the UK. Unfortunately, we do not
                      accept orders from addresses outside the UK.
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="3. THE ROLE OF VENDOIR">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      Vendoir&#39;s role is solely as a provider of the Platform whereby
                      users of the Platform are introduced to Suppliers. Vendoir is
                      not a Supplier of any of the Products.
                    </li>
                    <li>
                      You acknowledge and agree that:
                      <ol type="a">
                        <li>
                          Suppliers are the Suppliers of the Products and not Vendoir;
                        </li>
                        <li>
                          Vendoir has no control over the quality or delivery of any
                          of the Products, and has no responsibility or liability to
                          you in relation to any of the Products or any loss or
                          damage caused to you as a result of your using the
                          Products;
                        </li>
                        <li>
                          we do not give any representations, warranties, or
                          undertakings in relation to the Products. Any
                          representation, condition or warranty which might be
                          implied or incorporated into these terms by statute,
                          common law or otherwise is excluded to the fullest extent
                          permitted by law. In particular, we will not be
                          responsible for ensuring that the Products are suitable
                          for your purposes;
                        </li>
                        <li>
                          whilst we take reasonable steps to verify the identity of
                          the Suppliers using the Platform, we are unable to carry out
                          any comprehensive due diligence on Suppliers, and you are
                          responsible for carrying out any checks you deem necessary
                          regarding the Suppliers you wish to purchase Products from;
                        </li>
                        <li>
                          we are not responsible for any of the actions taken by the
                          Suppliers and are not party to any contract or transaction
                          you enter into with the Suppliers, nor do we have any
                          responsibility to enforce any such contract or transaction
                          against the Suppliers;
                        </li>
                        <li>
                          we do not guarantee the accuracy of any of the images or
                          descriptions of Products uploaded to the Platform by
                          Suppliers; and
                        </li>
                        <li>
                          we do not have any obligation to monitor, mediate or
                          decide upon any dispute arising between you and any
                          Suppliers.
                        </li>
                      </ol>
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={4}
                  headerTitle="4. ORDERING PRODUCTS VIA THE PLATFORM">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    Upon placing an order or a reservation for Products via the
                    Platform (a
                    {' '}
                    <b>&quot;Booking&quot;</b>
                    ), you must provide us with
                    certain information that we request from you, including:
                  </li>
                  <li>
                    You acknowledge and agree that:
                    <ol type="a">
                      <li>
                        the date on which your Event is scheduled to take place
                        when you purchase Products (please note that we cannot
                        accept Bookings in excess of 12 months before the
                        scheduled date of an Event);
                      </li>
                      <li>
                        other relevant information regarding your event; and
                      </li>
                      <li>
                        valid, up-to-date and complete credit or debit card
                        details and any other relevant valid, up-to-date and
                        complete contact and billing details as required by us.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You warrant and represent that the information you provide to
                    us in accordance with this agreement will be accurate and true
                    in all respects.
                  </li>
                  <li>
                    We will notify you to confirm whether your Booking has been
                    confirmed or accepted in writing. Only upon provision of such
                    confirmation will Suppliers be contractually bound to provide
                    Products to you in accordance with your Booking.
                  </li>
                  <li>
                    If you have any specific requirements when, or before, making
                    your Booking, you must communicate those requirements to your
                    Supplier using the in-app messaging system, and ensure you
                    document appropriately the terms of your agreement with the
                    Supplier. You may also include any specific requirements in the
                    space provided when making your Booking.
                  </li>
                  <li>
                    We use Stripe as our online payment provider, and you must
                    agree to their terms and conditions when using the Platform.
                    We shall provide Stripe with your relevant payment details in
                    accordance with our privacy policy.
                  </li>
                  <li>
                    Please ensure that you select the correct date for your Event
                    when making a Booking. If you would like to amend any of the
                    details of your Booking, including the date of your Event,
                    please contact us at &nbsp;
                    <a href="mailto:support@vendoir.com">support@vendoir.com</a>
                    .
                    We do not guarantee that your requested amendments can be
                    accommodated, or that such amendments will be made within any
                    timeframe. Additional fees may be incurred for carrying out
                    such amendments.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={5}
                  headerTitle="5. PAYMENT AND FEES">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      The fees you pay to us will comprise:
                      <ol type="a">
                        <li>
                          the Supplier&#39;s fees for the Products (
                          <b>&quot;Supplier Fees&quot;</b>
                          );
                          and
                        </li>
                        <li>
                          a fee equivalent to 10% of the Supplier Fees for our
                          Services (
                          <b>&quot;Service Fee&quot;</b>
                          ),
                        </li>
                      </ol>
                      together, the
                      {' '}
                      <b>&quot;Fees&quot;</b>
                      )
                    </li>
                    <li>
                      You agree to pay to us the Service Fee in return for our
                      Services. You acknowledge and agree that, because our Services
                      are provided at the time of your Booking by introducing you to
                      the relevant Supplier, the Service Fee is not refundable except
                      when provided under clauses 6.5 and 13.2.
                    </li>
                    <li>
                      You shall either pay all of the Fees due upon making your
                      Booking where clause 5.5 applies, or:
                      <ol type="a">
                        <li>
                          pay the Service Fee by the fourteenth day after the date
                          of your Booking or the thirtieth day before the date of
                          your Event, whichever is earlier; and
                        </li>
                        <li>
                          pay the Supplier Fees due no later than 30 days before the
                          date of your Event.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You authorise us to:
                      <ol type="a">
                        <li>
                          take payment of the Fees from you for your Booking as and
                          when they are required (in accordance with clauses 5.3 and
                          5.5), hold your payment on your behalf, make payment to
                          the Supplier of the Supplier Fees on your behalf, and (if you
                          are late in paying) continue to attempt to take payment
                          from you for your Booking periodically after the date on
                          which payment was originally required; and
                        </li>
                        <li>deduct and retain the Service Fee.</li>
                      </ol>
                    </li>
                    <li>
                      You:
                      <ol type="a">
                        <li>
                          have the option to pay the Fees in full at the time of
                          your Booking if your Booking is made within 90 days of
                          your Event;
                        </li>
                        <li>
                          shall be required to pay the Fees in full at the time of
                          your Booking if your Booking is made within 30 days of
                          your Event (instead of in accordance with clause 5.3).
                        </li>
                      </ol>
                    </li>
                    <li>
                      If you fail to pay the Fees by the thirtieth day before the
                      date of your Event (or by any other date by which payment of
                      any Fees to us is required), unless we agree otherwise with
                      you your Booking will be cancelled and you will forfeit the
                      Service Fee, which we shall be entitled to retain.
                    </li>
                    <li>
                      If your Booking is not accepted by the Supplier or you otherwise
                      cancel your Booking before it has been accepted, no Fees will
                      be taken by Vendoir.
                    </li>
                    <li>
                      We shall release payment of the Supplier Fees to the Supplier and
                      deduct and retain the Service Fee within 48 hours after the
                      date of the Event.
                    </li>
                    <li>
                      Our right of set-off if you are a business organizer. If you
                      are a business organizer you must pay all amounts due to us
                      under these terms in full without any set-off, counterclaim,
                      deduction or withholding (other than any deduction or
                      withholding of tax as required by law).
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={6}
                  headerTitle="6. ALTERATIONS AND CANCELLATIONS OF BOOKINGS">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      We understand that plans change, and you may need to change
                      the date or details of your Event or even cancel it. Whilst we
                      do give you the ability to cancel Bookings, work may already
                      have been undertaken by relevant Suppliers at the point of
                      cancellation. If you cancel your Booking:
                      <ol type="a">
                        <li>
                          Please note that a non-refundable service fee is charged
                          for each booking
                        </li>
                        <li>
                          31 days or more before the Event, you will receive a
                          refund of 100% of the Booking Fees; minus service fee
                        </li>
                        <li>
                          between 30 days and 21 days before the Event, you will
                          receive a refund of 90% of the Booking Fees; minus service
                          fee
                        </li>
                        <li>
                          between 20 days and 8 days before the Event, you will
                          receive a refund of 80% of the Booking Fees; minus service
                          fee
                        </li>
                        <li>
                          between 7 days and 48 hours before the Event, you will
                          receive a refund of 60% of the Fees; minus service fee
                          except where the affected Supplier receives an alternative
                          booking in respect of the Products for the same date as
                          your cancelled Event, in which case you will receive a
                          refund of up to 80% of the Booking Fees; minus service
                          fee, and
                        </li>
                        <li>
                          within 48 hours before the Event, you will receive a
                          refund of 30% of the Fees; minus service fee unless the
                          affected Supplier receives an alternative booking in respect
                          of the Products for the same date as your cancelled Event,
                          in which case you will receive a refund of 80% of the
                          Booking Fees; minus service fee.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Please note that for cancellations related to COVID-19 or any
                      extenuating circumstances, our cancellations policy set out
                      above in clause 6.1 applies and COVID-19 is not classNameed as
                      an event beyond any party&#39;s reasonable control. If a Booking
                      is cancelled for a reason relating to COVID-19, we may in our
                      sole and absolute discretion vary the terms of our
                      cancellations policy accordingly (but for the avoidance of
                      doubt you acknowledge that we shall at no time be obliged to
                      make such a variation).
                    </li>
                    <li>
                      Any sum retained by us as a result of your cancellation will
                      include the whole of our Service Fee in total, with the
                      remainder being the proportion of the Supplier Fees which shall
                      be released by us to the Supplier in question (less any
                      additional payment fees owed to us by the Supplier).
                    </li>
                    <li>
                      You acknowledge and agree that each Supplier may also implement
                      additional cancellation terms that apply to you.
                    </li>
                    <li>
                      If the Supplier cancels a Booking at any time, we will hold the
                      Fees you have paid to us on your behalf while you locate an
                      alternative Supplier, and if you decide to use an alternative
                      Supplier such Fees can be used to pay it. Our organizer support
                      team will provide assistance in searching for a new Supplier. In
                      any event in such circumstances, you will be entitled to a
                      full refund of the Booking Fees you have paid if you are
                      unable or do not wish to locate a new Supplier, and you can
                      notify us as such by emailing support@vendoir.com
                    </li>
                    <li>
                      Prior to cancelling any Booking, you agree to enter into good
                      faith discussions with the Supplier to establish whether the
                      date and time of the Event in question can be changed to suit
                      all involved parties.
                    </li>
                    <li>
                      Any refunds will be processed within 5 - 15 days depending on
                      your bank.
                    </li>
                    <li>
                      If you need to make any other changes to your Booking, please
                      contact us by emailing support@vendoir.com. We will discuss
                      the change with any relevant Suppliers and let you know if the
                      change is possible. If it is possible, we will let you know
                      about any changes to the Fees, the timing of supply or
                      anything else which would be necessary as a result of your
                      requested change and ask you to confirm whether you wish to go
                      ahead with the change.
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={7}
                  headerTitle="7. DISPUTES">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    If you have a complaint regarding the Product received, you
                    must raise a complaint within 7 days after the date of the
                    Event. If you fail to do so, we will not assist in mediating
                    the dispute
                  </li>
                  <li>
                    In the first instance, you should attempt to resolve your
                    dispute directly with the Supplier and enter into good faith
                    negotiations with the Supplier in order to do so. If the Product
                    is defective or comprises services not properly performed, the
                    Supplier will be responsible for providing a refund or
                    replacement according to the organizers preference.
                  </li>
                  <li>
                    In the event that you and the Supplier are unable to resolve a
                    dispute between you directly, you can ask us to mediate the
                    dispute. If Vendoir mediates the dispute, you agree to accept
                    our conclusion and pay any amount which Vendoir determines you
                    are liable for. In the course of mediating a dispute, we may
                    ask both you and the Supplier to submit all such evidence and
                    information as we reasonably require in order to reach a
                    conclusion, and you agree to do so.
                  </li>
                </ol>
                </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={8}
                  headerTitle="8. OUR RIGHTS TO MAKE CHANGES">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    Minor changes to the products. We may change the nature and
                    functionality of the Services and the Platform, and these
                    terms, to reflect changes in relevant laws and regulatory
                    requirements.
                  </li>
                  <li>
                    From time to time, we may automatically update the Platform to
                    improve performance, enhance functionality, reflect changes to
                    the operating system or address security issues.
                  </li>
                  <li>
                    We will not make any changes to the Services or these terms
                    where such changes would affect your rights in respect of
                    existing Bookings you have made.
                  </li>
                  <li>
                    Sensitive Media Policy
                    <ol type="a">
                      <li>
                        Please note that failure to comply with our terms and conditions
                        may result in the delisting or complete removal of your profile.
                      </li>
                      <li>
                        Vendoir does not permit the posting of graphic content or media
                        that depicts death, violence, medical procedures, or serious
                        physical injury in graphic detail. This includes depictions
                        of violent crimes or accidents, physical fights, physical child
                        abuse, bodily fluids including blood, faeces, semen, etc.,
                        serious physical harm including visible wounds, and
                        severely injured or mutilated animals.
                      </li>
                      <li>
                        Additionally, adult nudity and sexual behaviour are not
                        permitted on our platform. This includes consensually produced
                        and distributed media that is pornographic or intended to
                        cause sexual arousals, such as full or partial nudity,
                        simulated sexual acts, sexual intercourse, or other sexual
                        acts. Exceptions may be made for artistic, medical,
                        health, or educational content.
                      </li>
                      <li>
                        Violent sexual conduct is also prohibited, including media
                        that depicts violence, whether real or simulated, in
                        association with sexual acts. This includes depictions
                        of rape and other forms of violent sexual assault, sexual
                        acts that occur without the consent of participants,
                        and sexualized violence that inflicts physical harm on an
                        individual within an intimate setting where it is not
                        immediately obvious if those involved have consented to take part.
                      </li>
                      <li>
                        Media depicting excessively gory content, sexual
                        violence and/or assault, bestiality, or necrophilia is
                        also not permitted.
                      </li>
                      <li>
                        If you come across any violations of our policy, you can
                        report the content in-app by tapping &quot;Support&quot;
                        on the app profile page or emailing us at support@vendoir.com.
                      </li>
                    </ol>
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={9}
                  headerTitle="9. RIGHT TO USE THE PLATFORM">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    We give you the right for the term of this agreement to use
                    the Platform solely for the purposes of accessing and using
                    the Services in accordance with these terms.
                  </li>
                  <li>
                    You agree that you will:
                    <ol type="a">
                      <li>
                        not rent, lease, sub-license, loan, provide, or otherwise
                        make available, the Platform or Services in any form, in
                        whole or in part to any person without prior written
                        consent from us;
                      </li>
                      <li>
                        not copy the Platform or Services, except as part of the
                        normal use of the Platform or where it is necessary for
                        the purpose of back-up or operational security;
                      </li>
                      <li>
                        not translate, merge, adapt, vary, alter or modify, the
                        whole or any part of the Platform or Services nor permit
                        the Platform or the Services or any part of them to be
                        combined with, or become incorporated in, any other
                        programs, except as necessary to use the Platform and the
                        Services on devices as permitted in these terms;
                      </li>
                      <li>
                        not disassemble, de-compile, reverse engineer or create
                        derivative works based on the whole or any part of the
                        Platform or the Services nor attempt to do any such
                        things, except to the extent that (by virtue of sections
                        50B and 296A of the Copyright, Designs and Patents Act
                        1988) such actions cannot be prohibited because they are
                        necessary to decompile the Platform to obtain the
                        information necessary to create an independent program
                        that can be operated with the Platform or with another
                        program (&#39;Permitted Objective&#39;), and provided that the
                        information obtained by you during such activities:
                        <ol type="i">
                          <li>
                            is not disclosed or communicated without our prior
                            written consent to any third party to whom it is not
                            necessary to disclose or communicate it in order to
                            achieve the Permitted Objective; and
                          </li>
                          <li>
                            is not used to create any software that is
                            substantially similar in its expression to the
                            Platform; (iii) is kept secure; and
                          </li>
                          <li>is used only for the Permitted Objective;</li>
                        </ol>
                      </li>
                      <li>
                        comply with all applicable technology control or export
                        laws and regulations that apply to the technology used or
                        supported by the Platform or any Service.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You must:
                    <ol>
                      <li>
                        not use the Platform or any Service in any unlawful
                        manner, for any unlawful purpose, or in any manner
                        inconsistent with these terms, or act fraudulently or
                        maliciously, for example, by hacking into or inserting
                        malicious code, such as viruses, or harmful data, into the
                        Platform, any Service or any operating system;
                      </li>
                      <li>
                        not infringe our intellectual property rights or those of
                        any third party in relation to your use of the Platform or
                        any Service, including by the submission of any material
                        (to the extent that such use is not licensed by these
                        terms);
                      </li>
                      <li>
                        not transmit any material that is defamatory, offensive or
                        otherwise objectionable in relation to your use of the
                        Platform or any Service;
                      </li>
                      <li>
                        not use the Platform or any Service in a way that could
                        damage, disable, overburden, impair or compromise our
                        systems or security or interfere with other users; and
                      </li>
                      <li>
                        not collect or harvest any information or data from any
                        Service or our systems or attempt to decipher any
                        transmissions to or from the servers running any Service.
                      </li>
                    </ol>
                  </li>
                  <li>
                    When creating an account on the Platform, you will need to
                    choose a username and password, which you must keep
                    confidential at all times. You should notify us immediately if
                    you become aware of any disclosure or unauthorised use of your
                    account details. You are responsible for all activity on the
                    Platform using your account, whether authorised or
                    unauthorised.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={10}
                  headerTitle="10. INTELLECTUAL PROPERTY RIGHTS AND USER GENERATED CONTENT">
                  <p className="privacy-desc"> 
                  <ol>
                  <li>
                    All intellectual property rights in the Platform and the
                    Services throughout the world belong to us or our third-party
                    licensors and you are only granted a right to use the Platform
                    and the Services. You have no intellectual property rights in,
                    or to, the Platform or the Services other than the right to
                    use them in accordance with these terms.
                  </li>
                  <li>
                    The Platform may include information and materials uploaded by
                    other users of the Platform, such as reviews and comments
                    about specific Suppliers (&#39;Reviews&#39;). These Reviews have not
                    been verified or approved by us. The views expressed by other
                    users on the Platform do not represent our views or values.
                  </li>
                  <li>
                    You shall own all intellectual property rights in any Reviews
                    you post on the Platform. You grant to us a worldwide,
                    non-exclusive, perpetual, royalty-free, transferable licence
                    to use, reproduce, distribute, prepare derivative works of,
                    and display, those Reviews in connection with the relevant
                    Supplier and across different media.
                  </li>
                  <li>
                    We have the right to remove any Review you post on the
                    Platform if, in our opinion, your Review does not comply with
                    the content standards set out in clause 10.5.
                  </li>
                  <li>
                    A Review must be accurate (where it states facts), be
                    genuinely held (where it states opinions), and comply with the
                    law applicable in England and Wales and in any country from
                    which it is posted. A Review must not:
                    <ol type="a">
                      <li>
                        be defamatory of any person, obscene, offensive, hateful
                        or inflammatory, bully, insult, intimidate or humiliate,
                        be threatening, abuse or invade another&#39;s privacy, or
                        cause annoyance, inconvenience or needless anxiety, be
                        likely to harass, upset, embarrass, alarm or annoy any
                        other person;
                      </li>
                      <li>
                        promote discrimination based on race, sex, religion,
                        nationality, disability, sexual orientation or age;
                      </li>
                      <li>
                        infringe any copyright, database right or trade mark of
                        any other person; and/or
                      </li>
                      <li>be likely to deceive any person.</li>
                    </ol>
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={11}
                  headerTitle="11. WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES YOU LINK TO">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    The Platform or any Service may contain links to other
                    independent websites which are not provided by us. Such
                    independent sites are not under our control, and we are not
                    responsible for and have not checked and approved their
                    content or their privacy policies (if any).
                  </li>
                  <li>
                    You will need to make your own independent judgment about
                    whether to use any such independent sites, including whether
                    to buy any products or services offered by them.
                  </li>
                </ol>
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={12}
                  headerTitle="12. SUSPENSION OF THE PLATFORM">
                  <p className="privacy-desc"> 
                  <ol>
                    <li>
                      Reasons we may suspend the availability of the Platform. We
                      may have to suspend the availability of the Platform to:
                      <ol>
                        <li>
                          deal with technical problems or make minor technical
                          changes;
                        </li>
                        <li>
                          update the Platform to reflect changes in relevant laws
                          and regulatory requirements;
                        </li>
                        <li>
                          make changes to the Platform as notified by us to you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Your rights if we suspend the availability of the Platform. We
                      will use our reasonable endeavours to contact you in advance
                      to tell you we will be suspending availability of the
                      Platform, unless the problem is urgent or an emergency. If we
                      suspend the availability of the Platform for any of the
                      reasons set out in clause 12.1, this will not affect your
                      rights in respect of your existing Bookings in any way. If you
                      need to make any changes to your Bookings then please email
                      <a href="mailto:support@vendoir.com">support@vendoir.com</a>
                      .
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={13}
                  headerTitle="13. YOUR RIGHTS TO END THE CONTRACT">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      You can always end your contract with us. You may terminate
                      your contract with us at any time and for any reason. You may
                      do this by writing to us at support@vendoir.com or our
                      registered office address. Following this, you will not be
                      able to make any more Bookings on the Platform. Your existing
                      Bookings and your rights and obligations in relation to them
                      will remain valid and continue until the Events they relate to
                      have occurred (unless you choose to cancel all existing
                      Bookings in which case clause 6 shall apply), upon which our
                      contract with you will terminate and your account will be
                      deleted.
                    </li>
                    <li>
                      You can end your contract and any existing Bookings you have
                      with us in certain circumstances. You may terminate our
                      contract with you and any existing Bookings with immediate
                      effect by giving notice to us by emailing Support@vendoir.com
                      in the following circumstances:
                      <ol>
                        <li>
                          where you are a consumer and we have failed to introduce
                          you to a Supplier in accordance with your Booking, have
                          breached our data protection obligations to you in some
                          way, or have acted in breach of these terms or the law in
                          respect of the Fees you have paid to us and which we hold
                          on your behalf, or where you have a right under law to do
                          so as a result of something we have done wrong;
                        </li>
                        <li>
                          where you are a business organizer and we have committed a
                          material breach of these terms which we have not been able
                          to remedy within 14 days, in such circumstances you will
                          receive a full refund of the Fees paid but you will still
                          be bound to purchase the Products from the Supplier(s) in
                          question under your contract with it. We will arrange for
                          you to be contacted by the Suppliers in such cases.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Consumer&#39;s right to cancel. Consumers have a right to cancel
                      contracts in most situations by law. You acknowledge and agree
                      that we will provide the Services as soon as you have made a
                      Booking with us, and that following full performance of the
                      Services by us you will not have any such right to cancel
                      under law, other than as set out in these terms.
                    </li>
                  </ol></p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={14}
                  headerTitle="14. OUR RIGHTS TO END THE CONTRACT">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      We may end the contract if you break it. We may end the
                      contract between us at any time or any individual Booking by
                      written notice to you if:
                      <ol>
                        <li>
                          you fail to pay the Fees in respect of a Booking by the
                          date they are due;
                        </li>
                        <li>you commit a material breach of these terms;</li>
                        <li>
                          where you are an individual, you become bankrupt or have a
                          receiving order or administration order made against you;
                          or make any compromise or arrangement with or for the
                          benefit of your creditors or shall make any conveyance or
                          assignment for the benefit of your creditors, or shall
                          purport to do so; or
                        </li>
                        <li>
                          where you are a company, you go into liquidation either
                          compulsory or voluntary (save for the purpose of
                          reconstruction or amalgamation) or if an administrator,
                          administrative receiver or receiver is appointed in
                          respect of the whole or any part of your assets or if you
                          make an assignment for the benefit of or composition with
                          your creditors generally or threaten to do any of these
                          things or any judgment is made against you or any similar
                          occurrence under any jurisdiction affects you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You must compensate us if you break the contract. If we end
                      the contract in the situations set out in clause 14.1 we will
                      refund any money you have paid in advance for Services we have
                      not provided but we may deduct or charge you a reasonable sum
                      as compensation for the net costs we will incur as a result of
                      your breaking the contract.
                    </li>
                    <li>
                      We may withdraw the Platform and Services. We may write to you
                      to let you know that we are going to stop providing the
                      Platform and Services. We will let you know at least 30 days
                      in advance of our stopping the supply of the Platform and
                      Services. We will stop taking Bookings from this point
                      forwards but will not stop the supply of the Platform and
                      Services until all existing Bookings have been completed
                      (i.e., the Events they relate to have occurred and all Fees
                      have been paid).
                    </li>
                  </ol></p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={15}
                  headerTitle="15. IF THERE IS A PROBLEM WITH THE SERVICES">
                  <p className="privacy-desc">
                  How to tell us about problems. If you have any questions or
                  complaints about the Services, please contact us. You can write
                  to our organizer support team at support@vendoir.com or our
                  registered office address.
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={16}
                  headerTitle="16. YOUR INDEMNITY TO US IF YOU ARE A BUSINESS ORGANIZER">
                  <p className="privacy-desc">
                  <p>
                  You hereby indemnify us, and undertake to keep us indemnified,
                  against any and all losses, damages, costs, liabilities and
                  expenses (including without limitation legal expenses and any
                  amounts paid by us to a third party in settlement of a claim or
                  dispute) incurred or suffered by us and arising directly or
                  indirectly out of:
                </p>
                <ol>
                  <li>
                    any breach by you of any provision of these terms and
                    conditions; or
                  </li>
                  <li>your use of the Platform.</li>
                </ol>
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={17}
                  headerTitle="17. OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU IF YOU
                  ARE A CONSUMER">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      We are responsible to you for foreseeable loss and damage
                      caused by us. If we fail to comply with these terms, we are
                      responsible for loss or damage you suffer that is a
                      foreseeable result of our breaking this contract or our
                      failing to use reasonable care and skill, but we are not
                      responsible for any loss or damage that is not foreseeable.
                      Loss or damage is foreseeable if either it is obvious that it
                      will happen or if, at the time the contract was made, both we
                      and you knew it might happen.
                    </li>
                    <li>
                      We do not exclude or limit in any way our liability to you
                      where it would be unlawful to do so. This includes liability
                      for death or personal injury caused by our negligence or the
                      negligence of our employees, agents or subcontractors; for
                      fraud or fraudulent misrepresentation; for breach of your
                      legal rights in relation to the Services.
                    </li>
                    <li>
                      When we are liable for damage caused by defective digital
                      content. If defective digital content which we have supplied
                      damages a device or digital content belonging to you and this
                      is caused by our failure to use reasonable care and skill we
                      will either repair the damage or pay you compensation.
                    </li>
                    <li>
                      We are not liable for business losses. If you are a consumer
                      we only supply the products for to you for domestic and
                      private use. If you use the products for any commercial,
                      business or re-sale purpose our liability to you will be
                      limited as set out in clause 18.
                    </li>
                    <li>
                      We are not liable for any defective goods or services provided
                      by Suppliers. You acknowledge that we are not Suppliers of the
                      Products and have no responsibility for the quality or timely
                      delivery of the Products by Suppliers, and that you are not able
                      to hold us liable for any defective or late delivery of the
                      Products.
                    </li>
                  </ol>
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={18}
                  headerTitle="18. OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU IF YOU
                  ARE A BUSINESS">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      Nothing in these terms shall limit or exclude our liability
                      for:
                      <ol>
                        <li>
                          death or personal injury caused by our negligence, or the
                          negligence of our employees, agents or subcontractors (as
                          applicable);
                        </li>
                        <li>fraud or fraudulent misrepresentation;</li>
                        <li>
                          breach of the terms implied by section 2 of the Supply of
                          Goods and Services Act 1982; or
                        </li>
                        <li>
                          any matter in respect of which it would be unlawful for us
                          to exclude or restrict liability.
                        </li>
                      </ol>
                    </li>
                    <li>
                      All terms implied by sections 13 to 15 of the Sale of Goods
                      Act 1979 and sections 3 to 5 of the Supply of Goods and
                      Services Act 1982 are excluded.
                    </li>
                    <li>
                      Subject to clause 18.1:
                      <ol>
                        <li>
                          we shall not be liable to you, whether in contract, tort
                          (including negligence), breach of statutory duty, or
                          otherwise, for any loss of profit, or any indirect or
                          consequential loss arising under or in connection with any
                          contract between us;
                        </li>
                        <li>
                          our total liability to you for all other losses arising
                          under or in connection with each Booking you make with us,
                          whether in contract, tort (including negligence), breach
                          of statutory duty, or otherwise, shall be limited to the
                          total Service Fees paid by you to us in respect of that
                          Booking; and
                        </li>
                        <li>
                          except in respect of liability that relates to a
                          particular Booking in which case clause 18.3(b) shall
                          apply, our total liability to you in any 12-month period
                          starting on the date on which you accept these terms or
                          any anniversary of it (a &#39;Contract Year&#39;), for all other
                          losses arising under or in connection with this contract,
                          whether in contract, tort (including negligence), breach
                          of statutory duty, or otherwise, shall be limited to the
                          total Service Fees paid by you to us in that Contract
                          Year.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                collapseId={19}
                headerTitle="19. HOW WE MAY USE YOUR PERSONAL INFORMATION">
                <p className="privacy-desc">
                We will only use your personal information as set out in our
                https://www.vendoir.com/vendoir-privacy-policy.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                  collapseId={20}
                  headerTitle="20. OTHER IMPORTANT TERMS">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      We may transfer this agreement to someone else. We may
                      transfer our rights and obligations under these terms to
                      another organisation. We will always tell you in writing if
                      this happens and we will ensure that the transfer will not
                      affect your rights under the contract. If you are a consumer
                      and you are unhappy with the transfer, you may contact us to
                      end the contract within 30 days of us telling you about it,
                      and we will refund you any payments you have made in advance
                      in respect of Bookings for Products not yet provided. In such
                      cases, your contract with us will end, but you will still be
                      bound to purchase the Products from the Supplier(s) in question.
                      We will arrange for you to be contacted by the Suppliers in such
                      cases.
                    </li>
                    <li>
                      You need our consent to transfer your rights to someone else
                      (except that you can always transfer our guarantee). You may
                      only transfer your rights or your obligations under these
                      terms to another person if we agree to this in writing.
                    </li>
                    <li>
                      Events beyond our control. Neither party shall be in breach of
                      this contract nor liable for delay in performing, or failure
                      to perform, any of its obligations under it if such delay or
                      failure results from an event, circumstance or cause beyond
                      its reasonable control.
                    </li>
                    <li>
                      Nobody else has any rights under this contract (except someone
                      you pass your guarantee on to). This contract is between you
                      and us. No other person shall have any rights to enforce any
                      of its terms.
                    </li>
                    <li>
                      If a court finds part of this contract illegal, the rest will
                      continue in force. Each of the paragraphs of these terms
                      operates separately. If any court or relevant authority
                      decides that any of them are unlawful, the remaining
                      paragraphs will remain in full force and effect.
                    </li>
                    <li>
                      Even if we delay in enforcing this contract, we can still
                      enforce it later. If we do not insist immediately that you do
                      anything you are required to do under these terms, or if we
                      delay in taking steps against you in respect of your breaking
                      this contract, that will not mean that you do not have to do
                      those things and it will not prevent us taking steps against
                      you at a later date. For example, if you miss a payment and we
                      do not chase you but we continue to give you access to the
                      Platform, we can still require you to make the payment at a
                      later date.
                    </li>
                    <li>
                      Which laws apply to this contract and where you may bring
                      legal proceedings if you are a consumer. These terms are
                      governed by English law and you can bring legal proceedings in
                      respect of the products in the English courts. If you live in
                      Scotland you can bring legal proceedings in respect of the
                      products in either the Scottish or the English courts. If you
                      live in Northern Ireland you can bring legal proceedings in
                      respect of the products in either the Northern Irish or the
                      English courts.
                    </li>
                    <li>
                      Alternative dispute resolution if you are a consumer.
                      Alternative dispute resolution is a process where an
                      independent body considers the facts of a dispute and seeks to
                      resolve it, without you having to go to court. If you are a
                      consumer and are not happy with how we have handled any
                      complaint, you may want to contact the alternative dispute
                      resolution provider we use. You can submit a complaint to
                      Centre for Effective Dispute Resolution via their website at
                      <a href="https://www.cedr.com/">https://www.cedr.com/</a>
                      .
                    </li>
                    <li>
                      Which laws apply to this contract and where you may bring
                      legal proceedings if you are a business. If you are a
                      business, any dispute or claim arising out of or in connection
                      with a contract between us or its subject matter or formation
                      (including non-contractual disputes or claims) shall be
                      governed by and construed in accordance with the law of
                      England and Wales and the courts of England and Wales shall
                      have exclusive jurisdiction to settle any such dispute or
                      claim.
                    </li>
                  </ol>
                  </p>
                </MDBAccordionItem>
              </MDBAccordion>
            </MDBContainer>
          </div>
          </div>
          </div>
       
        </div>


        <Afooter/>
      </>
    );
  }
}
export default OrganizerTerms;