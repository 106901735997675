import React from "react";
import Nb from "../Nb";
import Footer from "../Footer";

function Invest() {
  return (
    <>
      <Nb />

      <div className="blog-inner-image-sec blog-imag-fifth">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="blog-inner-page-heading text-center">
                Why it's good to invest in a <br></br>sustainable business
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-inner-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <p className="building-heading">Building Vendoir</p>
                <p className="blog-building-date">23 October,2023</p>
              </div>

              <p className="detail-inner-desc">
                In today’s rapidly changing world, sustainability has evolved
                from a buzzword into a fundamental consideration for businesses,
                investors, and consumers. Sustainable practices are not only
                good for the planet but also make compelling economic sense.
                Here are three reasons why it’s wise to invest and be part of
                the green community:
              </p>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  1. Long-Term Profitability:
                </h3>
                <p className="blog-inner-lists-title">
                  Sustainable businesses tend to focus on the long term. We’re
                  not just looking for quick gains; we’re building a foundation
                  for lasting profitability. By reducing waste, conserving
                  resources, and minimizing negative environmental impacts,
                  sustainable companies are often more resilient in the face of
                  economic fluctuations.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  2. Attraction of Ethical Investors:
                </h3>
                <p className="blog-inner-lists-title">
                  In recent years, ethical investing, also known as ESG
                  (Environmental, Social, and Governance) investing, has gained
                  significant traction. Investors are increasingly considering a
                  company’s ethical and sustainable practices when making
                  investment decisions. By aligning with sustainability,
                  businesses can attract a larger pool of potential investors.
                </p>
              </div>

              <div className="blog-inner-lists">
                <h3 className="blog-inner-lists-heading">
                  3. Positive Impact:{" "}
                </h3>
                <p className="blog-inner-lists-title">
                  Investing in a sustainable business allows investors to
                  contribute to positive change in the world. Supporting
                  companies that prioritize environmental and social
                  responsibility can lead to a sense of purpose and fulfillment
                  beyond financial returns.
                </p>
                <p className="blog-inner-lists-title">
                  Choosing to invest in a sustainable business not only benefits
                  the environment but also makes strong financial sense. It can
                  result in sustained profitability, draw the interest of
                  ethical investors, lower operational costs, boost your brand
                  reputation, and encourage innovation, all while leaving a
                  positive mark on the world. This is an investment in a more
                  resilient, responsible, and prosperous future. If this aligns
                  with your values, at Vendoir, we are striving for just that -
                  creating a sustainable and inclusive ecosystem within the
                  event industry. We’re approaching the final 14 days of our
                  Seedrs funding round, so if you’re interested in investing,
                  click the link below!
                </p>
              </div>

              <p className="vendior-date">by Team Vendoir, 21/12/2023</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Invest;
