import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

function Worldslider() {
  return (
    <>
      <div className="worldsilder-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className=" glow-up-heading text-center">
                Don’t just take our word for it
              </h2>
              <p className="worldslider-title text-center">
                Here’s what we've been up to recently.
              </p>

              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                loop={true}
                breakpoints={{
                  340: {
                    slidesPerView: 1.2,
                    spaceBetween: 40,
                  },
                  410: {
                    slidesPerView: 1.2,
                    spaceBetween: 50,
                  },
                  575: {
                    slidesPerView: 1.2,
                    spaceBetween: 50,
                  },
                  767: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  991: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1199: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}>
                <SwiperSlide>
                  <div className="worldslider-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="34"
                      viewBox="0 0 49 34"
                      fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.16708 7.72658C9.95359 7.53549 10.7576 7.43928 11.564 7.43976C17.9503 7.43976 23.126 13.3863 23.126 20.7188C23.126 28.0535 17.9503 34 11.564 34C5.17767 34 0 28.0556 0 20.7188C0 20.5042 0.00408333 20.2875 0.0142917 20.0729H0C0 9.00522 7.84 0 17.4767 0V4.45772C14.3509 4.45772 11.468 5.67944 9.16708 7.72658ZM35.0432 7.72658C35.8149 7.53828 36.6153 7.43976 37.436 7.43976C43.8223 7.43976 49 13.3863 49 20.7188C49 28.0535 43.8223 34 37.436 34C31.0497 34 25.874 28.0556 25.874 20.7188C25.874 20.5042 25.8781 20.2875 25.8883 20.0729H25.874C25.874 9.00522 33.714 0 43.3507 0V4.45772C40.2229 4.45772 37.3441 5.67944 35.0432 7.72658Z"
                        fill="#4C66B1"
                      />
                    </svg>

                    <h4 className="worldslider-heading">Simply the Best</h4>
                    <p className="worldslider-title">
                      I've been on the waiting list for this app to come out and
                      it's finally launched! As a freelancer looking to get
                      bookings from event organisers, I normally have to rely on
                      doing my own social media outreach along with other stuff
                      but this platform pretty much helps me connect with the
                      people I need and I can start managing it all in one
                      place! Highly recommend!
                    </p>

                    <div className="name-person">
                      <p>Sherpasaiyan</p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="worldslider-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="34"
                      viewBox="0 0 49 34"
                      fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.16708 7.72658C9.95359 7.53549 10.7576 7.43928 11.564 7.43976C17.9503 7.43976 23.126 13.3863 23.126 20.7188C23.126 28.0535 17.9503 34 11.564 34C5.17767 34 0 28.0556 0 20.7188C0 20.5042 0.00408333 20.2875 0.0142917 20.0729H0C0 9.00522 7.84 0 17.4767 0V4.45772C14.3509 4.45772 11.468 5.67944 9.16708 7.72658ZM35.0432 7.72658C35.8149 7.53828 36.6153 7.43976 37.436 7.43976C43.8223 7.43976 49 13.3863 49 20.7188C49 28.0535 43.8223 34 37.436 34C31.0497 34 25.874 28.0556 25.874 20.7188C25.874 20.5042 25.8781 20.2875 25.8883 20.0729H25.874C25.874 9.00522 33.714 0 43.3507 0V4.45772C40.2229 4.45772 37.3441 5.67944 35.0432 7.72658Z"
                        fill="#4C66B1"
                      />
                    </svg>

                    <h4 className="worldslider-heading">Amazinnnngg!</h4>
                    <p className="worldslider-title">
                      The user interface and simplicity of this app is truly
                      impressive. I have everything in one place coming from and
                      individual who regularly needs photographers. This app has
                      just solved many issues I face when it comes to hiring the
                      right people for the job. Well done to the team for
                      creating such a simple and amazing experience.
                    </p>
                  </div>

                  <div className="name-person">
                    <p>Mehmood</p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="worldslider-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="34"
                      viewBox="0 0 49 34"
                      fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.16708 7.72658C9.95359 7.53549 10.7576 7.43928 11.564 7.43976C17.9503 7.43976 23.126 13.3863 23.126 20.7188C23.126 28.0535 17.9503 34 11.564 34C5.17767 34 0 28.0556 0 20.7188C0 20.5042 0.00408333 20.2875 0.0142917 20.0729H0C0 9.00522 7.84 0 17.4767 0V4.45772C14.3509 4.45772 11.468 5.67944 9.16708 7.72658ZM35.0432 7.72658C35.8149 7.53828 36.6153 7.43976 37.436 7.43976C43.8223 7.43976 49 13.3863 49 20.7188C49 28.0535 43.8223 34 37.436 34C31.0497 34 25.874 28.0556 25.874 20.7188C25.874 20.5042 25.8781 20.2875 25.8883 20.0729H25.874C25.874 9.00522 33.714 0 43.3507 0V4.45772C40.2229 4.45772 37.3441 5.67944 35.0432 7.72658Z"
                        fill="#4C66B1"
                      />
                    </svg>

                    <h4 className="worldslider-heading">It’s finally here!</h4>
                    <p className="worldslider-title">
                      Having moved out of london and trying to organise a
                      surprise bday party for a mate, I didn’t know where to
                      start. This app was the saviour! Got a Dj from here and
                      the rest was history. The ease in getting the Dj at budget
                      and even the recommendation for a mobile bar I got from
                      him once I was in touch was the plus! Excellent all round.{" "}
                    </p>
                  </div>

                  <div className="name-person">
                    <p>Abombalakaka</p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="worldslider-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="34"
                      viewBox="0 0 49 34"
                      fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.16708 7.72658C9.95359 7.53549 10.7576 7.43928 11.564 7.43976C17.9503 7.43976 23.126 13.3863 23.126 20.7188C23.126 28.0535 17.9503 34 11.564 34C5.17767 34 0 28.0556 0 20.7188C0 20.5042 0.00408333 20.2875 0.0142917 20.0729H0C0 9.00522 7.84 0 17.4767 0V4.45772C14.3509 4.45772 11.468 5.67944 9.16708 7.72658ZM35.0432 7.72658C35.8149 7.53828 36.6153 7.43976 37.436 7.43976C43.8223 7.43976 49 13.3863 49 20.7188C49 28.0535 43.8223 34 37.436 34C31.0497 34 25.874 28.0556 25.874 20.7188C25.874 20.5042 25.8781 20.2875 25.8883 20.0729H25.874C25.874 9.00522 33.714 0 43.3507 0V4.45772C40.2229 4.45772 37.3441 5.67944 35.0432 7.72658Z"
                        fill="#4C66B1"
                      />
                    </svg>

                    <h4 className="worldslider-heading">Amazinnnngg!</h4>
                    <p className="worldslider-title">
                      The user interface and simplicity of this app is truly
                      impressive. I have everything in one place coming from and
                      individual who regularly needs photographers. This app has
                      just solved many issues I face when it comes to hiring the
                      right people for the job. Well done to the team for
                      creating such a simple and amazing experience.
                    </p>
                  </div>

                  <div className="name-person">
                    <p>Mehmood</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Worldslider;
