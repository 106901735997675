import React, { PureComponent } from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Aheader from './Aheader';
import Afooter from './Afooter';

const title = 'Vendoir | Supplier Terms and Conditions';

class VendorTerms extends PureComponent {
  componentDidMount() {
    document.title = title;
  }

  render() {
    return (
      <>
        <Aheader/>
        <div className="about-hero-section">
           <div className='container'>
              <div className="wrapper about-hero" style={{ display: 'block' }}>
            <h2 className="text-center according-main-title">
              Vendoir Supplier Terms and Conditions
              {' '}
            </h2>

            <div className="row" id="intro">
              <p className="text-center">
                Your attention is particularly drawn to the provisions of clause
                9 (Your Obligations) and clause 12 (Limitation of liability).
              </p>
            </div>

            <div className="privacy-faq">
            <MDBContainer>
              <MDBAccordion alwaysOpen initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="1. ABOUT US">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      <b>Company details.</b>
                      {' '}
                      Vendoir Limited (company number
                      12986100) (
                      <b>we</b>
                      {' '}
                      and
                      <b>us</b>
                      ) is a company registered in England and Wales
                      and our registered office is at 128 City Road, London,
                      United Kingdom, EC1V 2NJ. We operate the online platform
                      <b>&quot;Vendoir&quot;</b>
                      {' '}
                      accessible via the website
                      https://www.vendoir.com/ (the &nbsp;Platform&nbsp;). In
                      this document we will refer to you, as a supplier, as
                      <b>&nbsp;you&nbsp;</b>
                      ).
                    </li>
                    <li>
                      <b>Contacting us.</b>
                      {' '}
                      To contact us, email our Organizer
                      service team at support.vendoir.com. How to give us formal
                      notice of any matter under the Contract is set out in
                      clause 17.2.
                    </li>
                    <li>
                      <b>Our Platform and Services.</b>
                      {' '}
                      We provide the Platform
                      through which users as Organizers (
                      <b>&quot;Organizers&quot;</b>
                      ) can connect with third
                      party suppliers such as you, of events related goods and
                      services (
                      <b>&nbsp;Products&nbsp;</b>
                      ) in relation to
                      those Organizer&#39;s events (
                      <b>&nbsp;Events&nbsp;</b>
                      ). Our services extend only to introducing you to
                      Organizers via the Platform and allowing you to take
                      orders from them (
                      <b>&nbsp;Services&nbsp;</b>
                      ).
                    </li>
                    <li>
                      <b>Our role.</b>
                      {' '}
                      Vendoir&#39;s role is solely as a
                      provider of the Platform whereby Organizers of the
                      Platform are introduced to suppliers. Vendoir is neither a
                      supplier nor a purchaser of any of the Products.
                    </li>
                  </ol>
                  </p>
                  
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="2. OUR CONTRACT WITH YOU">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      <b>Our contract.</b>
                      {' '}
                      These terms and conditions (
                      <b>Terms</b>
                      ) apply to your use of our Services as a
                      supplier and form our contract with you (Contract). They
                      apply to the exclusion of any other terms that you seek to
                      impose or incorporate, or which are implied by law, trade
                      custom, practice or course of dealing, including our
                      Vendoir Organizer Terms and Conditions (the
                      <b>&nbsp;Organizer Terms&nbsp;</b>
                      ).
                    </li>
                    <li>
                      <b>Entire agreement.</b>
                      {' '}
                      The Contract is the entire
                      agreement between you and us in relation to its subject
                      matter. You acknowledge that you have not relied on any
                      statement, promise or representation or assurance or
                      warranty that is not set out in the Contract.
                    </li>
                    <li>
                      <b>Language.</b>
                      {' '}
                      These Terms and the Contract are made
                      only in the English language.
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={3}
                  headerTitle=" 3. ACCEPTING BOOKINGS VIA THE PLATFORM">
                  <p className="privacy-desc"><ol>
                  <li>
                    <b>Your options for accepting bookings.</b>
                    {' '}
                    Organizers may
                    place orders or reservations for Products via the Platform
                    (a
                    <b>&nbsp;Booking&nbsp;</b>
                    ). You will be able to configure your account as a supplier
                    to accept orders in the following two ways:
                    <ol type="a">
                      <li>
                        <b>Instant Bookings:</b>
                        {' '}
                        where Bookings are
                        automatically accepted on your behalf as soon as they
                        are received without you reviewing and approving the
                        Booking first; or
                      </li>
                      <li>
                        <b>Approved Bookings:</b>
                        {' '}
                        where Bookings are only
                        accepted once you have reviewed and approved the
                        Booking in the Platform. For Approved Bookings you
                        must approve the Booking within 24 hours of receipt,
                        failing which the Booking will be deemed rejected and
                        will be cancelled.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Approved Booking is the default.</b>
                    {' '}
                    When you create an
                    account, only Approved Bookings may be accepted by
                    default. If you wish to take Instant Bookings, you must
                    activate this functionality via your account.
                  </li>
                  <li>
                    <b>Notification of acceptance.</b>
                    {' '}
                    We will send a
                    notification to an Organizer when their Approved Booking
                    has been accepted or when their Instant Booking has been
                    completed, and you will be bound to provide the Products
                    to the Organizer in accordance with the Organizer&#39;s
                    Booking upon such notification.
                  </li>
                  <li>
                    <b>Other terms.</b>
                    {' '}
                    If you have any specific requirements
                    when accepting Bookings, you must communicate those
                    requirements to your Organizer and ensure you document
                    appropriately the terms of your agreement with the
                    Organizer.
                  </li>
                  <li>
                    <b>Your contract with the Organizer.</b>
                    {' '}
                    You will be
                    contractually bound to provide the Products to the
                    Organizer in accordance with its Booking and any other
                    terms you agree with the Organizer. You acknowledge and
                    agree that we are not party to any contract or transaction
                    you enter into with your Organizers, nor do we have any
                    responsibility to enforce any such contract or transaction
                    against the Organizers.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={4}
                  headerTitle="4. THE FEES AND PAYMENT">
                  <p className="privacy-desc"><ol>
                  <li>
                    <b>Fees paid by the Organizer.</b>
                    {' '}
                    The Organizer is
                    required to pay fees as set out in clause 5 of the
                    Organizer Terms, and the definitions used in that clause
                    shall apply and be used in this Contract. You acknowledge
                    and agree that we are entitled to deduct and retain the
                    Service Fee from the Fees paid to us by the Organizer.
                  </li>
                  <li>
                    <b>Your authorisation to us.</b>
                    {' '}
                    You hereby authorise us
                    to hold the Supplier Fees as agent and release the same to
                    you only following the Event in which case the funds shall
                    be released to you within 48 hours after the Event, or
                    otherwise upon cancellation of an Event by an Organizer.
                    However, this might take up to 7 business days to reach
                    you depending on your bank.
                  </li>
                  <li>
                    <b>Payout Fee.</b>
                    {' '}
                    Our third-party payment gateway Stripe
                    will charge a small transactional fee during payout. This
                    will include a fee of £2 per active account per month as
                    well as 0.25% of the total payout amount per month and 10p
                    per payout: visit
                    <a
                      href="https://stripe.com/gb/connect/pricing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      https://stripe.com/gb/connect/pricing
                      {' '}
                    </a>
                    for more information.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={5}
                  headerTitle="5. CANCELLATIONS">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      <b>Organizer&#39;s right to cancel.</b>
                      {' '}
                      You acknowledge
                      and agree that Organizers may cancel Bookings at any time.
                      If the Organizer cancels the Booking 31 days or more
                      before the Event, you will not receive any cancellation
                      fee. You shall be entitled to receive a cancellation fee
                      where the Organizer cancels a Booking:
                      <ol type="a">
                        <li>
                          between 30 days and 21 days before the Event, in which
                          case you will receive a fee of 10% of the booking
                          Fees, less the Service Fee;
                        </li>
                        <li>
                          between 20 days and 8 days before the Event, in which
                          case you will receive a fee of 20% of the Booking
                          Fees, less the Service Fee;
                        </li>
                        <li>
                          between 7 days and 48 hours before the Event, in which
                          case you will receive a fee of 40% of the Booking
                          Fees, less the Service Fee except where you receive an
                          alternative booking in respect of the Products for the
                          same date as the cancelled Event, in which case you
                          will receive a fee of 20% of the Booking Fees less the
                          Service Fee and;
                        </li>
                        <li>
                          within 48 hours before the Event, you will receive a
                          fee of 60% of the Booking Fees, less the Service and
                          10% for administrative Fee from the Booking Fee,
                          unless you receive an alternative booking in respect
                          of the Products for the same date as the cancelled
                          Event, in which case you will receive a fee of 20% of
                          the Fees less the Service Fee.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Supplier&#39;s cancellation.</b>
                      {' '}
                      If you cancel a Booking:
                      <ol type="a">
                        <li>
                          within 21 days of the Event date, the Organizer will
                          receive a full refund of the Fees paid by it, and you
                          shall have debited against your account a cancellation
                          charges equivalent to 10% of the Supplier Fees for the
                          cancelled Booking, which we shall deduct from the
                          Supplier Fees taken in respect of your next Booking.
                        </li>
                        <li>
                          21 days or more in advance of the Event date, the
                          Organizer will receive a full refund of the Fees paid
                          by it, and you shall have debited against your account
                          a cancellation charge equivalent to 10% of the Supplier
                          Fees for the cancelled Booking, which we shall deduct
                          from the Supplier Fees taken in respect of your next
                          Booking,
                        </li>
                      </ol>
                      in either case, we reserve our right in our sole and
                      absolute discretion to suspend your account and access to
                      the Services until such payment has been made.
                    </li>
                    <li>
                      Please note that for cancellations related to COVID-19,
                      our cancellations policy set out above in clauses 5.1 and
                      5.2 applies and COVID-19 is not classed as an event beyond
                      any party&#39;s reasonable control. If any Booking is
                      cancelled for a reason relating to COVID-19, we may in our
                      sole and absolute discretion vary the terms of our
                      cancellations policy accordingly (but for the avoidance of
                      doubt you acknowledge that we shall at no time be obliged
                      to make such a variation).
                    </li>
                    <li>
                      Prior to cancelling any Booking, you agree to enter into
                      good faith discussions with the Organizer to establish
                      whether the date and time of the Event in question can be
                      changed to suit all involved parties.
                    </li>
                  </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={6}
                  headerTitle="6. DISPUTES">
                  <p className="privacy-desc"><ol>
                  <li>
                    If you have a complaint regarding an Organizer in
                    connection with their Booking, you must raise a complaint
                    within 7 days after the date of the Event. If you fail to
                    do so, we will not assist in mediating the dispute.
                  </li>
                  <li>
                    In the first instance, you should attempt to resolve your
                    dispute directly with the Organizer and enter into good
                    faith negotiations with the Organizer in order to do so.
                    If the Product is defective or comprises services not
                    properly performed, you will be responsible for providing
                    a refund or replacement according to the Organizer&#39;s
                    preference.
                  </li>
                  <li>
                    In the event that you and the Organizer are unable to
                    resolve a dispute between you directly, you can ask us to
                    mediate the dispute. If Vendoir mediates the dispute, you
                    agree to accept our conclusion and pay any amount which
                    Vendoir determines you are liable for. In the course of
                    mediating a dispute, we may ask both you and the Organizer
                    to submit all such evidence and information as we
                    reasonably require in order to reach a conclusion, and you
                    agree to do so.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={7}
                  headerTitle="7. RIGHT TO USE THE PLATFORM">
                  <p className="privacy-desc"><ol>
                  <li>
                    We give you the right for the term of this Contract to use
                    the Platform solely for the purposes of accessing and
                    using the Services in accordance with these terms.
                  </li>
                  <li>
                    You agree that you will:
                    <ol type="a">
                      <li>
                        not rent, lease, sub-license, loan, provide, or
                        otherwise make available, the Platform or Services in
                        any form, in whole or in part to any person without
                        prior written consent from us;
                      </li>
                      <li>
                        not copy the Platform or Services, except as part of
                        the normal use of the Platform or where it is
                        necessary for the purpose of back-up or operational
                        security;
                      </li>
                      <li>
                        not translate, merge, adapt, vary, alter or modify,
                        the whole or any part of the Platform or Services nor
                        permit the Platform or the Services or any part of
                        them to be combined with, or become incorporated in,
                        any other programs, except as necessary to use the
                        Platform and the Services on devices as permitted in
                        these terms;
                      </li>
                      <li>
                        not disassemble, de-compile, reverse engineer or
                        create derivative works based on the whole or any part
                        of the Platform or the Services nor attempt to do any
                        such things, except to the extent that (by virtue of
                        sections 50B and 296A of the Copyright, Designs and
                        Patents Act 1988) such actions cannot be prohibited
                        because they are necessary to decompile the Platform
                        to obtain the information necessary to create an
                        independent program that can be operated with the
                        Platform or with another program (&nbsp;Permitted
                        Objective&nbsp;), and provided that the information
                        obtained by you during such activities:
                        <ol type="i">
                          <li>
                            is not disclosed or communicated without our prior
                            written consent to any third party to whom it is
                            not necessary to disclose or communicate it in
                            order to achieve the Permitted Objective; and
                          </li>
                          <li>
                            is not used to create any software that is
                            substantially similar in its expression to the
                            Platform;
                          </li>
                          <li>is kept secure; and</li>
                          <li> is used only for the Permitted Objective;</li>
                        </ol>
                      </li>
                      <li>
                        comply with all applicable technology control or
                        export laws and regulations that apply to the
                        technology used or supported by the Platform or any
                        Service.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You must:
                    <ol>
                      <li>
                        not use the Platform or any Service in any unlawful
                        manner, for any unlawful purpose, or in any manner
                        inconsistent with these terms, or act fraudulently or
                        maliciously, for example, by hacking into or inserting
                        malicious code, such as viruses, or harmful data, into
                        the Platform, any Service or any operating system;
                      </li>
                      <li>
                        not infringe our intellectual property rights or those
                        of any third party in relation to your use of the
                        Platform or any Service, including by the submission
                        of any material (to the extent that such use is not
                        licensed by these terms);
                      </li>
                      <li>
                        not transmit any material that is defamatory,
                        offensive or otherwise objectionable in relation to
                        your use of the Platform or any Service;
                      </li>
                      <li>
                        not use the Platform or any Service in a way that
                        could damage, disable, overburden, impair or
                        compromise our systems or security or interfere with
                        other users; and
                      </li>
                      <li>
                        not collect or harvest any information or data from
                        any Service or our systems or attempt to decipher any
                        transmissions to or from the servers running any
                        Service.
                      </li>
                    </ol>
                  </li>
                  <li>
                    When creating an account on the Platform, you will need to
                    choose a username and password, which you must keep
                    confidential at all times and not allow any third party to
                    use your account. You will be required to satisfy our
                    onboarding requirements, including by providing two forms
                    of proof of identification, a live photograph of an
                    authorised representative of the business, and further
                    information such as date of birth, full name, bank details
                    and personal address. We reserve our right to require
                    re-verification of suppliers from time to time.
                  </li>
                  <li>
                    You should notify us immediately if you become aware of
                    any disclosure or unauthorised use of your account
                    details. You are responsible for all activity on the
                    Platform using your account, whether authorised or
                    unauthorised.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={8}
                  headerTitle="8. OUR SERVICES">
                  <p className="privacy-desc"><ol>
                  <li>
                    Descriptions and illustrations. Any descriptions or
                    illustrations on our site are published for the sole
                    purpose of giving an approximate idea of the services
                    described in them. They will not form part of the Contract
                    or have any contractual force.
                  </li>
                  <li>
                    Updates. From time to time we may automatically update the
                    Platform and Services to improve performance, enhance
                    functionality, reflect changes to the operating system or
                    address security issues.
                  </li>
                  <li>
                    Changes to the Services. We will not make any changes to
                    the Services or these terms where such changes would
                    affect your rights in respect of existing Bookings you
                    have accepted.
                  </li>
                  <li>
                    Reasonable care and skill. We warrant to you that the
                    Services will be provided using reasonable care and skill.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={9}
                  headerTitle="9. YOUR OBLIGATIONS ">
                  <p className="privacy-desc"><ol>
                  <li>
                    It is your responsibility to ensure that you, and you
                    warrant and represent that you will:
                    <ol type="a">
                      <li>
                        supply the Products using all reasonable care and
                        skill and in accordance with the Booking, and where
                        the Products are goods you shall ensure that they are
                        of satisfactory quality, fit for purpose, and as
                        described;
                      </li>
                      <li>
                        cooperate with us in all matters relating to the
                        Services;
                      </li>
                      <li>
                        provide us with such information and materials we may
                        reasonably require in order to supply the Services,
                        including any images and descriptions of the Products,
                        and ensure that such information is complete and
                        accurate in all material respects;
                      </li>
                      <li>
                        obtain and maintain all necessary licences,
                        permissions and consents which may be required to
                        supply the Products before the date on which the
                        Products are to be provided;
                      </li>
                      <li>
                        comply with all applicable laws, including health and
                        safety laws and consumer laws;
                      </li>
                      <li>
                        avoid deceptive, misleading or unethical practices
                        that are, or might be, detrimental to Vendoir, the
                        Services or the public and shall not publish or
                        employ, or co-operate in the publication or employment
                        of, any false, misleading or deceptive advertising
                        material or other representations with regard to the
                        Products, Vendoir or the Services;
                      </li>
                      <li>
                        conduct your business in a manner that reflects
                        favourably at all times on Vendoir and the good name,
                        goodwill and reputation of Vendoir; and
                      </li>
                      <li>
                        take responsibility for any consumer or product
                        liability claims made by the Organizer in respect of
                        the Products.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Sensitive Media Policy
                    <ol type="a">
                      <li>
                        Please note that failure to comply with our terms and
                        conditions may result in the delisting or complete
                        removal of your profile.
                      </li>
                      <li>
                        Vendoir does not permit the posting of graphic content
                        or media that depicts death, violence, medical
                        procedures, or serious physical injury in graphic
                        detail. This includes depictions of violent crimes or
                        accidents, physical fights, physical child abuse,
                        bodily fluids including blood, faeces, semen, etc.,
                        serious physical harm including visible wounds, and
                        severely injured or mutilated animals.
                      </li>
                      <li>
                        Additionally, adult nudity and sexual behaviour are
                        not permitted on our platform. This includes
                        consensually produced and distributed media that is
                        pornographic or intended to cause sexual arousals,
                        such as full or partial nudity, simulated sexual acts,
                        sexual intercourse, or other sexual acts. Exceptions
                        may be made for artistic, medical, health, or
                        educational content.
                      </li>
                      <li>
                        Violent sexual conduct is also prohibited, including
                        media that depicts violence, whether real or
                        simulated, in association with sexual acts. This
                        includes depictions of rape and other forms of violent
                        sexual assault, sexual acts that occur without the
                        consent of participants, and sexualized violence that
                        inflicts physical harm on an individual within an
                        intimate setting where it is not immediately obvious
                        if those involved have consented to take part.
                      </li>
                      <li>
                        Media depicting excessively gory content, sexual
                        violence and/or assault, bestiality, or necrophilia is
                        also not permitted.
                      </li>
                      <li>
                        If you come across any violations of our policy, you
                        can report the content in-app by tapping
                        &quot;Support&quot; on the app profile page or
                        emailing us at support@vendoir.com.
                      </li>
                    </ol>
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={10}
                  headerTitle="10. INTELLECTUAL PROPERTY RIGHTS">
                  <p className="privacy-desc"> <ol>
                  <li>
                    All intellectual property rights in or arising out of or
                    in connection with the Services (other than intellectual
                    property rights in any materials provided by you) will be
                    owned by us.
                  </li>
                  <li>
                    You agree to grant us a fully paid-up, perpetual,
                    non-exclusive, royalty-free, non-transferable licence to
                    copy and modify any materials provided by you to us for
                    the purpose of providing the Services, and for the
                    purposes of marketing the Services.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={11}
                  headerTitle=" 11. HOW WE MAY USE YOUR PERSONAL INFORMATION">
                  <p className="privacy-desc"><ol>
                  <li>
                    We will use any personal information you provide to us to:
                    <ol>
                      <li>provide the Services; and</li>
                      <li>process your payment for the Products.</li>
                    </ol>
                  </li>
                  <li>
                    We will process your personal information in accordance
                    with our privacy policy, here:
                    <a href="https://www.vendoir.com/vendoir-privacy-policy">
                      https://www.vendoir.com/vendoir-privacy-policy
                    </a>
                    .
                  </li>
                </ol>
                  </p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={12}
                  headerTitle="12. LIMITATIONS OF LIABILITY: YOUR ATTENTION IS PARTICULARLY
                  DRAWN TO THIS CLAUSE.">
                  <p className="privacy-desc"> <ol>
                  <li>
                    Nothing in the Contract limits any liability which cannot
                    legally be limited, including liability for:
                    <ol type="a">
                      <li>death or personal injury caused by negligence;</li>
                      <li>fraud or fraudulent misrepresentation; and</li>
                      <li>
                        breach of the terms implied by section 2 of the Supply
                        of Goods and Services Act 1982 (title and quiet
                        possession).
                      </li>
                    </ol>
                  </li>
                  <li>
                    Subject to clause 12.1, we will not be liable to you,
                    whether in contract, tort (including negligence), for
                    breach of statutory duty, or otherwise, arising under or
                    in connection with the Contract for:
                    <ol type="a">
                      <li>loss of profits;</li>
                      <li>loss of sales or business;</li>
                      <li> loss of agreements or contracts;</li>
                      <li>loss of anticipated savings;</li>
                      <li>
                        loss of use or corruption of software, data or
                        information;
                      </li>
                      <li>loss of or damage to goodwill; and</li>
                      <li>any indirect or consequential loss.</li>
                    </ol>
                  </li>
                  <li>
                    Subject to clause 12.1:
                    <ol type="a">
                      <li>
                        our total liability to you for all other losses
                        arising under or in connection with each Booking you
                        make with us, whether in contract, tort (including
                        negligence), breach of statutory duty, or otherwise,
                        shall be limited to the total Service Fees paid to us
                        in respect of that Booking; and
                      </li>
                      <li>
                        except in respect of liability that relates to a
                        particular Booking in which case clause 12.3(a) shall
                        apply, our total liability to you in any 12-month
                        period starting on the date on which you accept these
                        terms or any anniversary of it (a &nbsp;Contract
                        Year&nbsp;), for all other losses arising under or in
                        connection with this contract, whether in contract,
                        tort (including negligence), breach of statutory duty,
                        or otherwise, shall be limited to the total Service
                        Fees paid to us in connection with your Bookings in
                        that Contract Year.
                      </li>
                    </ol>
                  </li>
                  <li>
                    We have given commitments as to compliance of the Services
                    with the relevant specification in this Contract. In view
                    of these commitments, the terms implied by sections 3, 4
                    and 5 of the Supply of Goods and Services Act 1982 are, to
                    the fullest extent permitted by law, excluded from the
                    Contract.
                  </li>
                  <li>
                    Unless you notify us that you intend to make a claim in
                    respect of an event within the notice period, we shall
                    have no liability for that event. The notice period for an
                    event shall start on the day on which you became, or ought
                    reasonably to have become, aware of the event having
                    occurred and shall expire 12 months from that date. The
                    notice must be in writing and must identify the event and
                    the grounds for the claim in reasonable detail.
                  </li>
                  <li>
                    This clause 12 will survive termination of the Contract.
                  </li>
                </ol></p>
                </MDBAccordionItem>

                <MDBAccordionItem
                  collapseId={13}
                  headerTitle="13. YOUR INDEMNITY TO US">
                  <p className="privacy-desc"><p>
                  You hereby indemnify us, and undertake to keep us
                  indemnified, against any and all losses, damages, costs,
                  liabilities and expenses (including without limitation legal
                  expenses and any amounts paid by us to a third party in
                  settlement of a claim or dispute) incurred or suffered by us
                  and arising directly or indirectly out of:
                </p>
                <ol>
                  <li>
                    any breach by you of any provision of these Terms; and/or
                  </li>
                  <li>your use of the Platform</li>
                </ol></p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={14}
                  headerTitle="14. CONFIDENTIALITY ">
                  <p className="privacy-desc"><ol>
                  <li>
                    We each undertake that we will not at any time disclose to
                    any person any confidential information concerning one
                    another&#39;s business, affairs, Organizers, clients or
                    suppliers, except as permitted by clause 14.2.
                  </li>
                  <li>
                    We each may disclose the other&#39;s confidential
                    information:
                    <ol type="a">
                      <li>
                        to such of our respective employees, officers,
                        representatives, subcontractors or advisers who need
                        to know such information for the purposes of
                        exercising our respective rights or carrying out our
                        respective obligations under the Contract. We will
                        each ensure that such employees, officers,
                        representatives, subcontractors or advisers comply
                        with this clause 14; and
                      </li>
                      <li>
                        as may be required by law, a court of competent
                        jurisdiction or any governmental or regulatory
                        authority.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Each of us may only use the other&#39;s confidential
                    information for the purpose of fulfilling our respective
                    obligations under the Contract.
                  </li>
                </ol></p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={15}
                  headerTitle=" 15. TERMINATION, CONSEQUENCES OF TERMINATION AND SURVIVAL">
                  <p className="privacy-desc"><ol>
                  <li>
                    <b>Termination.</b>
                    {' '}
                    Without limiting any of our other
                    rights, we may suspend the performance of the Services, or
                    terminate the Contract with immediate effect by giving
                    written notice to you if:
                    <ol type="a">
                      <li>
                        you commit a material breach of any term of the
                        Contract and (if such a breach is remediable) fail to
                        remedy that breach within 14 days of you being
                        notified in writing to do so;
                      </li>
                      <li>
                        you fail to pay any amount due under the Contract on
                        the due date for payment;
                      </li>
                      <li>
                        you take any step or action in connection with you
                        entering administration, provisional liquidation or
                        any composition or arrangement with your creditors
                        (other than in relation to a solvent restructuring),
                        applying to court for or obtaining a moratorium under
                        Part A1 of the Insolvency Act 1986, being wound up
                        (whether voluntarily or by order of the court, unless
                        for the purpose of a solvent restructuring), having a
                        receiver appointed to any of your assets or ceasing to
                        carry on business or, if the step or action is taken
                        in another jurisdiction, in connection with any
                        analogous procedure in the relevant jurisdiction;
                      </li>
                      <li>
                        you suspend, threaten to suspend, cease or threaten to
                        cease to carry on all or a substantial part of your
                        business; or
                      </li>
                      <li>
                        your financial position deteriorates to such an extent
                        that in our opinion your capability to adequately
                        fulfil your obligations under the Contract has been
                        placed in jeopardy.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Consequences of termination.</b>
                    {' '}
                    On termination of the
                    Contract:
                    <ol type="a">
                      <li>
                        your right to access the Platform and Services shall
                        cease, and we shall be entitled to disable your
                        access;
                      </li>
                      <li>
                        we shall refund any Fees to the Organizers with
                        outstanding Bookings and you shall be responsible for
                        managing the relationship with the Organizer from that
                        point onwards;
                      </li>
                      <li>
                        you shall be liable to pay us the Service Fees in
                        respect of all outstanding Bookings at the point of
                        termination and any other applicable service fee;
                      </li>
                      <li>
                        termination of the Contract will not affect your or
                        our rights and remedies that have accrued as at
                        termination.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Survival.</b>
                    {' '}
                    Any provision of the Contract that
                    expressly or by implication is intended to come into or
                    continue in force on or after termination will remain in
                    full force and effect.
                  </li>
                </ol>
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={16}
                  headerTitle="16. EVENTS OUTSIDE OUR CONTROL">
                  <p className="privacy-desc">
                  <ol>
                    <li>
                      We will not be liable or responsible for any failure to
                      perform, or delay in performance of, any of our
                      obligations under the Contract that is caused by any act
                      or event beyond our reasonable control (&nbsp;Event
                      Outside Our Control&nbsp;).
                    </li>
                    <li>
                      If an Event Outside Our Control takes place that affects
                      the performance of our obligations under the Contract:
                      <ol type="a">
                        <li>
                          we will contact you as soon as reasonably possible to
                          notify you; and
                        </li>
                        <li>
                          our obligations under the Contract will be suspended
                          and the time for performance of our obligations will
                          be extended for the duration of the Event Outside Our
                          Control. We will arrange a new date for performance of
                          the Services with you after the Event Outside Our
                          Control is over.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You may cancel the Contract affected by an Event Outside
                      Our Control which has continued for more than 30 days. To
                      cancel please contact us on
                      <a href="support@vendoir.com">support@vendoir.com</a>
                      .
                    </li>
                  </ol>
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={17}
                  headerTitle="17. COMMUNICATIONS BETWEEN US">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    When we refer to &quot;in writing&quot; in these Terms,
                    this includes email.
                  </li>
                  <li>
                    Any notice or other communication given by one of us to
                    the other under or in connection with the Contract must be
                    in writing and be delivered personally, sent by pre-paid
                    first className post or other next working day delivery
                    service, or email.
                  </li>
                  <li>
                    A notice or other communication is deemed to have been
                    received:
                    <ol type="a">
                      <li>
                        if delivered personally, on signature of a delivery
                        receipt or at the time the notice is left at the
                        proper address;
                      </li>
                      <li>
                        if sent by pre-paid first className post or other next
                        working day delivery service, at 9.00 am on the second
                        working day after posting; or
                      </li>
                      <li>
                        if sent by email, at 9.00 am the next working day
                        after transmission.
                      </li>
                    </ol>
                  </li>
                  <li>
                    In proving the service of any notice, it will be
                    sufficient to prove, in the case of a letter, that such
                    letter was properly addressed, stamped and placed in the
                    post and, in the case of an email, that such email was
                    sent to the specified email address of the addressee.
                  </li>
                  <li>
                    The provisions of this clause will not apply to the
                    service of any proceedings or other documents in any legal
                    action.
                  </li>
                </ol>
                  </p>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={18}
                  headerTitle="18. GENERAL">
                  <p className="privacy-desc">
                  <ol>
                  <li>
                    <b>Assignment and transfer</b>
                    <ol type="a">
                      <li>
                        We may assign or transfer our rights and obligations
                        under the Contract to another entity.
                      </li>
                      <li>
                        You may only assign or transfer your rights or your
                        obligations under the Contract to another person if we
                        agree in writing.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Variation. </b>
                    Any variation of the Contract only has effect if it is in
                    writing and signed by you and us (or our respective
                    authorised representatives).
                  </li>
                  <li>
                    <b>Waiver.</b>
                    {' '}
                    If we do not insist that you perform any of
                    your obligations under the Contract, or if we do not
                    enforce our rights against you, or if we delay in doing
                    so, that will not mean that we have waived our rights
                    against you or that you do not have to comply with those
                    obligations. If we do waive any rights, we will only do so
                    in writing, and that will not mean that we will
                    automatically waive any right related to any later default
                    by you.
                  </li>
                  <li>
                    <b>Severance.</b>
                    {' '}
                    Each paragraph of these Terms operates
                    separately. If any court or relevant authority decides
                    that any of them is unlawful or unenforceable, the
                    remaining paragraphs will remain in full force and effect.
                  </li>
                  <li>
                    <b>Third party rights.</b>
                    {' '}
                    The Contract is between you and
                    us. No other person has any rights to enforce any of its
                    terms.
                  </li>
                  <li>
                    <b>Governing law and jurisdiction. </b>
                    The Contract is governed by English law and we each
                    irrevocably agree to submit all disputes arising out of or
                    in connection with the Contract to the exclusive
                    jurisdiction of the English courts.
                  </li>
                </ol>
                  </p>
                </MDBAccordionItem>
               
              </MDBAccordion>
            </MDBContainer>
          </div>
              </div>
           </div>
          
        </div>

        <Afooter/>
      </>
    );
  }
}
export default VendorTerms;
